import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import {
  SUBSCRIPTION_PLAN_ADD,
  SUBSCRIPTION_PLAN_LIST,
  SUBSCRIPTION_PLAN_UPDATE,
  SUBSCRIPTION_STATUS,

} from '../helper/queries';
import { STATUS, timeDelay, STATUS_BUTTONS } from '../helper/constant';
import { debounceTime } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class SubscriptionPlansComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model

  search = new FormControl(''); // table serch
  displayedColumns: string[] = [
    'planName',
    'description',
    'duration',
    'price',
    'status',
    'action',
  ]; // tables colums

  dataSource = new MatTableDataSource(); // user's table source

  createForm!: FormGroup; //  create
  createFormSubmit: boolean | undefined;
  isEditMode: boolean = false;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  readonly ACTIVE = STATUS.ACTIVE;
  readonly INACTIVE = STATUS.INACTIVE;
  readonly STATUS_BUTTONS = STATUS_BUTTONS;

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.createForm = this.formBuilder.group({
      id: [''],
      planName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      duration: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]{1,4}$'),
          Validators.max(9999),
        ],
      ],
      price: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
        ],
      ],
    });
  }

  ngOnInit() {
    this.listSubscription();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listSubscription(searchTerm);
      });
  }

  listSubscription(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: SUBSCRIPTION_PLAN_LIST,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listSubscriptionPlansWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            planName: resp?.planName,
            description: resp?.description,
            price: parseFloat(resp?.price),
            duration: resp?.duration,
            status: parseInt(resp?.status),
          })
        );
        this.totalItems =
          data?.listSubscriptionPlansWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listSubscriptionPlansWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listSubscriptionPlansWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listSubscription(this.search.value);
  }

  createPlan(): void {
    if (this.createForm.valid) {
      const value = this.createForm.value;
      if (value?.id) {
        const data = {
          planName: value?.planName,
          description: value?.description,
          price: value?.price?.toString(),
          duration: value?.duration,
        };
        this.apollo
          .mutate({
            mutation: SUBSCRIPTION_PLAN_UPDATE,
            variables: {
              subscriptionPlanId: value?.id,
              updateSubscriptionPlan: data,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(() => {
            this.listSubscription();
            this.closeModel();
            // Update your local state or perform additional actions as needed
          });
      } else {
        const data = {
          planName: value?.planName,
          description: value?.description,
          price: value?.price,
          duration: value?.duration,
        };
        this.apollo
          .mutate({
            mutation: SUBSCRIPTION_PLAN_ADD,
            variables: {
              createSubscriptionPlan: data,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(() => {
            this.listSubscription();
            this.closeModel();
          });
      }
    }
  }

  updatePlan(data: any): void {
    const newData = {
      id: data?.id,
      planName: data?.planName,
      description: data?.description,
      price: data?.price,
      duration: data?.duration.toString(),
    };
    this.openModel();
    this.isEditMode = true;
    this.createForm.patchValue(newData);
  }

  openDeleteDialog(id: number, type: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.updateStatus(id, type);
      }
    });
  }

  updateStatus(id: number, type: number): void {
    this.apollo
      .mutate({
        mutation: SUBSCRIPTION_STATUS,
        variables: {
          subscriptionPlanId: id,
          status: type,
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe(() => {
        this.listSubscription();
      });
  }
}
