import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { PROFILE, USER_TYPE_PERMISSIONS } from '../helper/queries';
import { headerUserIteam } from '../helper/types';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectHeaderTitle,
  selectMyProfile,
  selectPermissionState,
  selectPermissions,
} from '../reducers/permissions.selectors';
import { setHeaderTitle, setPermissions } from '../reducers/permissions.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  headerTitle$: Observable<string>;
  headerValue: string | undefined;

  headerIteam: headerUserIteam | undefined;

  picUrl: string = '../../assets/images/avatar.png';

  isPermission$: Observable<any>;
  myProfile$: Observable<any>;

  constructor(
    private router: Router,
    private readonly apollo: Apollo,
    private store: Store
  ) {
    this.isPermission$ = this.store?.select(selectPermissions);
    this.myProfile$ = this.store?.pipe(select(selectMyProfile));
    this.headerTitle$ = this.store.pipe(select(selectHeaderTitle));
  }

  ngOnInit() {
    this.headerTitle$.subscribe((title) => {
      this.headerValue = title
    });

    this.myProfile$?.subscribe((profile) => {
      if (profile?.profilePicUrl) {
        this.picUrl = profile?.profilePicUrl;
      }
      this.headerIteam = {
        username: profile?.username,
        userType: profile?.usertypeData?.roleName,
        profilePic: profile?.profilePicUrl,
      };
    });
  }

  logoutUser(): void {
    try {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    } catch (err) {
      console.log('Logout Failed', { err });
    }
  }

  updateHeaderTitle(newTitle: string) {
    this.store.dispatch(setHeaderTitle({ headerTitle: newTitle }));
  }
}
