import { Component } from '@angular/core';
import { AuthServiceService } from '../service/auth/auth-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES, VALIDATION_MESSAGES } from '../helper/messages';

interface loginUser {
  email: string;
}

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class ForgotComponent {
  loginForm!: FormGroup;
  loginSubmit: boolean | undefined;

  users: loginUser[] | undefined;
  errorMessages = VALIDATION_MESSAGES;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: AuthServiceService,
    private toastr: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  clearForm(): void {
    this.loginForm.reset();
  }

  loginUser() {
    if (this.loginForm.valid) {
      const value = this.loginForm.value;
      const userEmail: loginUser = {
        email: value?.email,
      };
      this.loginService?.forgotPassword(userEmail).subscribe(
        (response) => {
          if (response) {
            this.toastr.success(MESSAGES.PASSWORD_RESET_LINK_SENT);
            this.clearForm()
          } else {
            this.toastr.error(MESSAGES.PASSWORD_RESET_LINK_FAILED);
          }
        },
        (error) => {
          this.toastr.error(error?.error?.message);
          console.error(error);
        }
      );
    }
  }
}
