<section class="secBgMain1">
  <div class="flexBoxMain">
    <div class="welcomeBackDiv">
      <div class="welcomeFlexBack">
        <h1>
          Welcome
          <span>Back</span>
        </h1>
        <p>
          Your One-Stop Solution for All Car Parts Needs
        </p>
      </div>
    </div>
    <div class="loginBgBox">
      <img alt="" src="../../assets/images/logo.png">
      <h2>Login to Smarttrack</h2>
      <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
        <input type="text" placeholder="Email" formControlName="email">
        <div class="s_error" *ngIf="
            !loginForm.controls['email'].valid &&
            (loginForm.controls['email'].dirty ||
              loginForm.controls['email'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['email'].hasError('required')">
            {{errorMessages.EMAIL_REQUIRED}}
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['email'].hasError('email')">
            {{errorMessages.EMAIL_INVALID}}
          </div>
        </div>
        <div class="pswBox">
          <input type="password" placeholder="Password" formControlName="password"
            [type]="showPassword ? 'text' : 'password'" />
          <img [src]="pswEye" (click)="togglePasswordVisibility()">
        </div>
        <div class="s_error" *ngIf="
            !loginForm.controls['password'].valid &&
            (loginForm.controls['password'].dirty ||
              loginForm.controls['password'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['password'].hasError('required')">
            {{errorMessages.PASSWORD_REQUIRED}}
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['password'].hasError('minlength')">
            {{errorMessages.PASSWORD_MIN_LENGTH}}
          </div>
        </div>

        <div class="foprgotPasswarrpper">
          <button type="submit" [disabled]="loginForm.invalid">LOGIN</button>
          <div class="routerLinkWrapper">
            <a routerLink="/forgot-password">Forgot Password?</a>
          </div>
        </div>
      </form>
    </div>
  </div>

</section>