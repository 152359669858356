<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv searchDivFull">
            <form class="example-form">
                <input type="text" placeholder="Search for parts request..." [formControl]="search"
                    class="example-input">
            </form>
        </div>
    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- Position Column -->
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.username || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="partName">
                    <th mat-header-cell *matHeaderCellDef>
                        Part Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.partName || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="model">
                    <th mat-header-cell *matHeaderCellDef>
                        Model
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.model || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="imageUrl">
                    <th mat-header-cell *matHeaderCellDef>
                        Image
                    </th>
                    <td mat-cell *matCellDef="let element" data-bs-toggle="modal" data-bs-target="#imgModal"
                        (click)="popupDataImg(element?.imageforpopup)">
                        <img *ngIf="element?.imageUrl" [src]="imagePath">
                        <span *ngIf="!element.imageUrl">___</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dropMenuMain">
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="navigateToProductEnquiryView(element?.id)">View</button>
                            </mat-menu>

                            <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                                    fill="black" />
                                <path
                                    d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                                    fill="black" />
                                <path
                                    d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                                    fill="black" />
                            </svg>


                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <div class="no-data-found" *ngIf="!totalItems">
                <img src="../../assets/images/no-data-found.png" alt="noData">
            </div>
        </div>
        <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
        </mat-paginator>



    </div>
</section>


<div class="usersPopupdiv">
    <div class="modal fade" id="imgModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
                        #closeModalFilter>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <div class="col-sm-12 imge">
                            <img class="pop-img" [src]="imagePopupData" alt="img" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>