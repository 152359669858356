import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import {
  ADD_USER,
  GET_ONE_USER,
  LIST_USERS,
  SUBSCRIPTION_PLAN,
  UPDATE_USER,
  USER_STATUS,
  USER_TYPES_LIST,
} from '../helper/queries';
import { debounceTime } from 'rxjs/operators';
import { STATUS_BUTTONS, STATUS, timeDelay, CUSTOMER_TYPE_LIST } from '../helper/constant';
import { removeNullProperties, removeProperty } from '../helper/helper';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES, VALIDATION_MESSAGES } from '../helper/messages';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class CustomersComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model

  search = new FormControl(''); // user table serch
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'mail',
    'phone',
    'type',
    'status',
    'action',
  ]; // user's tables colums

  dataSource = new MatTableDataSource(); // user's table source
  customerType: any [] = [];
  createUserForm!: FormGroup; // user create
  createUserFormSubmit: boolean | undefined;

  usertypes: any | undefined; // usertype list
  subscriptionPlans: any | undefined; // subscriptionPlans list

  selectedUserType: number | undefined;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  searchData: any;
  isEditMode: boolean = false;
  showPassword: boolean = false;
  pswEye: string = '../../assets/images/password-eye-close.png';

  readonly ACTIVE = STATUS.ACTIVE;
  readonly INACTIVE = STATUS.INACTIVE;

  readonly STATUS_BUTTONS = STATUS_BUTTONS;
  readonly CUSTOMER_ID = [4, 3, 2];
  // readonly AGENT = 2;
  // readonly GOWNER= 3;
  readonly agent = 2;
  readonly customer = 4;
  readonly gowner = 3;

  readonly GARAGE = 'garageOwner';
  readonly AGENT = 'agent';
  readonly CUSTOMER = 'customer';

  closeModel(): void {
    this.createUserForm.reset();
    this.closeModal.nativeElement.click();
  }

  openModel(): void {
    this.createUserForm.reset();
    this.openModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createUserForm.reset();
  }

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.createUserForm = this.formBuilder.group({
      id: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required,  Validators.pattern("^[0-9]{10}$")]],
      password: [
        '',
        [
          Validators.required, // Password is required
          Validators.minLength(8), // Minimum length of 8 characters
          Validators.maxLength(16), // Maximum length of 16 characters
        ],
      ],
      userType: ['', [Validators.required]],
      address: ['', [Validators.required]],
      plan: [''],
      registrationNumber: [''],
      licenseNumber: [''],
    });

    this.fetchUsers();
    this.usertypesList();
    this.checkPasswordValidation();
    this.subscriptionPlansList();
    this.garageOwnerValidation();
    this.agentValidation();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.searchData = searchTerm;
        this.fetchUsers(searchTerm);
      });
  }

  get userType() {
    if (this.usertypes) {
      const id = this.createUserForm.get('userType')?.value;
      const userTypeObject = this.usertypes?.find((_a: any) => _a?.id == id);
      return userTypeObject?.roleName;
    }
  }
  agentValidation() {
    const licenseControl = this.createUserForm.get('licenseNumber');
    const userTypeControl = this.createUserForm.get('userType');
    const subPlanControl = this.createUserForm.get('plan');
    
  
    if (userTypeControl && licenseControl && subPlanControl) {
      // Initial check based on the current value
      if (userTypeControl.value === this.GARAGE) {
        licenseControl.setValidators([Validators.required]);
        subPlanControl.setValidators([Validators.required]);
      } else {
        licenseControl.clearValidators();
        subPlanControl.clearValidators();
      }
      licenseControl.updateValueAndValidity();
      subPlanControl.updateValueAndValidity();
  
      // Subscribe to changes in userTypeControl
      userTypeControl.valueChanges.subscribe((userTypeValue: any) => {
        
        if (userTypeValue === this.agent) {
          licenseControl.setValidators([Validators.required]);
          subPlanControl.setValidators([Validators.required]);
        } else {
          licenseControl.clearValidators();
          subPlanControl.clearValidators();
        }
        licenseControl.updateValueAndValidity();
        subPlanControl.updateValueAndValidity();
      });
    }
  }
  garageOwnerValidation() {
    const regControl = this.createUserForm.get('registrationNumber');
    const userTypeControl = this.createUserForm.get('userType');
    const subPlanControl = this.createUserForm.get('plan');
    
  
    if (userTypeControl && regControl && subPlanControl) {
      // Initial check based on the current value
      if (userTypeControl.value === this.GARAGE) {
        regControl.setValidators([Validators.required]);
        subPlanControl.setValidators([Validators.required]);
      } else {
        regControl.clearValidators();
        subPlanControl.clearValidators();
      }
      regControl.updateValueAndValidity();
      subPlanControl.updateValueAndValidity();
  
      // Subscribe to changes in userTypeControl
      userTypeControl.valueChanges.subscribe((userTypeValue: any) => {
        
        if (userTypeValue === this.gowner) {
          regControl.setValidators([Validators.required]);
          subPlanControl.setValidators([Validators.required]);
        } else {
          regControl.clearValidators();
          subPlanControl.clearValidators();
        }
        regControl.updateValueAndValidity();
        subPlanControl.updateValueAndValidity();
      });
    }
  }
  
  checkPasswordValidation() {
    const idControl = this.createUserForm.get('id');
    const passwordControl = this.createUserForm.get('password');

    if (idControl && passwordControl) {
      idControl?.valueChanges.subscribe((userType) => {
        if (userType) {
          passwordControl.clearValidators();
        } else {
          // passwordControl.setValidators([Validators.required]);
          passwordControl?.setValidators([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ]);
        }
        passwordControl.updateValueAndValidity();
      });
    }
  }

  fetchUsers(searchTerm: string | null = '', usertype: number | null = null) {
    this.apollo
      .watchQuery({
        query: LIST_USERS,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
          filter: {
            usertype: usertype ? usertype : this.CUSTOMER_ID
          },
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.dataSource = data?.listUsersWithPagination?.items?.map(
          (resp: any, index: number) => ({
            id: resp?.id,
            firstName: resp?.username?.split(' ')[0],
            lastName: resp?.username?.split(' ')[1],
            mail: resp?.email,
            phone: resp?.phone,
            status: parseInt(resp?.status),
            type: resp?.usertypeData?.roleName
          })
        );

        this.totalItems = data?.listUsersWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listUsersWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage = data?.listUsersWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.fetchUsers(this.search.value);
  }

  openDeleteDialog(id: number, type: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed()
    .subscribe((result: boolean) => {
      if (result) {
        this.userStatus(id, type);
      }
    });
  }

  userStatus(id: number, type: number): void {
    this.apollo
      .mutate({
        mutation: USER_STATUS,
        variables: {
          usersId: id,
          status: type,
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: () => {
          this.fetchUsers();
          if (type === 2) {
            this.toastr.success(MESSAGES.DATA_DELETED);
          } else {
            this.toastr.success(MESSAGES.SATUS_CHANGE_SUCCESS);
          }
        },
        error: (errors) => {
          this.toastr.error(errors.message);
        },
      });
  }

  userUpdate(id: number): void {
    if (id) {
      this.apollo
        .watchQuery({
          query: GET_ONE_USER,
          variables: {
            id: id,
          },
          fetchPolicy: 'no-cache',
        })
        .valueChanges.subscribe(({ data, loading }: any) => {
          const user = data?.getOneUser;
          console.log(user?.usertypeData?.roleName);
          
          const [firstName, lastName] = user?.username?.split(' ');
          let newData = {
            id: user?.id,
            firstName: firstName,
            lastName: lastName,
            email: user?.email,
            phone: user?.phone,
            userType: user?.roleId,
          };

          if (user?.usertypeData?.roleName === this.GARAGE) {
            console.log('gw');
            
            const garage = {
              plan: user?.garageOwner?.planId,
              address: user?.garageOwner?.garageAddress,
              registrationNumber: user?.garageOwner?.registrationNumber,
            };
            newData = {
              ...newData,
              ...garage,
            };
          }

          if (user?.usertypeData?.roleName === this.AGENT) {
            console.log('if agent');
            
            const agent = {              
              plan: user?.agent?.planId,
              address: user?.agent?.agencyAddress,
              licenseNumber: user?.agent?.licenseNumber,
            };
            newData = {
              ...newData,
              ...agent,
            };
            
          }

          if (user?.usertypeData?.roleName === this.CUSTOMER) {
            const customer = {
              address: user?.customer?.address,
            };
            newData = {
              ...newData,
              ...customer,
            };
          }
          this.openModel();
          this.isEditMode = true;
          this.createUserForm.patchValue(newData);
        });
    }
  }

  subscriptionPlansDay(id: number): any {
    if (this.subscriptionPlans && id) {
      const findOne = this.subscriptionPlans?.find((_a: any) => _a?.id === id);
      const duration = findOne?.duration;

      const currentDate = new Date();
      const startDate = currentDate.toISOString().split('T')[0]; // Format it as YYYY-MM-DD

      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + duration);
      const formattedEndDate = endDate.toISOString().split('T')[0];

      return {
        startDate: startDate,
        endDate: formattedEndDate,
      };
    }
  }

  // logValidationErrors(group: FormGroup): void {
  //   Object.keys(group.controls).forEach((key) => {
  //     const control = group.get(key);
  //     if (control instanceof FormGroup) {
  //       this.logValidationErrors(control);
  //     } else {
  //       if (control && control.invalid) {
  //         const errors = control.errors;
  //         if (errors) {
  //           console.log(`Control: ${key}, Errors: `, errors);
  //         }
  //       }
  //     }
  //   });
  // }

  async createUser(): Promise<void> {
    // this.logValidationErrors(this.createUserForm);

    if (this.createUserForm.valid) {
      const value = this.createUserForm.value;

      // Email validation
      // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      // if (!emailPattern.test(value.email)) {
      //   this.toastr.error('Please enter a valid email address');
      //   return;
      // }
      // // Check if the phone number is valid (contains only numbers)
      // const phoneNumberPattern = /^[0-9]+$/;
      // if (!phoneNumberPattern.test(value.phone)) {
      //   this.toastr.error('Phone number must contain only numbers.');
      //   return; // Stop the process if the phone number is invalid
      // }
      let createUserData;
      let expiryDate;
      if (value?.plan) {
        expiryDate = await this.subscriptionPlansDay(value?.plan);
      }

      

      switch (this.userType) {
        case this.GARAGE:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
            planId: value?.plan,
            startDate: expiryDate?.startDate,
            endDate: expiryDate?.endDate,
            garageAddress: value?.address,
            registrationNumber: value?.registrationNumber,
          };
         
          
          break;

        case this.AGENT:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
            planId: value?.plan,
            startDate: expiryDate?.startDate,
            endDate: expiryDate?.endDate,
            agencyAddress: value?.address,
            licenseNumber: value?.licenseNumber,
          };
          break;

        case this.CUSTOMER:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
            customerAddress: value?.address,
          };
          break;

        default:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
          };
          break;
      }
      try {
        if (value?.id) {
          // console.log(createUserData);

          await this.apollo
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                id: value?.id,
                updateUsers: removeNullProperties(createUserData),
              },
              fetchPolicy: 'no-cache',
            })
            .toPromise();

          this.fetchUsers(this.searchData);
          this.toastr.success(MESSAGES.PART_UPDATED);
          this.closeModel();
        } else {
          await this.apollo
            .mutate({
              mutation: ADD_USER,
              variables: {
                createUserData: createUserData,
              },
              fetchPolicy: 'no-cache',
            })
            .toPromise();

          this.fetchUsers(this.searchData);
          this.toastr.success(MESSAGES.NEW_PART_ADDED);
          this.closeModel();
        }
      } catch (error: any) {
        this.toastr.error(error.message);
      }
    } else {
      this.createUserForm.markAllAsTouched();
    }
  }
  

  navigateToUserDetails(userId: string): void {
    if (userId) {
      this.router.navigate(['/customers', userId]);
    } else {
      this.toastr.error(VALIDATION_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.pswEye = '../../assets/images/password-eye.png';
    } else {
      this.pswEye = '../../assets/images/password-eye-close.png';
    }
  }
  onSelectionUserType(event: MatSelectChange) {
    this.selectedUserType = event.value;
    this.fetchUsers(this.search.value, this.selectedUserType);
  }

  // usertypesList(): void {
  //   this.usertypes = CUSTOMER_TYPE_LIST;
  // }
  usertypesList(): void {
    this.apollo
      .watchQuery({
        query: USER_TYPES_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.usertypes = data?.usertypesList?.filter(
          (usertype: any) =>
            usertype?.id === this.gowner ||
            usertype?.id === this.agent ||
            usertype?.id === this.customer
        );
      });
  }
  subscriptionPlansList(): void {
    this.apollo
      .watchQuery({
        query: SUBSCRIPTION_PLAN,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.subscriptionPlans = data?.subscriptionPlansList;
      });
  }
}
