import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {ViewChild} from '@angular/core';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  action: string;
  datasheet: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: 'H', datasheet: 'H'},
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: 'H', datasheet: 'H'},
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: 'H', datasheet: 'H'},
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: 'H', datasheet: 'H'},
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: 'H', datasheet: 'H'},

];

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
 styleUrls: ['./datasheet.component.scss'],
})
export class DatasheetComponent implements OnInit {
  control = new FormControl('');
  streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets?: Observable<string[]>;

  ngOnInit() {
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'action', 'datasheet'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  constructor(private _liveAnnouncer: LiveAnnouncer) {}

  @ViewChild(MatSort)
  sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
