<section class="addDatSheetCommonSec">
  <div class="serachMainAllWrapper filter-button-style">
    <div class="searchDiv">
      <form class="example-form">
        <input
          type="text"
          placeholder="Search for orders..."
          [formControl]="search"
          class="example-input"
        />
      </form>
    </div>

    <div class="selectWrapper">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="SELECT DATE RANGE"
          [(value)]="selectedRange"
          (selectionChange)="onRangeChange()"
        >
          <mat-option
            *ngFor="let range of dateRangeOrder | keyvalue"
            [value]="range.value"
          >
            {{ range.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div  class="selectWrapper" *ngIf="selectedRange === CUSTOM_TYPE">
      <form [formGroup]="dateRangeForm">
        <mat-form-field>
          <!-- <mat-label>Enter a date range</mat-label> -->
          <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
            <input matStartDate formControlName="start" placeholder="Start Date">
            <input matEndDate formControlName="end" placeholder="End Date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </form>
      
      
    </div>

    <button type="button" (click)="exportexcel()">EXPORT</button>
    <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" #openModal>ADD
            ROLE</button> -->
  </div>
  <div class="dataSheetTable">
    <div class="table-responsive">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        id="excel-table"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="orderNo">
          <th mat-header-cell *matHeaderCellDef>Order No</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.orderNo || "___" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.username || "___" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="orderDate">
          <th mat-header-cell *matHeaderCellDef>Order Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.orderDate || "___" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="orderStatus">
          <th mat-header-cell *matHeaderCellDef>Order Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.orderStatus || "___" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="paymentStatus">
          <th mat-header-cell *matHeaderCellDef>Payment Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.paymentStatus || "___" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef>Total Amount</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.totalAmount || "___" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <div class="dropMenuMain">
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="navigateToOrderDetails(element?.id)"
                >
                  View
                </button>
                <button
                  mat-menu-item
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="orderUpdate(element)"
                >
                  Edit
                </button>
              </mat-menu>
              <svg
                mat-button
                [matMenuTriggerFor]="menu"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                  fill="black"
                />
                <path
                  d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                  fill="black"
                />
                <path
                  d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                  fill="black"
                />
              </svg>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="no-data-found" *ngIf="!totalItems">
        <img src="../../assets/images/no-data-found.png" alt="noData" />
      </div>
    </div>

    <mat-paginator
      *ngIf="totalItems"
      [length]="totalItems"
      [pageSize]="itemsPerPage"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-closeMain"
            data-bs-dismiss="modal"
            aria-label="Close"
            #closeModal
          >
            <img alt="" src="../../assets/images/close-icon1.png" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row rowSpaceMain">
            <form [formGroup]="createForm" (ngSubmit)="updateOrder()">
              <div class="col-sm-12 spaceMainall">
                <h2>UPDATE ORDER</h2>
                <div class="formFieldMainAll">
                  <span>Order Status</span>
                  <mat-form-field class="example-full-width">
                    <!-- <input matInput placeholder="Order Status" formControlName="orderStatus"> -->
                    <mat-select formControlName="orderStatus">
                      <mat-option value="placed">Placed</mat-option>
                      <mat-option value="confirmed">Confirmed</mat-option>
                      <mat-option value="shipped">Shipped</mat-option>
                      <mat-option value="completed">Completed</mat-option>
                      <mat-option value="cancelled">Cancelled</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="formFieldMainAll">
                  <span>Total Amount</span>
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="Total Amount"
                      formControlName="totalAmount"
                    />
                  </mat-form-field>
                </div>
                <div class="formFieldMainAll">
                  <span>Payment Status</span>
                  <mat-form-field class="example-full-width">
                    <!-- <input matInput placeholder="Payment Status" formControlName="paymentStatus"> -->
                    <mat-select formControlName="paymentStatus">
                      <mat-option value="failed">Pending</mat-option>
                      <mat-option value="success">Paid</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="formFieldMainAll">
                  <button type="submit" class="addUserSend">SUBMIT</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
