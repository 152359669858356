import { Component } from '@angular/core';
import {
  DASHBOARD,
  LOW_STOCK_ITEMS,
  ORDERS_PLACED_TODAY,
} from '../helper/queries';
import { Apollo } from 'apollo-angular';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { currencyType } from '../helper/constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class DashboardComponent {
  dashboardData: any;
  cards: any = [];

  dataSource = new MatTableDataSource(); // user's table source

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  totalItemsTwo: number | undefined;
  itemsPerPageTwo: number = 10;
  currentPageTwo: number = 1;

  displayedColumns: string[] = [
    'mfgName',
    'partNumber',
    'partName',
    'qtyAvail',
  ]; // user's tables colums

  dataSourceTwo = new MatTableDataSource(); // user's table source

  displayedColumnsTwo: string[] = [
    'username',
    'orderNo',
    'orderStatus',
    'paymentStatus',
    'totalAmount',
  ]; // user's tables colums

  constructor(private readonly apollo: Apollo) {}

  ngOnInit() {
    this.dashboard();
    this.lowStockItems();
    this.ordersPlacedToday();
  }

  dashboard() {
    this.apollo
      .watchQuery({
        query: DASHBOARD,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.cards = [
          {
            value: data?.dashboardOrderCount?.orderPlaced,
            label: 'Order Placed',
          },
          {
            value: data?.dashboardOrderCount?.ordersDelivered,
            label: 'Orders Delivered',
          },
          {
            value: data?.dashboardOrderCount?.ordersThisMonth,
            label: 'Orders This Month',
          },
          {
            value: data?.dashboardOrderCount?.activeCustomers,
            label: 'Active Customers',
          },
          {
            value: data?.dashboardOrderCount?.activeEnquiries,
            label: 'Active Enquiries',
          },
        ];
      });
  }

  lowStockItems() {
    this.apollo
      .watchQuery({
        query: LOW_STOCK_ITEMS,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.dataSource = data?.lowStockItems?.items?.map((resp: any) => ({
          productSku: resp?.productSku,
          mfgName: resp?.mfgName,
          partNumber: resp?.partNumber,
          partName: resp?.partName,
          qtyAvail: resp?.qtyAvail,
        }));
        this.totalItems = data?.lowStockItems?.pageInfo?.totalItems;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.lowStockItems();
  }

  ordersPlacedToday() {
    this.apollo
      .watchQuery({
        query: ORDERS_PLACED_TODAY,
        variables: {
          page: this.currentPageTwo,
          limit: this.itemsPerPageTwo,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.dataSourceTwo = data?.ordersPlacedToday?.items?.map(
          (resp: any) => ({
            username: resp?.userData?.username,
            orderNo: resp?.orderNo,
            orderStatus: resp?.orderStatus,
            paymentStatus: resp?.paymentStatus,
            totalAmount: currencyType + ' ' + resp?.totalAmount,
          })
        );
        this.totalItemsTwo = data?.ordersPlacedToday?.pageInfo?.totalItems;
      });
  }

  onPageChangeTwo(event: PageEvent) {
    this.currentPageTwo = event.pageIndex + 1;
    this.itemsPerPageTwo = event.pageSize;
    this.ordersPlacedToday();
  }
}
