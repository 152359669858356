import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { debounceTime } from 'rxjs';
import * as XLSX from 'xlsx';
import { timeDelay, STOCK, STATUS_BUTTONS, STATUS } from '../helper/constant';
import {
  ADD_PARTS,
  BULK_UPLOAD_PARTS,
  CATEGORY_LIST,
  GET_ONE_PARTS,
  LIST_PARTS,
  LIST_SUBCAT_OF_CAT,
  MANUFACTURERS,
  UPDATE_PARTS,
  VEHICLES_LIST,
} from '../helper/queries';
import { FireImgUploadService } from '../service/imageupload/fire-img-upload.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES, VALIDATION_MESSAGES } from '../helper/messages';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class InventoryComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model
  @ViewChild('closeModalFilter') closeModalFilter: any; //close Model
  @ViewChild('closeModalBulk') closeModalBulk: any;
  @ViewChild('fileSelect') fileSelect!: ElementRef;
  @Input() loader: string =
    'https://media.tenor.com/images/f864cbf3ea7916572605edd3b3fe637f/tenor.gif';
  @Input() height: number = 200;
  @Input() width: number = 200;
  @Input() imagePopupData: any;

  isLoading: boolean;

  search = new FormControl(''); //  table serch
  displayedColumns: string[] = [
    'productCode',
    'productSku',
    'partNumber',
    'partName',
    'mfgName',
    'qtyAvail',
    'image',
    'stockStatus',
    'status',
    'action',
  ]; // tables colums
  selectedFiles: File[] = []; // Array to hold selected files
  dataSource = new MatTableDataSource(); // table source

  createForm!: FormGroup; // user create
  createSubmit: boolean | undefined;

  filterForm!: FormGroup; // filterForm
  bulkUploadForm!: FormGroup; // filterForm

  CSVFile: any;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  partImage: any = null;

  vehiclesDropDown: any;
  categoriesDropDown: any;
  subcategoriesDropDown: any;
  manufacturersDropDown: any;

  isUploading: boolean = false;
  imagePath!: string;

  isEditMode: boolean = false;


  imagePreviews: { url: string, fileName: string }[] = [];
  partImageUrls: string[] = [];



  readonly INSTOCK = STOCK.INSTOCK;
  readonly OUTOFSTOCK = STOCK.OUTOFSTOCK;
  readonly STATUS_BUTTONS = STATUS_BUTTONS;
  readonly ACTIVE = STATUS.ACTIVE;
  readonly INACTIVE = STATUS.INACTIVE;
  filter = {
    categoryId: null,
    subCategory: null,
    manufacturer: null,
  };

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  closeFilterModal(): void {
    this.closeModalFilter.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private imageUploadService: FireImgUploadService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: [''],
      productSku: ['', [Validators.required]],
      partNumber: ['', [Validators.required]],
      // productCode: ['', [Validators.required]],
      partName: ['', [Validators.required]],
      partDesc: ['', [Validators.required]],
      mfgName: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      sellerPrice: ['', [Validators.required]],
      resellerPrice: ['', [Validators.required]],
      vehicleId: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      subCatId: ['', [Validators.required]],
      stockStatus: ['', [Validators.required]],
      status: ['', [Validators.required]],
      partLabel: ['', [Validators.required]],
      variant:['']
    });

    this.filterForm = this.formBuilder.group({
      category: [null],
      subCategory: [null],
      manufacturer: [null],
    });

    this.bulkUploadForm = this.formBuilder.group({
      uploadCSV: [null],
    });

    this.listParts();
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listParts(searchTerm);
      });

    this.listManufacturers();
    this.listVehicle();
    this.listCategories();
    this.imagePath = '../../assets/images/image-icon-black.svg';
  }

  hideLoader() {
    this.isLoading = false;
  }

  listParts(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_PARTS,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
          filter: this.filter,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listPartsWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            productSku: resp?.productSku,
            partNumber: resp?.partNumber,
            partName: resp?.partName,
            mfgName: resp?.mfgName,
            sellerPrice: resp?.sellerPrice,
            resellerPrice: resp?.resellerPrice,
            qtyAvail: resp?.qtyAvail,
            stockStatus: resp?.stockStatus,
            status: resp?.status,
            imageUrl: resp?.imageUrl,
            productCode: resp?.productCode,
            imagePopup: resp?.imageUrl,
          })
        );

        this.totalItems = data?.listPartsWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listPartsWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage = data?.listPartsWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listParts(this.search.value);
  }

  // onFileChange(event: any) {
  //   if (!event.target.files || !event.target.files[0]) {
  //     return;
  //   }
  //   this.isUploading = true; // Show loader
  //   this.onFileSelected(event);
  //   const fileName = event.target.files[0].name;
  //   this.createForm?.get('partLabel')?.setValue(fileName);
  // }

  // onFileSelected(event: any) {
  //   const fileObj = event.target.files[0];
  //   this.imageUploadService
  //     .uploadImage(fileObj)
  //     .then((downloadURL) => {
  //       this.partImage = downloadURL;
  //       this.isUploading = false; // Hide loader after upload
  //     })
  //     .catch((error) => {
  //       console.error('Error uploading image:', error);
  //       this.isUploading = false; // Hide loader after upload
  //     });
  // }

// onFileChange(event: any) {
//   if (!event.target.files || event.target.files.length === 0) {
//     return;
//   }
//   this.isUploading = true; // Show loader
  
//   const files = event.target.files;
//   const fileNames = Array.from(files).map((file: any) => file.name).join('; ');
//   this.createForm?.get('partLabel')?.setValue(fileNames); // Update the form control with file names
  
//   this.onFileSelected(files); // Pass the files to be uploaded
// }

// onFileSelected(files: FileList) {
//   const uploadPromises = Array.from(files).map((file: File) => {
//     return this.imageUploadService.uploadImage(file);
//   });

//   Promise.all(uploadPromises)
//     .then((downloadURLs) => {
//       this.partImage = downloadURLs.join('; '); // Join the URLs with a semicolon
//       this.isUploading = false; // Hide loader after upload
//     })
//     .catch((error) => {
//       console.error('Error uploading images:', error);
//       this.isUploading = false; // Hide loader even if there's an error
//     });
// }


  listManufacturers() {
    this.apollo
      .watchQuery({
        query: MANUFACTURERS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.manufacturersDropDown = data?.listManufacturers;
      });
  }

  filterApply(): void {
    const value = this.filterForm.value;
    this.filter.categoryId = value.category;
    this.filter.subCategory = value.subCategory;
    this.filter.manufacturer = value.manufacturer;
    this.listParts(this.search.value);
    this.closeFilterModal();
  }

  clearFilter(): void {
    this.filter.categoryId = null;
    this.filter.subCategory = null;
    this.filter.manufacturer = null;
    this.search.setValue('');
    this.listParts();
    this.filterForm.reset();
    this.closeFilterModal();
  }

  listVehicle() {
    this.apollo
      .watchQuery({
        query: VEHICLES_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.vehiclesDropDown = data?.vehiclesList.map((item: any) => ({
          ...item,
          combinedLabel: `${item.make} ${item.model} ${item.year}`,
        }));
      });
  }

  listCategories() {
    this.apollo
      .watchQuery({
        query: CATEGORY_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.categoriesDropDown = data?.listCategories;
      });
  }
  
onFileChange(event: any) {
  const files: FileList | null = event.target.files;
  if (!files || files.length === 0) {
    return;
  }

  this.isUploading = true; // Show loader

  // Convert FileList to an array and process files one by one
  Array.from(files).forEach((file: File) => {
    this.uploadFile(file);
  });
}

uploadFile(file: File) {
  this.imageUploadService.uploadImage(file).then((downloadURL) => {
    this.partImageUrls.push(downloadURL);
    this.imagePreviews.push({ url: downloadURL, fileName: file.name });
    this.partImage = this.partImageUrls.join(';');
    this.createForm.get('partLabel')?.setValue(this.partImage);
    this.isUploading = false; // Hide loader after upload
  }).catch((error) => {
    console.error('Error uploading image:', error);
    this.isUploading = false; // Hide loader after upload
  });
}

removeImage(image: { url: string, fileName: string }) {
  this.imagePreviews = this.imagePreviews.filter(img => img.url !== image.url);
  this.partImageUrls = this.partImageUrls.filter(url => url !== image.url);
  const updatedImageUrls = this.partImageUrls.join(';');
  this.createForm.get('partLabel')?.setValue(updatedImageUrls);
  this.partImage = updatedImageUrls;
}
resetForm(): void {
  this.createForm.reset();
  this.fileSelect.nativeElement.value = ''; // Assuming fileSelect is a reference to your file input
  this.imagePreviews = []; // Clear the preview list
  this.partImageUrls = []; // Clear the URLs list
  this.partImage = ''; // Clear the partImage
  console.log(this.fileSelect.nativeElement.value,"this.fileSelect.nativeElement.value");
  console.log(this.imagePreviews,"array");
  console.log(this.partImageUrls, "partImageUrls");
  console.log(this.partImage, "partImage");
  
  
}
  
  createInventory(): void {
    if (this.createForm.valid) {
      const value = this.createForm.value;
      console.log(value);
      
      const partImg = this.imageUploadService.getFileName(value?.imageUrl);
      console.log(partImg);
      
      const data = {
        productSku: value?.productSku,
        partNumber: value?.partNumber,
        partName: value?.partName,
        partDesc: value?.partDesc,
        mfgName: value?.mfgName,
        qtyAvail: parseInt(value?.quantity),
        sellerPrice: value?.sellerPrice,
        resellerPrice: value?.resellerPrice,
        imageUrl: this.partImage,
        vehicleId: value?.vehicleId,
        categoryId: value?.categoryId?.toString(),
        subCatId: value?.subCatId,
        stockStatus: parseInt(value?.stockStatus),
        status: parseInt(value?.status),
        partLabel: partImg,
        variants: value?.variant
      };
      if (value?.id) {
        this.apollo
          .mutate({
            mutation: UPDATE_PARTS,
            variables: {
              partsId: value?.id,
              updateParts: data,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(
            () => {
              this.listParts(this.search.value);
              this.resetForm();
              this.toastr.success(MESSAGES.PART_UPDATED);
              this.closeModel();
            },
            (error) => {
              this.toastr.error(error.messages);
              console.error(error);
            }
          );
      } else {
        this.apollo
          .mutate({
            mutation: ADD_PARTS,
            variables: {
              createParts: data,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(
            () => {
              this.listParts(this.search.value);
              this.resetForm();
              this.toastr.success(MESSAGES.NEW_PART_ADDED);
              this.closeModel();
            },
            (error) => {
              this.toastr.error(MESSAGES.ERROR_ADDING_NEWPART);
              console.error(error);
            }
          );
      }
    }
  }
  partStatusUpdate(id: number, status: number) {
    this.apollo
      .mutate({
        mutation: UPDATE_PARTS,
        variables: {
          partsId: id,
          updateParts: {
            status: status,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe(
        () => {
        this.listParts();
        this.toastr.success(MESSAGES.SATUS_CHANGE_SUCCESS);
      },
      (error) => {
        this.toastr.error(MESSAGES.SATUS_CHANGE_FAILED);
      }
    );
  }
  setFormData(id: any): void {
    if (id) {
      this.apollo
        .watchQuery({
          query: GET_ONE_PARTS,
          variables: {
            partsId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .valueChanges.subscribe(({ data }: any) => {
          const imageUrl = data?.getOneParts?.imageUrl;
  
          // Split the imageUrl into an array using semicolons
          const imageUrlArray = imageUrl ? imageUrl.split(';') : [];
  
          // Map the URLs to image preview objects
          const imagePreviews = imageUrlArray?.map((url: string) => {
            const isFirebaseUrl = url.startsWith('https://firebasestorage.googleapis.com');
            let fileName = isFirebaseUrl
            ? this.imageUploadService.getFileName(url) // Extract the file name for Firebase URL
            : url; // If not Firebase, use the URL as the file name
            if (isFirebaseUrl) {
              // Extract the file name from the Firebase URL
              fileName = this.imageUploadService.getFileName(url) || 'Unknown';
            } else {
              // Use the URL as the file name for non-Firebase URLs
              fileName = url;
            }
                      // Ensure fileName is defined and limit its length to 15 characters
          const maxLength = 15;
          if (fileName.length > maxLength) {
            fileName = fileName.slice(0, maxLength) + '...'; // Trim and add "..."
          }
            return { url, fileName }; // Create an object with the URL and file name
          });
  
          // Update form and UI with image previews and other data
          const newData = {
            id: data?.getOneParts?.id,
            productSku: data?.getOneParts?.productSku,
            partNumber: data?.getOneParts?.partNumber,
            partName: data?.getOneParts?.partName,
            partDesc: data?.getOneParts?.partDesc,
            mfgName: data?.getOneParts?.mfgName,
            quantity: data?.getOneParts?.qtyAvail?.toString(),
            sellerPrice: data?.getOneParts?.sellerPrice,
            resellerPrice: data?.getOneParts?.resellerPrice,
            vehicleId: data?.getOneParts?.vehicleId,
            categoryId: data?.getOneParts?.categoryId,
            subCatId: data?.getOneParts?.subCatId,
            partLabel: imagePreviews.map((img: { fileName: any; }) => img.fileName).join(';'), // Set the partLabel from the file names
            stockStatus: data?.getOneParts?.stockStatus?.toString(),
            status: data?.getOneParts?.status?.toString(),
            variant: data?.getOneParts?.variants
          };
  
          // Set form data and image previews
          this.openModel();
          this.isEditMode = true;
          this.createForm.patchValue(newData);
          this.imagePreviews = imagePreviews; // Display images in the UI
          this.partImageUrls = imageUrlArray; // Store the image URLs for form submission
          this.partImage = imageUrlArray.join(';'); // Join URLs to form a single string
          
          // Call the subcategory list function with the selected category
          this.listSubCatOfCategory(newData.categoryId);
        });
    }
  }
  

  // setFormData(id: any): void {
  //   if (id) {
  //     this.apollo
  //       .watchQuery({
  //         query: GET_ONE_PARTS,
  //         variables: {
  //           partsId: id,
  //         },
  //         fetchPolicy: 'no-cache',
  //       })
  //       .valueChanges.subscribe(({ data }: any) => {
  //         const imageUrl = data?.getOneParts?.imageUrl;
  //         const fileName = imageUrl ? imageUrl.split('/').pop() : '';
  //         const newData = {
  //           id: data?.getOneParts?.id,
  //           productSku: data?.getOneParts?.productSku,
  //           partNumber: data?.getOneParts?.partNumber,
  //           partName: data?.getOneParts?.partName,
  //           partDesc: data?.getOneParts?.partDesc,
  //           mfgName: data?.getOneParts?.mfgName,
  //           quantity: data?.getOneParts?.qtyAvail?.toString(),
  //           sellerPrice: data?.getOneParts?.sellerPrice,
  //           resellerPrice: data?.getOneParts?.resellerPrice,
  //           vehicleId: data?.getOneParts?.vehicleId,
  //           categoryId: data?.getOneParts?.categoryId,
  //           subCatId: data?.getOneParts?.subCatId,
  //           partLabel: fileName,
  //           stockStatus: data?.getOneParts?.stockStatus?.toString(),
  //           status: data?.getOneParts?.status?.toString(),
  //           variant: data?.getOneParts?.variants
  //         };
          
  //         this.openModel();
  //         this.isEditMode = true;
  //         this.createForm.patchValue(newData);
  //         this.listSubCatOfCategory(newData.categoryId);
  //       });
  //   }
  // }
  listSubCatOfCategory(event: any) {
    const categoryId = event.categoryId || event;
    this.apollo
      .watchQuery({
        query: LIST_SUBCAT_OF_CAT,
        variables: {
          categoryId: categoryId,
          make: null,
          year: null,
          model: null,
          engine: null,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.subcategoriesDropDown = data?.listSubCatByCat;
      });
  }


  popupDataImg(img: string): void {
    this.imagePopupData = img?.split(';')[0] ;
  }
  navigateToProductEnquiryView(id: string): void {
    if (id) {
      this.router.navigate(['/inventory-enquiry', id]);
    } else {
      this.toastr.error(VALIDATION_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }

  onFileCSVChange(event: any) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    this.CSVFile = event.target.files[0];
    // const fileName = event.target.files[0].name;
    // this.bulkUploadForm?.get('uploadCSVLabel')?.setValue(fileName);
  }

  InventorybulkUpload(): void {
    // Ensure the CSVFile is a File or Blob
    const fileToUpload =
      this.CSVFile instanceof File || this.CSVFile instanceof Blob
        ? this.CSVFile
        : null;

    if (!fileToUpload) {
      console.error('Invalid file. The CSVFile must be a File or Blob.');
      return;
    }

    console.log(fileToUpload);
    console.log(this.CSVFile);

    this.apollo
      .mutate({
        mutation: BULK_UPLOAD_PARTS,
        variables: {
          file: this.CSVFile,
        },
        fetchPolicy: 'no-cache',
        context: {
          headers: {
            'x-apollo-operation-name': 'bulkUploadParts',
          },
        },
      })
      .subscribe(
        () => {
          this.toastr.success('Bulk upload successful.');
          this.bulkUploadForm.reset();
          this.closeModalBulk.nativeElement.click();
          this.listParts();
        },
        (error) => {
          console.error('Error during bulk upload:', error);
          this.toastr.error('Bulk upload failed. Please try again.');
        }
      );
  }
  partDelete(id: number) {
    this.apollo
      .mutate({
        mutation: UPDATE_PARTS,
        variables: {
          partsId: id,
          updateParts: {
            status: 2,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe(
        () => {
        this.listParts();
        this.toastr.success(MESSAGES.DATA_DELETED);
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }
}