import { Component, signal } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  LIST_ALL_USER_TYPES,
  PERMISSION_LIST,
  PERMISSION_LIST_VIEW,
  UPDATE_ROLE_PERMISSION,
  USER_TYPES_LIST,
} from '../helper/queries';

@Component({
  selector: 'app-modules-and-permissions',
  templateUrl: './modules-and-permissions.component.html',
  styleUrls: ['./modules-and-permissions.component.scss'],
})
export class ModulesAndPermissionsComponent {
  usertypes: any;
  permissions: any;
  permissionsList: any;

  constructor(private readonly apollo: Apollo) {}

  ngOnInit() {
    this.usertypesList();
    this.permissionListView();
    this.permissionList();
  }

  usertypesList(): void {
    this.apollo
      .watchQuery({
        query: LIST_ALL_USER_TYPES,
        fetchPolicy: 'no-cache',
      })
      .valueChanges?.subscribe(({ data }: any) => {
        this.usertypes = data?.listAllUsertypes
          ?.filter(
            (usertype: any) =>
              usertype?.id != 2 && usertype?.id != 3 && usertype?.id != 4
          )
          ?.sort((a: any, b: any) => a?.id - b?.id);
      });
  }

  permissionListView(): void {
    this.apollo
      .watchQuery({
        query: PERMISSION_LIST_VIEW,
        fetchPolicy: 'no-cache',
      })
      .valueChanges?.subscribe(({ data }: any) => {
        this.permissionsList = data?.permissionListView;
      });
  }

  permissionList(): void {
    this.apollo
      .watchQuery({
        query: PERMISSION_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges?.subscribe(({ data }: any) => {
        this.permissions = data?.permissionList;
      });
  }

  updateRole(roleId: number, permissionId: number[]): void {
    this.apollo
      .mutate({
        mutation: UPDATE_ROLE_PERMISSION,
        variables: {
          updateRolePermission: {
            roleId: roleId,
            permissionId: permissionId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe(() => {
        this.usertypesList();
        this.permissionListView();
      });
  }

  isCheckedHead(userId: number, modules: any): boolean {
    const modulesLength = modules?.length;
    const moduleId = modules[0].id;

    const findUser = this.usertypes?.find((_a: any) => _a?.id === userId);
    if (findUser) {
      const moduleData = findUser?.rolePermissionData?.filter(
        (item: any) => item?.permissionData?.moduleId === moduleId
      );
      if (modulesLength == moduleData?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleHeadChecked(event: Event, userId: number, moduleId: number): void {
    const toggleType = (event.target as HTMLInputElement).checked;
    const findUser = this.usertypes?.find((_a: any) => _a?.id === userId);

    //subfunction permission list ids
    const subFunctionPermissionIds = this.permissions
      ?.filter(
        (permission: any) => permission.id && permission?.moduleId == moduleId
      )
      .map((item: any) => item.id);

    // users permission ids
    const userPermissionIds = findUser?.rolePermissionData?.map(
      (item: any) => item?.permissionId
    );

    if (toggleType) {
      const ids = [
        ...new Set([...userPermissionIds, ...subFunctionPermissionIds]),
      ];
      this.updateRole(userId, ids);
    } else {
      const ids = userPermissionIds.filter(
        (item: any) => !subFunctionPermissionIds.includes(item)
      );
      this.updateRole(userId, ids);
    }
  }

  isChecked(userId: number, moduleId: number, functionId: number): boolean {
    const findUser = this.usertypes?.find((_a: any) => _a?.id === userId);

    if (findUser) {
      const moduleData = findUser?.rolePermissionData?.find(
        (item: any) =>
          item?.permissionData?.moduleId === moduleId &&
          item?.permissionData?.functionId === functionId
      );
      if (moduleData) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleChecked(
    event: Event,
    userId: number,
    moduleId: number,
    permissionId: number
  ): void {
    const permissionType = (event.target as HTMLInputElement).checked;
    const findUser = this.usertypes?.find((_a: any) => _a?.id === userId);

    // users permission ids
    const userPermissionIds = findUser?.rolePermissionData?.map(
      (item: any) => item?.permissionId
    );

    if (permissionType) {
      userPermissionIds.push(permissionId);
      const ids: any = [...new Set(userPermissionIds)];
      this.updateRole(userId, ids);
    } else {
      const ids = userPermissionIds.filter(
        (item: any) => item !== permissionId
      );
      this.updateRole(userId, ids);
    }
  }
}
