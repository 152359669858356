import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { debounceTime } from 'rxjs';
import { timeDelay } from '../helper/constant';
import { LIST_ENGINE } from '../helper/queries';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class EngineComponent {
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = ['id', 'engineId', 'vehicleId', 'engineName']; // tables colums

  dataSource = new MatTableDataSource(); // table source

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  constructor(private readonly apollo: Apollo) {}

  ngOnInit() {
    this.listEngine();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listEngine(searchTerm);
      });
  }

  listEngine(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_ENGINE,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listEngineWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            engineId: resp?.engineId,
            vehicleId: resp?.vehicleId,
            engineName: resp?.engineName,
          })
        );

        this.totalItems = data?.listEngineWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listEngineWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listEngineWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listEngine(this.search.value);
  }
}
