export const moduleName = {
  //Dashbard
  viewDashbard: 'viewDashbard', //..

  //Orders
  viewOrder: 'viewOrder', //..
  editOrder: 'editOrder',
  exportOrder: 'exportOrder',

  // Product Enquiries
  viewProductEnquiries: 'viewDashbard', //.. //  new data
  enquiryLinkSend: 'enquiryLinkSend',

  // Subscription Plans
  viewSubscription: 'viewDashbard', //.. //  new data
  deleteSubscription: 'deleteSubscription',
  editSubscription: 'editSubscription',
  addSubscription: 'addSubscription',

  //Transactions
  viewTransaction: 'viewDashbard', //.. //  new data
  exportTransaction: 'exportTransaction',

  //Inventories
  viewInventory: 'viewDashbard', //.. //  new data
  deleteInventory: 'deleteInventory',
  editInventory: 'editInventory',
  addInventory: 'addInventory',

  //Categories
  viewCategory: 'viewDashbard', //.. //  new data
  deleteCategory: 'deleteCategory',
  editCategory: 'editCategory',
  addCategory: 'addCategory',

  //Sub Categories
  viewAddSubCategory: 'viewDashbard', //.. //  new data
  deleteSubCategory: 'deleteSubCategory',
  editSubCategory: 'editSubCategory',
  addSubCategory: 'addSubCategory',

  //Customers
  viewCustomer: 'viewCustomer', //..
  activateCustomer: 'activateCustomer',
  deleteCustomer: 'deleteCustomer',
  editCustomer: 'editCustomer',
  addCustomer: 'addCustomer',

  //Users
  viewUser: 'viewUser', //..
  activateUser: 'activateUser',
  deleteUser: 'deleteUser',
  editUser: 'editUser',
  addUser: 'addUser',

  //Roles
  viewRoles: 'viewRoles', //..
  deleteRoles: 'deleteRoles',
  editRoles: 'editRoles',
  addRoles: 'addRoles',

  //Permissions
  viewPermission: 'viewPermission', //..
};
