import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { USER } from '../helper/queries';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { STATUS, currencyType } from '../helper/constant';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['../users/users.component.scss'],
  providers: [DatePipe],
})
export class UserDetailsComponent {
  search = new FormControl(''); //  table serch

  displayedColumns: string[] = [
    'orderNo',
    'orderDate',
    'orderStatus',
    'totalAmount',
    'paymentStatus',
  ]; // tables colums

  dataSource = new MatTableDataSource(); // table source

  readonly GARAGE = 'garageOwner';
  readonly AGENT = 'agent';
  readonly CUSTOMER = 'customer';
  readonly ACTIVE = STATUS.ACTIVE;
  readonly INACTIVE = STATUS.INACTIVE;

  userData: any;
  userType: any;

  constructor(
    private readonly apollo: Apollo,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const orderID = params['id'];
      this.userOne(parseInt(orderID));
    });
  }
  back(): void {
    this.location.back()
  }

  checkDate(dateString: string): string {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    if (givenDate < currentDate) {
      return 'expired';
    } else {
      return 'active';
    }
  }

  userOne(id: number): void {
    if (id) {
      this.apollo
        .watchQuery({
          query: USER,
          variables: {
            id: id,
          },
          fetchPolicy: 'no-cache',
        })
        .valueChanges.subscribe(({ data }: any) => {
          this.userType = data?.getOneUser?.usertypeData?.roleName;
          
          switch (this.userType) {
            case this.GARAGE:
              this.userData = {
                name: data?.getOneUser?.garageOwner?.garageName,
                email: data?.getOneUser?.email,
                address: data?.getOneUser?.garageOwner?.garageAddress,
                number: data?.getOneUser?.garageOwner?.registrationNumber,
                phoneNumber: data?.getOneUser?.phone,
                status: data?.getOneUser?.status,
                planData: {
                  name: data?.getOneUser?.garageOwner?.planData?.planName,
                  startDate: data?.getOneUser?.garageOwner?.startDate,
                  endDate: data?.getOneUser?.garageOwner?.endDate,
                  status: this.checkDate(
                    data?.getOneUser?.garageOwner?.endDate
                  ),
                },
              };
              break;

            case this.AGENT:
              this.userData = {
                name: data?.getOneUser?.agent?.agencyName,
                email: data?.getOneUser?.email,
                address: data?.getOneUser?.agent?.agencyAddress,
                phoneNumber: data?.getOneUser?.phone,
                status: data?.getOneUser?.status,
                number: data?.getOneUser?.agent?.licenseNumber,
                planData: {
                  name: data?.getOneUser?.agent?.planData?.planName,
                  startDate: data?.getOneUser?.agent?.startDate,
                  endDate: data?.getOneUser?.agent?.endDate,
                  status: this.checkDate(data?.getOneUser?.agent?.endDate),
                },
              };
              break;

            case this.CUSTOMER:
              this.userData = {
                name:
                  data?.getOneUser?.customer?.firstName +
                  ' ' +
                  data?.getOneUser?.customer?.lastName,
                email: data?.getOneUser?.email,
                address: data?.getOneUser?.customer?.address,
                phoneNumber: data?.getOneUser?.phone,
                status: data?.getOneUser?.status,
                number: data?.getOneUser?.customer?.licenseNumber,
                planData: {
                  name: data?.getOneUser?.customer?.planData?.planName,
                  startDate: data?.getOneUser?.customer?.startDate,
                  endDate: data?.getOneUser?.customer?.endDate,
                  status: this.checkDate(data?.getOneUser?.customer?.endDate),
                },
              };
              break;

            default:
              this.userData = {
                name:
                  data?.getOneUser?.customer?.firstName +
                  ' ' +
                  data?.getOneUser?.customer?.lastName,
                email: data?.getOneUser?.email,
                phoneNumber: data?.getOneUser?.phone,
                status: data?.getOneUser?.status,
                address: data?.getOneUser?.customer?.address,
                number: data?.getOneUser?.customer?.licenseNumber,
                planData: {
                  name: data?.getOneUser?.customer?.planData?.planName,
                  startDate: data?.getOneUser?.customer?.startDate,
                  endDate: data?.getOneUser?.customer?.endDate,
                  status: this.checkDate(data?.getOneUser?.customer?.endDate),
                },
              };
              break;
          }

          this.dataSource = data?.getOneUser?.orderHistory?.map(
            (resp: any) => ({
              id: data?.getOneUser?.id,
              orderNo: resp?.orderNo,
              username: data?.getOneUser?.garageOwner?.garageName,
              orderDate: this.datePipe.transform(
                resp?.createdAt,
                'yyyy-MM-dd hh:mm a'
              ), // Convert timestamp to date
              orderStatus: resp?.orderStatus,
              paymentStatus:
                resp?.paymentData?.paymentStatus?.toLowerCase() === 'success'
                  ? 'Paid'
                  : 'Pending',
              totalAmount:
                currencyType + ' ' + resp?.paymentData?.paymentAmount,
            })
          );
        });
    }
  }
}
