import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { ADD_SETTINGS, GET_ONE_SETTINGS, LIST_ENGINE, LIST_SETTINGS, UPDATE_SETTINGS } from '../helper/queries';
import { debounceTime } from 'rxjs';
import { DISCOUNT_TYPE, timeDelay } from '../helper/constant';
import { MESSAGES } from '../helper/messages';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';


@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss', '../users/users.component.scss']
})
export class AdminSettingsComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = ['tax', 'shipping', 'discount','discountType' , 'action']; // tables colums
  dataSource = new MatTableDataSource(); // table source
  discountTypes: any;
  createForm!: FormGroup; // user create
  totalItems: number | undefined | null;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  isEditMode: boolean = false;
  createFormSubmit: boolean | undefined;
  adminData: any = {}; 


  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private location: Location
  ) {}

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      tax:['', [Validators.required,  Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
      shipping:['', [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
      discount:['', [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
      discountType:[ '',[Validators.required]],
      adminEmail:[ '',[Validators.required]],
      adminPhone:[ '',[Validators.required]],
      address:[ '',[Validators.required]],
      facebook:[ '',[Validators.required]],
      whatsApp:[ '',[Validators.required]],
      twitter:[ '',[Validators.required]],
      linkedIn:[ '',[Validators.required]],
    })
    // this.listSettings();
    this.populateDiscountTypes();
    this.getOne();
  }
  populateDiscountTypes() {
    this.discountTypes = Object.keys(DISCOUNT_TYPE).map(key => ({
      key: key,
      value: DISCOUNT_TYPE[key as keyof typeof DISCOUNT_TYPE],
    }));
  }

  
  // adminData = {
  //   adminPhone: '',
  //   adminEmail: '',
  //   tax: '',
  //   discount: '',
  //   shipping: '',
  //   discountType: '',
  //   address: '',
  //   facebook: '',
  //   whatsApp: '',
  //   twitter: '',
  //   linkedIn: ''
  // };

  // toggleEdit(){
  //   this.isEditMode = !this.isEditMode;
  // }

  // toggleEdit() {
  //   this.isEditMode = !this.isEditMode;

  //   if (this.isEditMode) {
  //     this.apollo.mutate({
  //       mutation: UPDATE_SETTINGS,
  //       variables:{
  //         updateSettings: {
  //           adminEmail: this.adminData.adminEmail,
  //         adminPhone: this.adminData.adminPhone,
  //         tax: this.adminData.tax,
  //         discount: this.adminData.discount,
  //         shipping: this.adminData.shipping,
  //         discountType: this.adminData.discountType,
  //         address: this.adminData.address,
  //         facebook: this.adminData.facebook,
  //         whatsApp: this.adminData.whatsApp,
  //         twitter: this.adminData.twitter,
  //         linkedIn: this.adminData.linkedIn
  //         } ,
  //         settingsId:1
  //       },
  //       fetchPolicy:'no-cache'
  //     })
  //     .subscribe({
  //       next: () =>  {
  //         this.getOne();
  //         // this.isEditMode = !this.isEditMode
          
  //       }
  //     })
  //     this.isEditMode = !this.isEditMode
  //   }
  // }
  // openModel(): void {
  //   this.createForm.reset();
  //   this.openModal.nativeElement.click();
  // }


  setFormData(data: any): void {
    const newData = {
      id: data?.id,
      tax: data?.tax,
      shipping: data?.shipping,
      discount: data?.discount,
      discountType: data?.discountType,
      adminEmail: data?.adminEmail,
      adminPhone: data?.adminPhone,
      address: data?.address,
      facebookLink: data?.facebookLink,
      whatsAppLink: data?.whatsAppLink,
      twitterLink: data?.twitterLink,
      linkedIn: data?.linkedIn
    };
    console.log(newData);
    
    // this.openModel();
    // this.isEditMode = true;
    this.createForm.patchValue(newData);  
  }
  
  // clearForm(): void {
  //   this.isEditMode = false;
  //   this.createForm.reset();
  // }

  // closeModel(): void {
  //   this.createForm.reset();
  //   this.closeModal.nativeElement.click();
  // }

  listSettings(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query:  LIST_SETTINGS,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listAllSettings?.map(
          (resp: any) => ({
            // id: resp?.id,
            tax: resp?.tax,
            shipping: resp?.shipping,
            discount: resp?.discount,
            discountType: resp?.discountType,
            adminEmail: resp?.adminEmail,
            adminPhone: resp?.adminPhone,
            address: resp?.address,
            facebook: resp?.facebook,
            whatsApp: resp?.whatsApp,
            twitter: resp?.twitter,
            linkedIn: resp?.linkedIn

          })
        );
        
        console.log(this.dataSource);
          
        if(!this.dataSource){
          this.totalItems=null;
        };
      });
  }


  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
    
    if (this.isEditMode) {
      this.populateForm();
    }
  }
  // Populate form with current data
  populateForm(): void {
    this.createForm.patchValue({
      adminEmail: this.adminData.adminEmail,
      adminPhone: this.adminData.adminPhone,
      address: this.adminData.address,
      tax: this.adminData.tax,
      shipping: this.adminData.shipping,
      discountType: this.adminData.discountType,
      whatsApp: this.adminData.whatsApp,
      twitter: this.adminData.twitter,
      facebook: this.adminData.facebook,
      linkedIn: this.adminData.linkedIn,
    });
  }

  getOne(){
    this.apollo.watchQuery({
      query: GET_ONE_SETTINGS,
      variables: {
        settingsId: 1
      },
      fetchPolicy: 'no-cache',
    })
    .valueChanges.subscribe(({data}: any) => {
      this.adminData = data?.getOneSettings;
  
      // Populate the form with the admin data
      this.createForm.patchValue({
        id: this.adminData.id,
        tax: this.adminData.tax,
        shipping: this.adminData.shipping,
        discount: this.adminData.discount,
        discountType: this.adminData.discountType,
        adminEmail: this.adminData.adminEmail,
        adminPhone: this.adminData.adminPhone,
        address: this.adminData.address,
        facebook: this.adminData.facebook,
        whatsApp: this.adminData.whatsApp,
        twitter: this.adminData.twitter,
        linkedIn: this.adminData.linkedIn
      });
    });
    this.setFormData(this.createForm)
  }

  edit() {
    if (this.isEditMode) {
      // const updatedSettings = { ...this.createForm.value };
      // console.log(updatedSettings);
      
      this.apollo.mutate({
        mutation: UPDATE_SETTINGS,
        variables: {
          updateSettings: this.createForm.value ,
          settingsId: 1
        },
        fetchPolicy: 'no-cache'
      })
      .subscribe({
        next: (result) => {
          console.log('Update successful:', result);
          this.getOne(); // Refresh data after successful update
          this.isEditMode = !this.isEditMode;
        },
        error: (error) => {
          console.error('Apollo mutation error:', error);
          this.toastr.error('Update failed, please try again.', 'Error');
        }
      });
    }
  }
  openLink(type: 'whatsapp' | 'email' | 'website' | 'twitter' | 'linkedIn' | 'facebook', data: string): void {
    let url: string;
  
    switch (type) {
      case 'whatsapp':
        url = `https://wa.me/${data}`;
        break;
      case 'email':
        url = `mailto:${data}`;
        break;
      case 'twitter':
        url = `https://twitter.com/${data}`;
        break;
      case 'linkedIn':
        url = `https://www.linkedin.com/in/${data}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/${data}`;
        break;
      default:
        console.error('Invalid link type');
        return;
    }
  
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('URL is missing or invalid');
    }
  }
  cancel(){
    this.isEditMode =!this.isEditMode;
  }
  

}
