import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { USER_TYPE_PERMISSIONS } from '../helper/queries';
import { moduleName } from '../helper/permissionData';
import { Store, select } from '@ngrx/store';
import { selectPermissions } from '../reducers/permissions.selectors';
import { Observable, tap } from 'rxjs';
import { setHeaderTitle } from '../reducers/permissions.actions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  moduleName = moduleName;
  permissions$: Observable<any>;
  userPermission: any;

  constructor(
    private router: Router,
    private readonly apollo: Apollo,
    private store: Store
  ) {
    this.permissions$ = this.store.pipe(select(selectPermissions));
  }

  ngOnInit() {
    this.permissions$
      .pipe(
        tap((permissions: any) => {
          this.userPermission = permissions;
          // console.log('Permissions:', permissions);
        })
      )
      .subscribe();
  }

  checkUserPermission(menu: string): boolean {
    const isPermission = this.userPermission?.find(
      (permission: any) => permission?.permissionData?.permissionName === menu
    );
    if (isPermission) {
      return true;
    } else {
      return false;
    }
  }

  updateHeaderTitle(newTitle: string) {
    this.store.dispatch(setHeaderTitle({ headerTitle: newTitle }));
  }
}
