import { gql } from 'apollo-angular';

export const DASHBOARD = gql`
  query {
    dashboardOrderCount {
      orderPlaced
      ordersDelivered
      ordersThisMonth
      activeEnquiries
      activeCustomers
    }
  }
`;

export const UPDATE_SUBCATEGORY_STATUS = gql`
  mutation updateSubCategoryStatus($subCategoryId: Int!, $status: Int!) {
    updateSubCategoryStatus(subCategoryId: $subCategoryId, status: $status) {
      subCatId
      categoryId
      subCatName
      subCatImage
      status
    }
  }
`;

export const UPDATE_CATEGORY_STATUS = gql`
  mutation updateCategoryStatus($categoryId: Int!, $status: Int!) {
    updateCategoryStatus(categoryId: $categoryId, status: $status) {
      id
      categoryId
      vehicleId
      categoryName
      categoryImage
      status
    }
  }
`;

export const LOW_STOCK_ITEMS = gql`
  query lowStockItems($page: Int!, $limit: Int!) {
    lowStockItems(page: $page, limit: $limit) {
      items {
        productSku
        partNumber
        partName
        qtyAvail
        mfgName
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const ORDERS_PLACED_TODAY = gql`
  query ordersPlacedToday($page: Int!, $limit: Int!) {
    ordersPlacedToday(page: $page, limit: $limit) {
      items {
        id
        userId
        addressId
        orderNo
        orderStatus
        paymentStatus
        totalAmount
        userData {
          username
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const LIST_USERS = gql`
  query listUsersWithPagination(
    $page: Int!
    $limit: Int!
    $search: String
    $filter: FilterUserDto
  ) {
    listUsersWithPagination(
      page: $page
      limit: $limit
      search: $search
      filter: $filter
    ) {
      items {
        id
        username
        email
        phone
        profilePicUrl
        roleId
        usertypeData {
          id
          roleName
          isEditable
          status
        }
        customer {
          id
          userId
          firstName
          lastName
          address
        }
        status
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($createUserData: CreateUsersDto!) {
    addUser(createUserData: $createUserData) {
      id
      username
      email
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUsers($id: Int!, $updateUsers: UpdateUserDto!) {
    updateUsers(id: $id, updateUsers: $updateUsers) {
      id
      username
      email
    }
  }
`;

export const USER_STATUS = gql`
  mutation userStatusChange($usersId: Int!, $status: Int!) {
    userStatusChange(usersId: $usersId, status: $status) {
      id
      username
      status
    }
  }
`;

export const GET_ONE_PART = gql`
  query getOneParts($partsId: Int!) {
    getOneParts(partsId: $partsId) {
      id
      productSku
      partNumber
      partName
      partDesc
      mfgName
      sellerPrice
      resellerPrice
      qtyAvail
      stockStatus
      status
      imageUrl
      variants
      vehicleId
      categoryId
      vehicleDetails {
        year
        make
        model
      }
      requests {
        id
        partSku
        year
        make
        model
        engine
        otherInformation
        imageUrl
        userId
        status
      }
      subCatDetails {
        subCatName
        categoryData {
          categoryName
        }
      }
    }
  }
`;

export const SEARCH_FOR_PARTS = gql`
  query searchForPart(
    $make: String!
    $year: String!
    $model: String!
    $engine: String!
    $categoryId: String
    $subCatId: Int
    $search: String
  ) {
    searchForPart(
      make: $make
      year: $year
      model: $model
      engine: $engine
      categoryId: $categoryId
      subCatId: $subCatId
      search: $search
    ) {
      id
      productSku
      partNumber
      partName
      partDesc
      mfgName
      sellerPrice
      resellerPrice
      qtyAvail
      stockStatus
      status
      imageUrl
      variants
      vehicleId
      categoryId
      subCatId
      subCatDetails {
        subCatName
        categoryData {
          categoryName
        }
      }
    }
  }
`;

export const GET_ONE_USER = gql`
  query getOneUser($id: Int!) {
    getOneUser(id: $id) {
      id
      username
      email
      phone
      passwordHash
      profilePicUrl
      roleId
      status
      usertypeData {
        id
        roleName
        isEditable
        status
      }
      garageOwner {
        id
        userId
        garageName
        garageAddress
        registrationNumber
        planId
        startDate
        endDate
      }
      agent {
        id
        userId
        agencyName
        agencyAddress
        licenseNumber
        planId
        startDate
        endDate
      }
      customer {
        id
        userId
        firstName
        lastName
        address
      }
      admin {
        id
        userId
        firstName
        lastName
        otherRoles
      }
    }
  }
`;

export const USER_TYPES_LIST = gql`
  query {
    usertypesList {
      id
      roleName
      isEditable
      status
    }
  }
`;

export const LIST_ALL_USER_TYPES = gql`
  query {
    listAllUsertypes {
      id
      roleName
      isEditable
      status
      users {
        id
        username
        email
        phone
        passwordHash
        profilePicUrl
        otp
        roleId
        status
      }
      rolePermissionData {
        id
        roleId
        permissionId
        permissionData {
          id
          moduleId
          functionId
          permissionName
          moduleData {
            moduleName
          }
          subFunctionData {
            functionName
          }
        }
      }
    }
  }
`;

export const PERMISSION_LIST = gql`
  query {
    permissionList {
      id
      moduleId
      functionId
      permissionName
      moduleData {
        id
        moduleName
        status
        subFunctions {
          id
          moduleId
          functionName
        }
      }
    }
  }
`;

export const PERMISSION_LIST_VIEW = gql`
  query {
    permissionListView {
      moduleId
      moduleName
      subFunctions {
        id
        functionName
      }
    }
  }
`;

export const UPDATE_ROLE_PERMISSION = gql`
  mutation updateRolePermission(
    $updateRolePermission: UpdateRolePermissionDto!
  ) {
    updateRolePermission(updateRolePermission: $updateRolePermission)
  }
`;

export const USER_TYPE_PERMISSIONS = gql`
  query usertypePermissions($usertype: Int!) {
    usertypePermissions(usertype: $usertype) {
      id
      roleId
      permissionId
      usertypeData {
        id
        roleName
        isEditable
        status
      }
      permissionData {
        id
        moduleId
        functionId
        permissionName
        moduleData {
          moduleName
        }
        subFunctionData {
          functionName
        }
      }
    }
  }
`;

export const SUBSCRIPTION_PLAN = gql`
  query {
    subscriptionPlansList {
      id
      planName
      description
      price
      duration
      status
    }
  }
`;

export const SUBSCRIPTION_PLAN_LIST = gql`
  query listSubscriptionPlansWithPagination(
    $page: Int!
    $limit: Int!
    $search: String
  ) {
    listSubscriptionPlansWithPagination(
      page: $page
      limit: $limit
      search: $search
    ) {
      items {
        id
        planName
        description
        price
        duration
        status
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const SUBSCRIPTION_PLAN_ADD = gql`
  mutation addSubscriptionPlan(
    $createSubscriptionPlan: CreateSubscriptionPlanDto!
  ) {
    addSubscriptionPlan(createSubscriptionPlan: $createSubscriptionPlan) {
      id
      planName
      description
      price
      duration
      status
    }
  }
`;
export const SUBSCRIPTION_PLAN_UPDATE = gql`
  mutation updateSubscriptionPlan(
    $subscriptionPlanId: Int!
    $updateSubscriptionPlan: UpdateSubscriptionPlanDto!
  ) {
    updateSubscriptionPlan(
      subscriptionPlanId: $subscriptionPlanId
      updateSubscriptionPlan: $updateSubscriptionPlan
    ) {
      id
      planName
      description
      price
      duration
      status
    }
  }
`;

export const SUBSCRIPTION_STATUS = gql`
  mutation subscriptionPlanStatusChange(
    $subscriptionPlanId: Int!
    $status: Int!
  ) {
    subscriptionPlanStatusChange(
      subscriptionPlanId: $subscriptionPlanId
      status: $status
    ) {
      id
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`;

export const PROFILE = gql`
  query {
    myProfile {
      id
      username
      email
      phone
      profilePicUrl
      roleId
      usertypeData {
        id
        roleName
        isEditable
        status
      }
      status
    }
  }
`;

export const LIST_USERS_TYPE = gql`
  query listUserTypesWithPagination(
    $page: Int!
    $limit: Int!
    $search: String
  ) {
    listUserTypesWithPagination(page: $page, limit: $limit, search: $search) {
      items {
        id
        roleName
        isEditable
        status
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const ADD_USER_TYPE = gql`
  mutation addUsertype($createUsertype: CreateUsertypeDto!) {
    addUsertype(createUsertype: $createUsertype) {
      id
      roleName
      isEditable
      status
    }
  }
`;

export const UPDATE_USER_TYPE = gql`
  mutation updateUsertype(
    $usertypeId: Int!
    $updateUsertype: UpdateUsertypeDto!
  ) {
    updateUsertype(usertypeId: $usertypeId, updateUsertype: $updateUsertype) {
      id
      roleName
      isEditable
      status
    }
  }
`;

export const USER_TYPE_STATUS = gql`
  mutation usertypeStatusChange($usertypeId: Int!, $status: Int!) {
    usertypeStatusChange(usertypeId: $usertypeId, status: $status) {
      id
      roleName
      isEditable
      status
    }
  }
`;

export const LIST_ORDER = gql`
  query listOrdersWithPagination(
    $page: Int!
    $limit: Int!
    $search: String
    $filter: OrderFilter
  ) {
    listOrdersWithPagination(
      page: $page
      limit: $limit
      search: $search
      filter: $filter
    ) {
      items {
        id
        userId
        addressId
        orderNo
        orderStatus
        paymentStatus
        totalAmount
        createdAt
        userData {
          id
          username
          email
          phone
        }
        addressData {
          id
          userId
          addressLine1
          addressLine2
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($orderId: Int!, $updateOrders: UpdateOrdersDto!) {
    updateOrder(orderId: $orderId, updateOrders: $updateOrders) {
      id
      userId
      addressId
      orderNo
      orderStatus
      paymentStatus
      totalAmount
    }
  }
`;

export const GET_ORDER = gql`
  query getOneOrder($orderId: Int!) {
    getOneOrder(orderId: $orderId) {
      id
      userId
      addressId
      orderNo
      orderStatus
      paymentStatus
      totalAmount
      userData {
        id
        username
        email
        phone
        customer {
          firstName
          lastName
        }
        garageOwner {
          garageName
        }
        agent {
          agencyName
        }
      }
      addressData {
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
      }
      orderItems {
        id
        price
        quantity
        partsData {
          partName
          partNumber
        }
      }
      paymentData {
        id
        orderId
        paymentDate
        paymentAmount
        paymentMethod
        paymentStatus
        invoice
      }
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query listTransactionsWithPagination(
    $page: Int!
    $limit: Int!
    $search: String
  ) {
    listTransactionsWithPagination(
      page: $page
      limit: $limit
      search: $search
    ) {
      items {
        id
        paymentId
        transactionDate
        transactionAmount
        transactionStatus
        paymentData {
          id
          orderId
          paymentDate
          paymentAmount
          paymentStatus
          paymentMethod
          invoice
          orderData {
            id
            userId
            addressId
            orderNo
            orderStatus
            paymentStatus
            totalAmount
            createdAt
            userData {
              id
              username
              email
              phone
              passwordHash
              profilePicUrl
              otp
              roleId
              status
            }
          }
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const LIST_VEHICLE = gql`
  query listVehicleWithPagination($page: Int!, $limit: Int!, $search: String) {
    listVehicleWithPagination(page: $page, limit: $limit, search: $search) {
      items {
        id
        year
        make
        model
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const LIST_ENGINE = gql`
  query listEngineWithPagination($page: Int!, $limit: Int!, $search: String) {
    listEngineWithPagination(page: $page, limit: $limit, search: $search) {
      items {
        id
        engineId
        vehicleId
        engineName
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;
export const LIST_SETTINGS = gql`
  query listAllSettings {
    listAllSettings {
      id
      tax
      shipping
      discount
      discountType
      adminEmail
      adminPhone
      address
      facebook
      whatsApp
      twitter
      linkedIn
    }
  }
`;

export const GET_ONE_SETTINGS = gql`
  query getOneSettings($settingsId: Int!) {
    getOneSettings(settingsId: $settingsId) {
      id
      tax
      shipping
      discount
      discountType
      adminEmail
      adminPhone
      address
      facebook
      whatsApp
      twitter
      linkedIn
    }
  }
`;
export const ADD_SETTINGS = gql`
  mutation addSettings($createSettings: CreateSettingsDto!) {
    addSettings(createSettings: $createSettings) {
      id
      tax
      shipping
      discount
      discountType
      adminEmail,
      adminPhone
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings(
    $settingsId: Int!
    $updateSettings: UpdateSettingsDto!
  ) {
    updateSettings(settingsId: $settingsId, updateSettings: $updateSettings) {
      id
      tax
      shipping
      discount
      discountType
    }
  }
`;
export const CATEGORY_LIST = gql`
  query listCategories {
    listCategories {
      categoryId
      categoryName
    }
  }
`;

export const LIST_CATEGORIES = gql`
  query listCategoriesWithPagination(
    $page: Int!
    $limit: Int!
    $search: String!
  ) {
    listCategoriesWithPagination(page: $page, limit: $limit, search: $search) {
      items {
        id
        categoryId
        vehicleId
        categoryName
        categoryImage
        vehicleData {
          model
          make
          year
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const VEHICLES_LIST = gql`
  query {
    vehiclesList {
      id
      year
      make
      model
    }
  }
`;

export const ADD_CATEGORIES = gql`
  mutation createCategory($createCategoryInput: CreateCategoryDto!) {
    createCategory(createCategoryInput: $createCategoryInput) {
      id
      categoryId
      vehicleId
      categoryName
      categoryImage
    }
  }
`;

export const UPDATE_CATEGORIES = gql`
  mutation updateCategory($id: Int!, $updateCategoryInput: UpdateCategoryDto!) {
    updateCategory(id: $id, updateCategoryInput: $updateCategoryInput) {
      id
      categoryId
      vehicleId
      categoryName
      categoryImage
    }
  }
`;

export const LIST_SUB_CATEGORIES = gql`
  query listSubCategoriesWithPagination(
    $page: Int!
    $limit: Int!
    $search: String!
    $filter: FilterSubCategoryDto!
  ) {
    listSubCategoriesWithPagination(
      page: $page
      limit: $limit
      search: $search
      filter: $filter
    ) {
      items {
        id
        subCatId
        categoryId
        subCatName
        subCatImage
        categoryData {
          categoryName
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;
export const LIST_CONTACT = gql`
  query listContactWithPagination($page: Int!, $limit: Int!, $search: String) {
    listContactWithPagination(page: $page, limit: $limit, search: $search) {
      items {
        id
        firstName
        lastName
        phoneNumber
        emailId
        message
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;
export const LIST_SUBCAT_OF_CAT = gql`
  query listSubCatByCat(
    $categoryId: String!
    $make: String
    $year: String
    $model: String
    $engine: String
  ) {
    listSubCatByCat(
      categoryId: $categoryId
      make: $make
      year: $year
      model: $model
      engine: $engine
    ) {
      id
      subCatId
      subCatName
    }
  }
`;

export const ADD_SUBCATEGORIES = gql`
  mutation createSubCategory($createSubCategoryInput: CreateSubCategoryDto!) {
    createSubCategory(createSubCategoryInput: $createSubCategoryInput) {
      id
      subCatId
      categoryId
      subCatName
      subCatImage
    }
  }
`;
export const UPDATE_SUBCATEGORIES = gql`
  mutation updateSubCategory(
    $id: Int!
    $updateSubCategoryInput: UpdateSubCategoryDto!
  ) {
    updateSubCategory(
      id: $id
      updateSubCategoryInput: $updateSubCategoryInput
    ) {
      id
      subCatId
      categoryId
      subCatName
      subCatImage
    }
  }
`;

export const LIST_PARTS = gql`
  query listPartsWithPagination(
    $page: Int!
    $limit: Int!
    $search: String!
    $filter: PartsFilter
  ) {
    listPartsWithPagination(
      page: $page
      limit: $limit
      search: $search
      filter: $filter
    ) {
      items {
        id
        productSku
        partNumber
        productCode
        partName
        partDesc
        mfgName
        sellerPrice
        resellerPrice
        qtyAvail
        stockStatus
        status
        imageUrl
        vehicleId
        categoryId
        subCatId
        vehicleDetails {
          make
        }
        subCatDetails {
          subCatId
        }
        categoryDetails {
          categoryName
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const MANUFACTURERS = gql`
  query {
    listManufacturers {
      mfgName
    }
  }
`;

export const ADD_PARTS = gql`
  mutation addParts(
    $createParts: CreatePartsDto!
    $fromEnquiry: String
    $enquiryId: Int
  ) {
    addParts(
      createParts: $createParts
      fromEnquiry: $fromEnquiry
      enquiryId: $enquiryId
    ) {
      id
      productSku
      partNumber
      partName
      partDesc
      mfgName
      sellerPrice
      resellerPrice
      qtyAvail
      stockStatus
      status
      imageUrl
      vehicleId
      categoryId
      subCatId
      vehicleDetails {
        id
        year
        make
        model
      }
      subCatDetails {
        id
        subCatId
        categoryId
        subCatName
        subCatImage
      }
    }
  }
`;

export const GET_ONE_PARTS = gql`
  query getOneParts($partsId: Int!) {
    getOneParts(partsId: $partsId) {
      id
      productSku
      partNumber
      partName
      partDesc
      mfgName
      sellerPrice
      resellerPrice
      qtyAvail
      stockStatus
      status
      imageUrl
      vehicleId
      categoryId
      variants
      subCatId
    }
  }
`;

export const UPDATE_PARTS = gql`
  mutation updateParts(
    $partsId: Int!
    $updateParts: UpdatePartsDto!
    $fromEnquiry: String
    $enquiryId: Int
  ) {
    updateParts(
      partsId: $partsId
      updateParts: $updateParts
      fromEnquiry: $fromEnquiry
      enquiryId: $enquiryId
    ) {
      id
      productSku
      partNumber
      partName
      partDesc
      mfgName
      sellerPrice
      resellerPrice
      qtyAvail
      stockStatus
      variants
      status
      imageUrl
      vehicleId
      categoryId
      subCatId
      vehicleDetails {
        id
        year
        make
        model
      }
      subCatDetails {
        id
        subCatId
        categoryId
        subCatName
        subCatImage
      }
    }
  }
`;

export const LIST_PART_REQUEST = gql`
  query listPartRequestWithPagination(
    $page: Int!
    $limit: Int!
    $search: String!
  ) {
    listPartRequestWithPagination(page: $page, limit: $limit, search: $search) {
      items {
        id
        partSku
        year
        make
        model
        engine
        otherInformation
        imageUrl
        userId
        status
        partData {
          id
          productSku
          partNumber
          partName
          partDesc
          mfgName
          sellerPrice
          resellerPrice
          qtyAvail
          variants
          stockStatus
          imageUrl
          status
        }
        userData {
          username
          partRequests {
            partData {
              partName
            }
          }
        }
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
        itemsPerPage
      }
    }
  }
`;

export const GET_ONE_PART_REQUEST = gql`
  query getOnePartRequest($partRequestId: Int!) {
    getOnePartRequest(partRequestId: $partRequestId) {
      id
      partSku
      year
      make
      model
      engine
      otherInformation
      imageUrl
      userId
      status
      userData {
        id
        username
        email
        phone
        passwordHash
        profilePicUrl
        otp
        roleId
        status
      }
      partData {
        id
        productSku
        partNumber
        productCode
        partName
        partDesc
        mfgName
        sellerPrice
        resellerPrice
        qtyAvail
        stockStatus
        status
        variants
        imageUrl
        vehicleId
        categoryId
        subCatId
      }
    }
  }
`;

export const USER = gql`
  query getOneUser($id: Int!) {
    getOneUser(id: $id) {
      id
      username
      email
      phone
      profilePicUrl
      roleId
      status
      usertypeData {
        id
        roleName
        isEditable
        status
      }
      garageOwner {
        id
        userId
        garageName
        garageAddress
        registrationNumber
        planId
        startDate
        endDate
        planData {
          planName
        }
      }
      agent {
        id
        userId
        agencyName
        agencyAddress
        licenseNumber
        planId
        startDate
        endDate
        planData {
          planName
        }
      }
      customer {
        id
        userId
        firstName
        lastName
        address
      }
      admin {
        id
        userId
        firstName
        lastName
        otherRoles
      }
      orderHistory {
        orderNo
        createdAt
        orderItems {
          partsData {
            partNumber
            partName
          }
          quantity
          price
        }
        orderStatus
        paymentData {
          paymentAmount
          paymentStatus
        }
      }
    }
  }
`;

export const BULK_UPLOAD_PARTS = gql`
  mutation bulkUploadParts($file:  Upload!) {
    bulkUploadParts(file: { file: $file })
  }
`;
