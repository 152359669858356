export const environment = {
  production: false,
  graphqlURL: 'https://smartrack-be.techlabz.in/graphql',
  apiURL: 'https://smartrack-be.techlabz.in',

  // graphqlURL: 'https://nivedhya-82.tw-workspace.techlabz.in/graphql',
  // graphqlURL: 'https://shamla-82.twl-workspace.techwarelab.com/graphql',
  // apiURL: 'https://nivedhya-82.tw-workspace.techlabz.in',

  firebaseConfig: {
    apiKey: 'AIzaSyCJPJT2z9UzJZ6eTpDSlk3XaF_skLHGrBM',
    authDomain: 'smarttrack-auto-spare-part.firebaseapp.com',
    projectId: 'smarttrack-auto-spare-part',
    storageBucket: 'smarttrack-auto-spare-part.appspot.com',
    messagingSenderId: '432181476120',
    appId: '1:432181476120:web:b5f180c86f3eb910b17c6d',
    measurementId: 'G-7WRV1ESVTE',
  },
};
