import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { debounceTime } from 'rxjs';
import { timeDelay } from '../helper/constant';
import { LIST_PART_REQUEST } from '../helper/queries';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { VALIDATION_MESSAGES } from '../helper/messages';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-enquiry',
  templateUrl: './product-enquiry.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class ProductEnquiryComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model

  search = new FormControl(''); //  table serch
  displayedColumns: string[] = [
    'username',
    'partName',
    'model',
    'imageUrl',
    'action',

  ]; // tables colums

  dataSource = new MatTableDataSource(); // table source

  createForm!: FormGroup; // user create
  createSubmit: boolean | undefined;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  imagePath!: string;
  imagePopupData: any;

  isEditMode: boolean = false;
  isImageExists: boolean=false
  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: [''],
      roleName: ['', [Validators.required]],
      isEditable: ['', [Validators.required]],
    });

    this.listPartRequest();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listPartRequest(searchTerm);
      });
      this.imagePath = "../../assets/images/image-icon-black.svg"
  }

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  listPartRequest(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_PART_REQUEST,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.dataSource = data?.listPartRequestWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            partName: resp?.partData?.partName,
            username:resp?.userData?.username,
            model: resp?.model,
            imageUrl: resp?.imageUrl,
            imageforpopup: resp?.imageUrl,
          })
        );
        this.totalItems =
          data?.listPartRequestWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listPartRequestWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listPartRequestWithPagination?.pageInfo?.currentPage;
      });
  }
  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listPartRequest(this.search.value);
  }

  navigateToProductEnquiryView(prodId: string): void {
    if (prodId) {
      this.router.navigate(['/product-enquiry', prodId]);
    } else {
      this.toastr.error(VALIDATION_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }

  popupDataImg(img: string): void {
    this.imagePopupData = img?.split(';')[0] 
  }
}
