import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GET_ONE_PART, SEARCH_FOR_PARTS } from '../helper/queries';
import {MatRadioModule} from '@angular/material/radio';
import { debounceTime } from 'rxjs';
import { STATUS, STOCK } from '../helper/constant';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, Location } from '@angular/common';



@Component({
  selector: 'app-inventory-enguiry-view',
  templateUrl: './inventory-enguiry-view.component.html',
  styleUrls: ['inventory-enguiry-view.component.scss'],
  standalone: true,
  imports: [MatRadioModule, ReactiveFormsModule, CommonModule],
})
export class InventoryEnquiryViewComponent {
  inventoryEnguiryData: any;

  productImg: string[] = [];
  vehicleDetails: any;
  searchData: any;
  searchResult : any;
  isVarient: boolean = false;
  search = new FormControl('');
  partImage: any = null;
  status = STATUS;
  stock = STOCK;

  currentIndex: number = 0;
  selectedPartId: number | null = null;
  constructor(
    private route: ActivatedRoute,
    private readonly apollo: Apollo,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const partsId = params['id'];
      this.inventoryEnquiry(parseInt(partsId));
    });
  }
  
  // Method to move to the previous image
  prevImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.productImg.length - 1; // Loop back to the last image
    }
  }

  // Method to move to the next image
  nextImage() {
    if (this.currentIndex < this.productImg.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0; // Loop back to the first image
    }
  }

  // Compute the transform for the slider
  getSliderTransform() {
    return `translateX(-${this.currentIndex * 100}%)`;
  }

  back(): void {
    this.location.back()
  }





  inventoryEnquiry(id: number) {
    this.apollo
      .watchQuery({
        query: GET_ONE_PART,
        variables: {
          partsId: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.inventoryEnguiryData = data?.getOneParts;
        console.log(this.inventoryEnguiryData);
        
        if (data?.getOneParts?.imageUrl) {
          this.productImg = data?.getOneParts?.imageUrl.split(';');
        }
        
        console.log(this.productImg);
        
      });
  }
  navigateBack() {
    this.router.navigate(['/inventory']);
  }

  searchForParts(searchValue: string) {
    const searchData = searchValue;
    
    this.apollo
    .watchQuery({
      query:  SEARCH_FOR_PARTS,
      variables: {
        make:this.inventoryEnguiryData?.vehicleDetails?.make,
        year: this.inventoryEnguiryData?.vehicleDetails?.year,
        model: this.inventoryEnguiryData?.vehicleDetails?.model,
        engine: this.inventoryEnguiryData?.requests?.engine ? this.inventoryEnguiryData?.requests?.engine : '',
        categoryId: null,
        subCatId: null,
        search: searchData ? searchData : null
      },
      fetchPolicy: 'no-cache',
    })
    .valueChanges.subscribe(({ data }: any ) => {
      this.searchResult = data?.searchForPart;
      console.log(this.searchResult);
      this.isVarient = this.searchResult.some((part: any) => part.variants && parseInt(part.variants) > 0);
    })

  }
}
