import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { DatasheetComponent } from './datasheet/datasheet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { MatSelectModule } from '@angular/material/select';
import { UsersComponent } from './users/users.component';
import { MatMenuModule } from '@angular/material/menu';
import { SettingComponent } from './setting/setting.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ToastrModule } from 'ngx-toastr';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { GraphqlService } from './service/graphql.service';
import { UserTypeComponent } from './user-type/user-type.component';
import { AngularFireModule } from '@angular/fire/compat';
import { OrderManagementComponent } from './order-management/order-management.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaymentTransactionComponent } from './payment-transaction/payment-transaction.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { EngineComponent } from './engine/engine.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
} from 'ngx-ui-loader';
import { InventoryComponent } from './inventory/inventory.component';
import { ProductEnquiryComponent } from './product-enquiry/product-enquiry.component';
import { ModulesAndPermissionsComponent } from './modules-and-permissions/modules-and-permissions.component';
import { ProductEnguiryViewComponent } from './product-enguiry-view/product-enguiry-view.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomersComponent } from './customers/customers.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
//StoreModule
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { permissionReducer } from './reducers/permissions.reducer';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TruncatePipe } from './truncate.pipe';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#c3252a',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#c3252a',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-spin-clockwise',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#c3252a',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    PagelayoutComponent,
    DatasheetComponent,
    ProductdetailsComponent,
    UsersComponent,
    SettingComponent,
    ForgotComponent,
    ChangePasswordComponent,
    SubscriptionPlansComponent,
    UserTypeComponent,
    OrderManagementComponent,
    PaymentTransactionComponent,
    OrderDetailsComponent,
    VehicleComponent,
    EngineComponent,
    CategoryComponent,
    SubCategoryComponent,
    PagenotfoundComponent,
    InventoryComponent,
    ProductEnquiryComponent,
    ModulesAndPermissionsComponent,
    ProductEnguiryViewComponent,
    CustomersComponent,
    UserDetailsComponent,
    DashboardComponent,
    ConfirmDeleteDialogComponent,
    AdminSettingsComponent,
    ContactUsComponent,
    TruncatePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ApolloModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MatPaginatorModule,

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),

    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    NgSelectModule,

    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,

    
    StoreModule.forRoot({ permissions: permissionReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([])
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, graphQLConfigService: GraphqlService) =>
        graphQLConfigService.createApollo(),
      deps: [HttpLink, GraphqlService],
     },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
