<section class="addDatSheetCommonSec">

    <!-- <div class="serachMainAllWrapper">
        <div class="searchDiv searchDivFull">
            <form class="example-form">
                <input type="text" placeholder="Search for contact..." [formControl]="search" class="example-input">
            </form>
        </div>

    </div> -->
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                <!-- Position Column -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>
                        First Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.firstName || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>
                        Last Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.lastName || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>
                       Email
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.email || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        Phone Number
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.phoneNumber || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="message">
                    <th mat-header-cell *matHeaderCellDef>
                        Message
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.message || "___"}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItems">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
    </mat-paginator>
</section>