import { FormGroup } from "@angular/forms";

export function removeProperty<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  propToRemove: K
): Omit<T, K> {
  const { [propToRemove]: _, ...rest } = obj;
  return rest;
}


export function removeNullProperties(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null));
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors["mustMatch"]) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}