<div class="no-data-found">
    <img src="../../assets/images/no-data-found.png" alt="noData">
</div>
<div class="page-not-wrapper container">
    <h1>404</h1>
    <p>Page Not Found</p>
    <p>The page you are looking for doesn't exist or an error occurred.</p>
    <button routerLink="/">
        Back to home
    </button>
</div>