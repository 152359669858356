import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import {
  ADD_USER,
  GET_ONE_USER,
  LIST_USERS,
  SUBSCRIPTION_PLAN,
  UPDATE_USER,
  USER_STATUS,
  USER_TYPES_LIST,
} from '../helper/queries';
import { debounceTime } from 'rxjs/operators';
import { STATUS, timeDelay } from '../helper/constant';
import { removeNullProperties, removeProperty } from '../helper/helper';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { MESSAGES, VALIDATION_MESSAGES } from '../helper/messages';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model

  search = new FormControl(''); // user table serch
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'mail',
    'phone',
    'role',
    'status',
    'action',
  ]; // user's tables colums

  dataSource = new MatTableDataSource(); // user's table source

  createUserForm!: FormGroup; // user create
  createUserFormSubmit: boolean | undefined;

  usertypes: any | undefined; // usertype list
  subscriptionPlans: any | undefined; // subscriptionPlans list

  selectedUserType: number | undefined;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  isEditMode: boolean = false;

  readonly ACTIVE = STATUS.ACTIVE;
  readonly INACTIVE = STATUS.INACTIVE;
  readonly GARAGE = 'garageOwner';
  readonly AGENT = 'agent';
  readonly CUSTOMER = 'customer';
  readonly CUSTOMER_ID = 4;
  readonly GARAGE_OWNER_ID = 3;
  readonly AGENT_ID = 2;
  readonly ADMIN_ID  = 1;
  

  get userType() {
    if (this.usertypes) {
      const id = this.createUserForm.get('userType')?.value;
      const userTypeObject = this.usertypes?.find((_a: any) => _a?.id == id);
      return userTypeObject?.roleName;
    }
  }

  closeModel(): void {
    this.createUserForm.reset();
    this.closeModal.nativeElement.click();
  }

  openModel(): void {
    this.createUserForm.reset();
    this.openModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createUserForm.reset();
  }

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.createUserForm = this.formBuilder.group({
      id: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required,  Validators.pattern("^[0-9]{10}$")]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      userType: ['', [Validators.required]],
      plan: [''],
      address: [''],
      registrationNumber: [''],
      licenseNumber: [''],
    });

    // this.checkPasswordValidation();
    this.checkAddressValidation();
    this.checkPlanValidation();
    this.checkRegistrationNumberValidation();
    this.checkLicenseNumberValidation();

    this.fetchUsers();
    this.usertypesList();
    this.subscriptionPlansList();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.fetchUsers(searchTerm, this.selectedUserType);
      });
  }

  checkPasswordValidation() {
    const idControl = this.createUserForm.get('id');
    const passwordControl = this.createUserForm.get('password');

    if (idControl && passwordControl) {
      idControl?.valueChanges.subscribe((userType) => {
        if (userType) {
          passwordControl.clearValidators();
        } else {
          passwordControl.setValidators([Validators.required]);
        }
        passwordControl.updateValueAndValidity();
      });
    }
  }

  checkAddressValidation() {
    const userTypeControl = this.createUserForm.get('userType');
    const addressControl = this.createUserForm.get('address');
    if (userTypeControl && addressControl) {
      userTypeControl?.valueChanges.subscribe(() => {
        if (
          this.userType === this.CUSTOMER ||
          this.userType === this.AGENT ||
          this.userType === this.GARAGE
        ) {
          addressControl.setValidators([Validators.required]);
        } else {
          addressControl.clearValidators();
        }
        addressControl.updateValueAndValidity();
      });
    }
  }

  checkPlanValidation() {
    const userTypeControl = this.createUserForm.get('userType');
    const planControl = this.createUserForm.get('plan');
    if (userTypeControl && planControl) {
      userTypeControl?.valueChanges.subscribe(() => {
        if (this.userType === this.AGENT || this.userType === this.GARAGE) {
          planControl.setValidators([Validators.required]);
        } else {
          planControl.clearValidators();
        }
        planControl.updateValueAndValidity();
      });
    }
  }

  checkRegistrationNumberValidation() {
    const userTypeControl = this.createUserForm.get('userType');
    const registrationControl = this.createUserForm.get('registrationNumber');
    if (userTypeControl && registrationControl) {
      userTypeControl?.valueChanges.subscribe(() => {
        if (this.userType === this.GARAGE) {
          registrationControl.setValidators([Validators.required]);
        } else {
          registrationControl.clearValidators();
        }
        registrationControl.updateValueAndValidity();
      });
    }
  }

  checkLicenseNumberValidation() {
    const userTypeControl = this.createUserForm.get('userType');
    const licenseControl = this.createUserForm.get('licenseNumber');
    if (userTypeControl && licenseControl) {
      userTypeControl?.valueChanges.subscribe(() => {
        if (this.userType === this.AGENT) {
          licenseControl.setValidators([Validators.required]);
        } else {
          licenseControl.clearValidators();
        }
        licenseControl.updateValueAndValidity();
      });
    }
  }

  onSelectionUserType(event: MatSelectChange) {
    this.selectedUserType = event.value;
    this.fetchUsers(this.search.value, this.selectedUserType);
  }

  fetchUsers(searchTerm: string | null = '', usertype: number | null = null) {
    this.apollo
      .watchQuery({
        query: LIST_USERS,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
          filter: {
            usertype: usertype,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listUsersWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            firstName: resp?.username?.split(' ')[0],
            lastName: resp?.username?.split(' ')[1],
            mail: resp?.email,
            phone: resp?.phone,
            role: resp?.usertypeData?.roleName,
            status: parseInt(resp?.status),
          })
        );

        this.totalItems = data?.listUsersWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listUsersWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage = data?.listUsersWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.fetchUsers(this.search.value, this.selectedUserType);
  }

  usertypesList(): void {
    this.apollo
      .watchQuery({
        query: USER_TYPES_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.usertypes = data?.usertypesList?.filter(
          (usertype: any) => usertype?.id !== this.GARAGE_OWNER_ID && usertype?.id !== this.CUSTOMER_ID && usertype?.id !== this.AGENT_ID
        )
      });
  }
  

  subscriptionPlansList(): void {
    this.apollo
      .watchQuery({
        query: SUBSCRIPTION_PLAN,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.subscriptionPlans = data?.subscriptionPlansList;
      });
  }

  subscriptionPlansDay(id: number): any {
    if (this.subscriptionPlans && id) {
      const findOne = this.subscriptionPlans?.find((_a: any) => _a?.id === id);
      const duration = findOne?.duration;

      const currentDate = new Date();
      const startDate = currentDate.toISOString().split('T')[0]; // Format it as YYYY-MM-DD

      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + duration);
      const formattedEndDate = endDate.toISOString().split('T')[0];

      return {
        startDate: startDate,
        endDate: formattedEndDate,
      };
    }
  }

  openDeleteDialog(id: number, type: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.userStatus(id, type);
      }
    });
  }

  userStatus(id: number, type: number): void {
    this.apollo
      .mutate({
        mutation: USER_STATUS,
        variables: {
          usersId: id,
          status: type,
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: () => {
          this.fetchUsers();
          if(type === 2){
            this.toastr.success(MESSAGES.DATA_DELETED);
          }else{
            this.toastr.success(MESSAGES.SATUS_CHANGE_SUCCESS);
          }
         
        },
        error: (errors) => {
          this.toastr.error(MESSAGES.SATUS_CHANGE_FAILED);
        }
      });
  }

  userUpdate(id: number): void {
    if (id) {
      this.apollo
        .watchQuery({
          query: GET_ONE_USER,
          variables: {
            id: id,
          },
          fetchPolicy: 'no-cache',
        })
        .valueChanges.subscribe(({ data, loading }: any) => {
          const user = data?.getOneUser;
          const [firstName, lastName] = user?.username?.split(' ');
          let newData = {
            id: user?.id,
            firstName: firstName,
            lastName: lastName,
            email: user?.email,
            phone: user?.phone,
            userType: user?.roleId,
          };

          if (user?.usertypeData?.roleName === this.GARAGE) {
            const garage = {
              plan: user?.garageOwner?.planId,
              address: user?.garageOwner?.garageAddress,
              registrationNumber: user?.garageOwner?.registrationNumber,
            };
            newData = {
              ...newData,
              ...garage,
            };
          }

          if (user?.usertypeData?.roleName === this.AGENT) {
            const agent = {
              plan: user?.agent?.planId,
              address: user?.agent?.agencyAddress,
              registrationNumber: user?.agent?.licenseNumber,
            };
            newData = {
              ...newData,
              ...agent,
            };
          }

          if (user?.usertypeData?.roleName === this.CUSTOMER) {
            const customer = {
              address: user?.customer?.address,
            };
            newData = {
              ...newData,
              ...customer,
            };
          }
          this.openModel();
          this.isEditMode = true;
          this.createUserForm.patchValue(newData);
        });
    }
  }

  async createUser(): Promise<void> {
    if (this.createUserForm.valid) {
      const value = this.createUserForm.value;
      let createUserData;
      let expiryDate;
      // Email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(value.email)) {
        this.toastr.error('Please enter a valid email address');
        return;
      }
      // Check if the phone number is valid (contains only numbers)
      const phoneNumberPattern = /^[0-9]+$/;
      if (!phoneNumberPattern.test(value.phone)) {
        this.toastr.error('Phone number must contain only numbers.');
        return; // Stop the process if the phone number is invalid
      }

      if (value?.plan) {
        expiryDate = await this.subscriptionPlansDay(value?.plan);
      }

      switch (this.userType) {
        case this.GARAGE:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
            planId: value?.plan,
            startDate: expiryDate?.startDate,
            endDate: expiryDate?.endDate,
            garageAddress: value?.address,
            registrationNumber: value?.registrationNumber,
          };
          break;

        case this.AGENT:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
            planId: value?.plan,
            startDate: expiryDate?.startDate,
            endDate: expiryDate?.endDate,
            agencyAddress: value?.address,
            licenseNumber: value?.licenseNumber,
          };
          break;

        case this.CUSTOMER:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
            customerAddress: value?.address,
          };
          break;

        default:
          createUserData = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            password: value?.password,
            roleId: value?.userType,
            phone: value?.phone,
          };
          break;
      }
      try {
        if (value?.id) {
          await this.apollo
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                id: value?.id,
                updateUsers: removeNullProperties(createUserData),
              },
              fetchPolicy: 'no-cache',
            })
            .toPromise();

          this.fetchUsers();
          this.toastr.success(MESSAGES.PART_UPDATED);
          this.closeModel();
        } else {
          await this.apollo
            .mutate({
              mutation: ADD_USER,
              variables: {
                createUserData: createUserData,
              },
              fetchPolicy: 'no-cache',
            })
            .toPromise();

          this.fetchUsers();
          this.toastr.success(MESSAGES.NEW_PART_ADDED);
          this.closeModel();
        }
      } catch (error: any) {
        this.toastr.error(error.message);
      }
    }
  }
  

  navigateToUserDetails(userId: string): void {
    if (userId) {
      this.router.navigate(['/users', userId]);
    } else {
      this.toastr.error(VALIDATION_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
}
