<section class="addDatSheetCommonSec">
  
    <div class="divBgWhiteSet">
        <div class="row rowSpaceMain">
            <div class="col-sm-12 spaceMainall">
        <div class="divBgWhiteSetSearch">
    <div class="searchWrapperMain">
        <div class="searchWrapperPos">
<img src="../../assets/images/search-icon1.png">
            </div>
            <input type="text" placeholder="Enter the Product code below">
    </div>
    <button class="addNewBtn">ADD NEW DATASHEET</button>
    </div>
    </div>
    </div>
</div>
<div class="divBgWhiteSet">
    <div class="row rowSpaceMain">
    <div class="col-sm-12 spaceMainall">
    <div class="headerFleXmain">
    <label>Product Specification</label>
    <button class="addNewBtn">Generate Datasheet</button>
</div>
</div>
<div class="col-sm-12 spaceMainall">
    <div class="formFieldMainAll">
<span>Name</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Product Name" >
  </mat-form-field>
    </div>
</div>

<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Brand</span>
<mat-form-field>
    <mat-select [(value)]="selected">
      <mat-option value="">Select</mat-option>
      <mat-option value="one">First option</mat-option>
      <mat-option value="two">Second option</mat-option>
    </mat-select>
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Family Name</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Family Name" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Project Name</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Project Name" >
  </mat-form-field>
    </div>
</div>

<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Category</span>
<mat-form-field>
    <mat-select [(value)]="selected">
      <mat-option value="">Select</mat-option>
      <mat-option value="one">Category</mat-option>
      <mat-option value="two">Second option</mat-option>
    </mat-select>
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
        <span>Sub Category</span>
        <mat-form-field>
            <mat-select [(value)]="selected">
              <mat-option value="">Select</mat-option>
              <mat-option value="one">Sub Category</mat-option>
              <mat-option value="two">Second option</mat-option>
            </mat-select>
          </mat-form-field>
            </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Type Number</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Type Number" >
  </mat-form-field>
    </div>
</div>

<div class="col-sm-6 spaceMainall">
    <div class="formFieldMainAll">
<span>Description</span>
<mat-form-field class="example-full-width">

    <textarea placeholder="Product Description"  matInput></textarea>
  </mat-form-field>
    </div>
</div>

<div class="col-sm-6 spaceMainall">
    <div class="formFieldMainAll">
<span>Key Features</span>
<mat-form-field class="example-full-width">
    <textarea placeholder="Key Features"  matInput></textarea>
  </mat-form-field>
    </div>
</div>



<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Source Power </span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Source Wattage" >
  </mat-form-field>
    </div>
</div>

<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>System </span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="System Wattage " >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Material</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Material" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Source Lumen</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Source Lumen" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>System Lumen</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="System Lumen" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Control Gear</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Control Gear" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Fitting Color</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Fitting Color" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Perfection Rating</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Perfection Rating" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Impact Rating</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Impact Rating" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Efficacy</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Efficacy" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Tilt</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Sub Category" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>COI Compatibility</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="COI Compatibility" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Supply Voltage</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Supply Voltage" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Power Connection</span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Power Connection" >
  </mat-form-field>
    </div>
</div>
<div class="col-sm-4 spaceMainall">
    <div class="formFieldMainAll">
<span>Class </span>
<mat-form-field class="example-full-width">
    <input matInput placeholder="Class " >
  </mat-form-field>
    </div>
</div>

<div class="col-sm-12 spaceMainall">
    <ul class="fileUploadDivWrapper">
        <li>    <div class="formFieldMainAll">
            <span>Image </span>
            <div class="browerUploadDiv">
                <div class="browerUploadDivBorder">
                <img alt="" src="../../assets/images/download-icon.png">
            <h4>Drag and drop image</h4>
            <h4>Or</h4>
            <button>Browse File</button>
            </div>
            </div>
                </div></li>
                <li>    <div class="formFieldMainAll">
                    <span>Photometric Image </span>
                    <div class="browerUploadDiv">
                        <div class="browerUploadDivBorder">
                        <img alt="" src="../../assets/images/download-icon.png">
                    <h4>Drag and drop image</h4>
                    <h4>Or</h4>
                    <button>Browse File</button>
                    </div>
                    </div>
                        </div></li>
                        <li>    <div class="formFieldMainAll">
                            <span>Measurement Diagram </span>
                            <div class="browerUploadDiv">
                                <div class="browerUploadDivBorder">
                                <img alt="" src="../../assets/images/download-icon.png">
                            <h4>Drag and drop image</h4>
                            <h4>Or</h4>
                            <button>Browse File</button>
                            </div>
                            </div>
                                </div></li>
    </ul>

</div>

<div class="col-sm-12spaceMainall">
    <div class="formFieldMainAll">
        <span>Symbols</span>
        <mat-form-field>
            <mat-select [(value)]="selected">
              <mat-option value="">Select</mat-option>
              <mat-option value="one">Symbols</mat-option>
              <mat-option value="two">Second option</mat-option>
            </mat-select>
          </mat-form-field>
            </div>
</div>

<div class="col-sm-12 spaceMainall">
    <div class="formFieldMainAll">
        <span>Accessories</span>
        <div class="addUploadWrapper">
        <ul class="fileUploadDivWrapper">
            <li>  
              
                <div class="browerUploadDiv">
                    <div class="browerUploadDivBorder">
                    <img alt="" src="../../assets/images/download-icon.png">
                <h4>Drag and drop image</h4>
                <h4>Or</h4>
                <button>Browse File</button>
              
                </div>
                    </div></li>
                    <li>    
                      <a href="#">
                        <div class="addPluseBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="95" viewBox="0 0 47 95">
                                <text data-name="+" transform="translate(0 71)" style="fill:#cecece;font-size:68px;font-family:Poppins-Regular,Poppins"><tspan x="0" y="0">+</tspan></text>
                            </svg>
                        </div>
                    </a>
                           </li>
                        
        </ul>
    </div>
            </div>
</div>
<div class="col-sm-12 spaceMainall">
    <div class="bottomBtnmain">
        <button class="saveBtn">Save</button>
        <button class="cancelbtn">Cancel</button>
    </div>
</div>
</div>
</div>


    </section>

