import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';
import { createUploadLink } from 'apollo-upload-client';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// @Injectable({
//   providedIn: 'root',
// })
// export class GraphqlService {
//   constructor(private httpLink: HttpLink) {}

//   createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
//     const authLink = setContext((operation, context) => {
//       const token = localStorage.getItem('token');

//       return {
//         headers: {
//           ...context['headers'],
//           authorization: token ? token : '',
//           'Access-Control-Allow-Origin': '*',
//           'Cross-Origin-Embedder-Policy': 'require-corp',
//           'Cross-Origin-Opener-Policy': 'same-origin',
//         },
//       };
//     });

//     const link = ApolloLink.from([
//       authLink,
//       httpLink.create({ uri: environment.graphqlURL }),
//     ]);

//     return {
//       link,
//       cache: new InMemoryCache(),
//     };
//   }
// }


@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private httpLink: HttpLink,
    private ngxLoader: NgxUiLoaderService
  ) {}

  createApollo(): ApolloClientOptions<any> {
    const authLink = setContext((operation, context) => {
      const token = localStorage.getItem('token');

      return {
        headers: {
          ...context['headers'],
          authorization: token ? token : '',
          'Access-Control-Allow-Origin': '*',
          'Cross-Origin-Embedder-Policy': 'require-corp',
          'Cross-Origin-Opener-Policy': 'same-origin',
        },
      };
    });

    const uploadLink = createUploadLink({
      uri: environment.graphqlURL,
    });

    // const link = ApolloLink.from([authLink, uploadLink]);
    const link = ApolloLink.from([
      authLink,
      new ApolloLink((operation, forward) => {
        // Show loader before the request
        this.ngxLoader.start();

        return forward(operation).map((response) => {
          // Hide loader after the response
          this.ngxLoader.stop();
          return response;
        });
      }),
      uploadLink,
    ]);

    return {
      link,
      cache: new InMemoryCache(),
    };
  }
}
