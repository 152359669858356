<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper btnSpace">
        <button type="button" class="backBtn" (click)="navigateBack()">
           < Back
        </button>
        <!-- <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for order details..." [formControl]="search"
                    class="example-input">
            </form>
        </div> -->
        <button  class="invoiceBtn" [disabled]="!isPaymentInvoiceValid()"  type="button" (click)="openInvoice()">
            INVOICE
        </button>
    </div>
    <div class="order-details-wrapper">
        <div class="top-box">

            <div class="right-set">
                <div class="summary-wrapper">
                    <div class="head">
                        <h2>Order Summary</h2>
                    </div>
                    <div class="content">
                        <div class="value-box">
                            <h3>Order no :</h3>
                            <h4>{{orderNo || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Purchased By :</h3>
                            <h4>{{purchasedBy || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Shipping address :</h3>
                            <h4>{{shippingAddress?.addressLine1}}</h4>
                        </div>
                        <div class="value-box">
                            <h3></h3>
                            <h4>{{shippingAddress?.addressLine2}}</h4>
                        </div>
                        <div class="value-box">
                            <h3></h3>
                            <h4>
                                {{shippingAddress?.city }}
                                {{shippingAddress?.state }}
                                {{shippingAddress?.country }}
                                {{shippingAddress?.postalCode }}
                            </h4>
                        </div>
                        <div class="value-box">
                            <h3>Order Status :</h3>
                            <select (change)="onOrderStatusChange($event)" [value]="orderStatus">
                              <option *ngFor="let status of orderStatusOptions" [value]="status">{{ status  | titlecase  }}</option>
                            </select>
                          </div>
                    </div>
                </div>
            </div>
            <div class="right-set">
                <div class="summary-wrapper">
                    <div class="head">
                        <h2>Payment Summary</h2>
                    </div>
                    <div class="content">
                        <div class="value-box">
                            <h3>Payment Date :</h3>
                            <h4>{{paymentDate || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Payment Amount :</h3>
                            <h4>{{paymentAmount || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Payment Status :</h3>
                            <h4>{{paymentStatus || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Payment Method :</h3>
                            <h4>{{paymentMethod || "___"}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-set">
            <div class="dataSheetTable">
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                        <!-- Position Column -->
                        <ng-container matColumnDef="partNumber">
                            <th mat-header-cell *matHeaderCellDef>
                                Part Number
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.partNumber || "___"}} </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="partName">
                            <th mat-header-cell *matHeaderCellDef>
                                Part Name
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.partName || "___"}} </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef>
                                Quantity
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.quantity || "___"}} </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef>
                                Price
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.price || "___"}} </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

</section>