<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for plans..." [formControl]="search" class="example-input">
            </form>
        </div>
        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()" #openModal>ADD
            PLAN</button>
    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">


                <ng-container matColumnDef="planName">
                    <th mat-header-cell *matHeaderCellDef>
                        Plan
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.planName || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>
                        Description
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.description || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>
                        Price
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.price || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef>
                        Duration
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.duration || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.status === 1 ? ACTIVE : INACTIVE}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dropMenuMain">
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="updatePlan(element)">Edit</button>
                                <button mat-menu-item (click)="openDeleteDialog(element?.id , 2)">Delete</button>
                                <button mat-menu-item (click)="updateStatus(element?.id , 1)"
                                    *ngIf="element?.status === 0">{{ STATUS_BUTTONS.ACTIVATE }}</button>
                                <button mat-menu-item (click)="updateStatus(element?.id , 0)"
                                    *ngIf="element?.status === 1">{{STATUS_BUTTONS.DEACTIVATE}}</button>
                            </mat-menu>
                            <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                                    fill="black" />
                                <path
                                    d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                                    fill="black" />
                                <path
                                    d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                                    fill="black" />
                            </svg>


                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <div class="no-data-found" *ngIf="!totalItems">
                <img src="../../assets/images/no-data-found.png" alt="noData">
            </div>
        </div>

        <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
        </mat-paginator>



    </div>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="createForm" (ngSubmit)="createPlan()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>{{ isEditMode ? 'EDIT PLANS' : 'ADD PLANS' }}</h2>
                                <div class="formFieldMainAll">
                                    <span>Plan Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Plan Name" formControlName="planName">
                                    </mat-form-field>
                                    <div class="s_error" *ngIf="
                                    !createForm.controls['planName'].valid &&
                                    (createForm.controls['planName'].dirty ||
                                    createForm.controls['planName'].touched ||
                                    createFormSubmit)
                                    ">
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['planName'].hasError('required')">
                                            Plan Name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Description</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Description" formControlName="description">
                                    </mat-form-field>
                                    <div class="s_error" *ngIf="
                                    !createForm.controls['description'].valid &&
                                    (createForm.controls['description'].dirty ||
                                    createForm.controls['description'].touched ||
                                    createFormSubmit)
                                    ">
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['description'].hasError('required')">
                                            Description is required
                                        </div>
                                    </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Duration </span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Duration in days" formControlName="duration">
                                    </mat-form-field>
                                    <div class="s_error" *ngIf="
                                    !createForm.controls['duration'].valid &&
                                    (createForm.controls['duration'].dirty ||
                                    createForm.controls['duration'].touched ||
                                    createFormSubmit)
                                    ">
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['duration'].hasError('required')">
                                            Duration is required
                                        </div>
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['duration'].hasError('pattern')">
                                            Duration must be a number with up to 4 digits
                                        </div>
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['duration'].hasError('max')">
                                            Duration cannot be more than 9999
                                        </div>
                                    </div>

                                </div>
                                <div class="formFieldMainAll">
                                    <span>Price</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Price" formControlName="price">
                                    </mat-form-field>

                                    <div class="s_error" *ngIf="
                                    !createForm.controls['price'].valid &&
                                    (createForm.controls['price'].dirty ||
                                    createForm.controls['price'].touched ||
                                    createFormSubmit)
                                    ">
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['price'].hasError('required')">
                                            Price is required
                                        </div>
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['price'].hasError('pattern')">
                                            Price must be a number
                                        </div>
                                        <div class="s_validation" *ngIf="createForm.controls['price'].hasError('min')">
                                            Price cannot be less than 0
                                        </div>
                                    </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend" [disabled]="createForm.invalid">
                                        SUBMIT</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>