<h1 mat-dialog-title class="dialog-header">
    <button type="button" class="close-icon btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCancel()">
        <img alt="" src="../../assets/images/close-icon1.png">
    </button>
</h1>
<div mat-dialog-content class="dialog-content">
    <h2>Are you sure?</h2>
    <p>Do you really want to delete these records?</p>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button class="cancel-button" (click)="onCancel()">Cancel</button>
    <button class="delete-button" (click)="onConfirm()">Delete</button>
</div>