import { Component } from '@angular/core';
import { AuthServiceService } from '../service/auth/auth-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MESSAGES, VALIDATION_MESSAGES } from '../helper/messages';
import { redirectToURL } from '../helper/constant';

interface password {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class ChangePasswordComponent {
  loginForm!: FormGroup;
  loginSubmit: boolean | undefined;

  users: password[] | undefined;
  errorMessages = VALIDATION_MESSAGES;

  password: string | undefined;
  showPassword: boolean = false;
  pswEye: string = '../../assets/images/password-eye-close.png';
  showPasswordConfirm: boolean = false;
  pswEyeConfirm: string = '../../assets/images/password-eye-close.png';

  token: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private loginService: AuthServiceService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.autoLogin();
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
    });
  }

  autoLogin(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate([redirectToURL]);
    }
  }

  clearForm(): void {
    this.loginForm.reset();
  }

  resetLogin() {
    if (this.loginForm.valid) {
      const value = this.loginForm.value;
      const userPassword: password = {
        password: value?.password,
        confirmPassword: value?.password,
      };
      if (value?.password !== value?.confirmPassword) {
        this.toastr.error(MESSAGES.PASSWORD_MISMATCH);
        return;
      }
      this.loginService?.resetPassword(userPassword, this.token).subscribe(
        (response) => {
          if (response) {
            this.toastr.success(MESSAGES.PASSWORD_CHANGE_SUCCESSFUL);
            this.clearForm();
            this.router.navigate(['/login']);
          } else {
            this.toastr.error(MESSAGES.PASSWORD_CHANGE_FAILED);
          }
        },
        (error) => {
          this.toastr.error(error?.error?.message);
          console.error(error);
        }
      );
    }
  }

  togglePasswordVisibility(type: string) {
    if (type === 'new') {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.pswEye = '../../assets/images/password-eye.png';
      } else {
        this.pswEye = '../../assets/images/password-eye-close.png';
      }
    }
    if (type === 'confirm') {
      this.showPasswordConfirm = !this.showPasswordConfirm;
      if (this.showPasswordConfirm) {
        this.pswEyeConfirm = '../../assets/images/password-eye.png';
      } else {
        this.pswEyeConfirm = '../../assets/images/password-eye-close.png';
      }
    }
  }
}
