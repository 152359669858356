import { Component } from '@angular/core';
import { AuthServiceService } from '../service/auth/auth-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { VALIDATION_MESSAGES, MESSAGES } from '../helper/messages';
import { redirectToURL } from '../helper/constant';

interface loginUser {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm!: FormGroup;
  loginSubmit: boolean | undefined;

  users: loginUser[] | undefined;

  password: string | undefined;
  showPassword: boolean = false;
  pswEye: string = '../../assets/images/password-eye-close.png';
  errorMessages = VALIDATION_MESSAGES;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: AuthServiceService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.autoLogin();

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  autoLogin(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate([redirectToURL]);
    }
  }

  loginUser() {
    if (this.loginForm.valid) {
      const value = this.loginForm.value;
      const loginUser: loginUser = {
        email: value?.email,
        password: value?.password,
      };
      this.loginService?.loginUser(loginUser).subscribe(
        (response) => {
          if (response?.access_token) {
            this.toastr.success(MESSAGES.LOGIN_SUCCESS);
            const token = response?.access_token;
            localStorage.setItem('token', token);
            this.router.navigate([redirectToURL]);
          }
        },
        (error) => {
          this.toastr.error(
            error?.error?.message || VALIDATION_MESSAGES.SOMETHING_WENT_WRONG
          );
          console.error(error);
        }
      );
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.pswEye = '../../assets/images/password-eye.png';
    } else {
      this.pswEye = '../../assets/images/password-eye-close.png';
    }
  }
}
