<section class="addDatSheetCommonSec">

  <div class="serachMainAllWrapper filter-button-style">
    <div class="searchDiv">
      <form class="example-form">
        <input type="text" placeholder="Search for users..." [formControl]="search" class="example-input">
      </form>
    </div>
    <div class="selectWrapper">
      <mat-form-field class="example-full-width">
        <mat-select placeholder="SELECT ROLE" (selectionChange)="onSelectionUserType($event)">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let user of usertypes" [value]="user.id"> {{ user.roleName  | titlecase  }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()" #openModal>ADD
      USER</button>
  </div>
  <div class="dataSheetTable">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>
            First Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{element?.firstName || "___"}}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>
            Last Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{element?.lastName || "___"}}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="mail">
          <th mat-header-cell *matHeaderCellDef>
            Email
          </th>
          <td class="lowercase" mat-cell *matCellDef="let element"> {{element?.mail || "___"}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>
            Phone
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.phone || "___"}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>
            Role
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.role || "___"}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.status === 1 ? ACTIVE : INACTIVE }} </td>
        </ng-container>


        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
            Action
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="dropMenuMain">
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="navigateToUserDetails(element?.id)">View</button>
                <button mat-menu-item (click)="userUpdate(element?.id)">Edit</button>
                <button mat-menu-item (click)="openDeleteDialog(element?.id , 2)">Delete</button>
                <button mat-menu-item (click)="userStatus(element?.id , 1)"
                  *ngIf="element?.status === 0">Activate</button>
                <button mat-menu-item (click)="userStatus(element?.id , 0)"
                  *ngIf="element?.status === 1">Deactivate</button>
              </mat-menu>

              <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                  fill="black" />
                <path
                  d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                  fill="black" />
                <path
                  d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                  fill="black" />
              </svg>


            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>
    <div class="no-data-found" *ngIf="!totalItems">
      <img src="../../assets/images/no-data-found.png" alt="noData">
    </div>
  </div>

  <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
    [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
  </mat-paginator>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
            <img alt="" src="../../assets/images/close-icon1.png">
          </button>
        </div>
        <div class="modal-body">
          <div class="row rowSpaceMain">
            <form [formGroup]="createUserForm" (ngSubmit)="createUser()">
              <div class="col-sm-12 spaceMainall">
                <h2>{{ isEditMode ? 'EDIT USER' : 'ADD USER' }}</h2>
                <div class="formFieldMainAll">
                  <span>First Name</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="First Name" formControlName="firstName">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['firstName'].valid &&
            (createUserForm.controls['firstName'].dirty ||
              createUserForm.controls['firstName'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['firstName'].hasError('required')">
                      First Name is required
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Last Name</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Last Name" formControlName="lastName">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['lastName'].valid &&
            (createUserForm.controls['lastName'].dirty ||
              createUserForm.controls['lastName'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['lastName'].hasError('required')">
                      Last Name is required
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Email ID</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Email ID" formControlName="email">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['email'].valid &&
            (createUserForm.controls['email'].dirty ||
              createUserForm.controls['email'].touched ||
              createUserFormSubmit)
          ">
          <div class="s_validation" *ngIf="createUserForm.controls['email'].hasError('email')">
            Email must follow the format
          </div>
                    <div class="s_validation" *ngIf="createUserForm.controls['email'].hasError('required')">
                      Email is required
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Phone Number</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Phone Number" formControlName="phone">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['phone'].valid &&
            (createUserForm.controls['phone'].dirty ||
              createUserForm.controls['phone'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['phone'].hasError('required')">
                      Phone Number is required
                    </div>
                    <div class="s_validation" *ngIf="createUserForm.controls['phone'].hasError('pattern')">
                      Phone Number must be a 10-digit number
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Password</span>
                  <mat-form-field class="example-full-width">
                    <input type="password" matInput placeholder="Password" autocomplete="new-password"
                      formControlName="password">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['password'].valid &&
            (createUserForm.controls['password'].dirty ||
              createUserForm.controls['password'].touched ||
              createUserFormSubmit)
          ">   
                    <div class="s_validation" *ngIf="createUserForm.controls['password'].hasError('required')">
                      Password is required
                    </div>   
                    <div class="s_validation" *ngIf="createUserForm.controls['password'].hasError('minlength')">
                      Password must be at least 8 characters long
                    </div>
                    <div class="s_validation" *ngIf="createUserForm.controls['password'].hasError('maxlength')">
                      Password cannot be more than 16 characters long
                    </div>
                    <!-- <div *ngIf="createUserForm.controls['password'].errors">
                      {{ createUserForm.controls['password'].errors | json }}
                    </div> -->
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Role</span>
                  <mat-form-field>
                    <mat-select placeholder="Select" formControlName="userType">
                      <mat-option *ngFor="let user of usertypes" [value]="user.id"> {{ user.roleName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['userType'].valid &&
            (createUserForm.controls['userType'].dirty ||
              createUserForm.controls['userType'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['userType'].hasError('required')">
                      User Type is required
                    </div>
                  </div>
                </div>

                <div class="formFieldMainAll" *ngIf="userType === GARAGE || userType === AGENT">
                  <span>Subscription Plans</span>
                  <mat-form-field>
                    <mat-select placeholder="Select" formControlName="plan">
                      <mat-option *ngFor="let plan of subscriptionPlans" [value]="plan.id"> {{ plan.planName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="formFieldMainAll"
                  *ngIf="userType === GARAGE || userType === AGENT || userType === CUSTOMER">
                  <span>Address</span>
                  <mat-form-field class="example-full-width">
                    <textarea matInput placeholder="Address" formControlName="address"></textarea>
                  </mat-form-field>
                </div>

                <div class="formFieldMainAll" *ngIf="userType === GARAGE">
                  <span>Registration Number</span>
                  <mat-form-field class="example-full-width">
                    <input type="text" matInput placeholder="Registration Number" formControlName="registrationNumber">
                  </mat-form-field>
                </div>


                <div class="formFieldMainAll" *ngIf="userType === AGENT">
                  <span>License Number</span>
                  <mat-form-field class="example-full-width">
                    <input type="text" matInput placeholder="License Number" formControlName="licenseNumber">
                  </mat-form-field>
                </div>

                <div class="formFieldMainAll">
                  <button type="submit" class="addUserSend" [disabled]="createUserForm.invalid">SUBMIT</button>
                </div>

              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
