<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for parts..." [formControl]="search" class="example-input">
            </form>
        </div>


        <button type="button" class="primaryColotBtn" (click)="clearFilter()">CLEAR</button>
        <button type="button" class="primaryColotBtn" data-bs-toggle="modal"
            data-bs-target="#filterModal">FILTER</button>

            <button type="button" class="primaryColotBtn" data-bs-toggle="modal"
            data-bs-target="#importModal">IMPORT</button> 

        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()" #openModal>ADD
            PARTS</button>
    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                <!-- Position Column -->
                <ng-container matColumnDef="productSku">
                    <th mat-header-cell *matHeaderCellDef>
                        Part SKU
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.productSku}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.status === 1 ? ACTIVE : INACTIVE || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="partNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        Part Number
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.partNumber || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="partName">
                    <th mat-header-cell *matHeaderCellDef>
                        Part Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.partName || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="productCode">
                    <th mat-header-cell *matHeaderCellDef>
                        Product Code
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.productCode || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="mfgName">
                    <th mat-header-cell *matHeaderCellDef>
                        Manufacture
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.mfgName || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="qtyAvail">
                    <th mat-header-cell *matHeaderCellDef>
                        Quantity
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.qtyAvail || "0"}} </td>
                </ng-container>
                <ng-container matColumnDef="stockStatus">
                    <th mat-header-cell *matHeaderCellDef>
                        Stock Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.stockStatus >0 ? INSTOCK : OUTOFSTOCK || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef>
                        Image
                    </th>
                    <td mat-cell *matCellDef="let element" data-bs-toggle="modal" data-bs-target="#imgModal"
                        (click)="popupDataImg(element?.imagePopup)">
                        <img *ngIf="element.imageUrl" [src]="imagePath">
                        <span *ngIf="!element.imageUrl">___</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dropMenuMain">
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="navigateToProductEnquiryView(element.id)">View</button>
                                <button mat-menu-item (click)="setFormData(element?.id)">Edit</button>
                                <button mat-menu-item (click)="partDelete(element.id)">Delete</button>
                                <button mat-menu-item *ngIf="element.status === 0"
                                    (click)="partStatusUpdate(element.id, 1)">{{STATUS_BUTTONS.ACTIVATE}}</button>

                                <button mat-menu-item *ngIf="element.status === 1"
                                    (click)="partStatusUpdate(element.id, 0)">{{STATUS_BUTTONS.DEACTIVATE}}</button>
                            </mat-menu>

                            <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                                    fill="black" />
                                <path
                                    d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                                    fill="black" />
                                <path
                                    d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                                    fill="black" />
                            </svg>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItems">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
    </mat-paginator>
</section>


<!-- pop Up -->
<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="createForm" (ngSubmit)="createInventory()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>{{ isEditMode ? 'EDIT PARTS' : 'ADD PARTS' }}</h2>
                                <div class="formFieldMainAll">
                                    <span>Product Sku</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Product Sku" formControlName="productSku">
                                    </mat-form-field>  
                    <div class="s_error" *ngIf="
                    !createForm.controls['productSku'].valid &&
                    (createForm.controls['productSku'].dirty ||
                    createForm.controls['productSku'].touched ||
                    createSubmit)
                  ">
                  
                            <div class="s_validation" *ngIf="createForm.controls['productSku'].hasError('required')">
                             Product SKU is required
                            </div>
                          </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Part Number</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Part Number" formControlName="partNumber">
                                    </mat-form-field>
                                      
                    <div class="s_error" *ngIf="
                    !createForm.controls['partNumber'].valid &&
                    (createForm.controls['partNumber'].dirty ||
                    createForm.controls['partNumber'].touched ||
                    createSubmit)
                  ">
                  
                            <div class="s_validation" *ngIf="createForm.controls['partNumber'].hasError('required')">
                             Part Number is required
                            </div>
                          </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Part Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Part Name" formControlName="partName">
                                    </mat-form-field>
                                    
                                      
                    <div class="s_error" *ngIf="
                    !createForm.controls['partName'].valid &&
                    (createForm.controls['partName'].dirty ||
                    createForm.controls['partName'].touched ||
                    createSubmit)
                  ">
                  
                            <div class="s_validation" *ngIf="createForm.controls['partName'].hasError('required')">
                             Part Name is required
                            </div>
                          </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Part Description</span>
                                    <mat-form-field class="example-full-width">
                                        <textarea matInput placeholder="Part Description"
                                            formControlName="partDesc"></textarea>
                                    </mat-form-field>
                    <div class="s_error" *ngIf="
                    !createForm.controls['partDesc'].valid &&
                    (createForm.controls['partDesc'].dirty ||
                    createForm.controls['partDesc'].touched ||
                    createSubmit)
                  ">
                  
                            <div class="s_validation" *ngIf="createForm.controls['partDesc'].hasError('required')">
                             Part Description is required
                            </div>
                          </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Manufacture</span>
                                    <ng-select [items]="manufacturersDropDown" bindLabel="mfgName" bindValue="mfgName"
                                        [virtualScroll]="true" formControlName="mfgName"
                                        [placeholder]="'Select Manufacture'" [clearable]="true"></ng-select>
                                        
                    <div class="s_error" *ngIf="
                    !createForm.controls['mfgName'].valid &&
                    (createForm.controls['mfgName'].dirty ||
                    createForm.controls['mfgName'].touched ||
                    createSubmit)
                  ">
                  
                            <div class="s_validation" *ngIf="createForm.controls['mfgName'].hasError('required')">
                                Manufacture is required
                            </div>
                          </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Variant</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Variant" formControlName="variant">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Quantity</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Quantity" formControlName="quantity">
                                    </mat-form-field>
                                    
                    <div class="s_error" *ngIf="
                    !createForm.controls['quantity'].valid &&
                    (createForm.controls['quantity'].dirty ||
                    createForm.controls['quantity'].touched ||
                    createSubmit)
                  ">
                  
                            <div class="s_validation" *ngIf="createForm.controls['quantity'].hasError('required')">
                                Quantity is required
                            </div>
                          </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Seller Price</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Seller Price" formControlName="sellerPrice">
                                    </mat-form-field>
                                    
                                    <div class="s_error" *ngIf="
                                        !createForm.controls['sellerPrice'].valid &&
                                        (createForm.controls['sellerPrice'].dirty ||
                                        createForm.controls['sellerPrice'].touched ||
                                        createSubmit)
                                        ">
                  
                                        <div class="s_validation" *ngIf="createForm.controls['sellerPrice'].hasError('required')">
                                            Seller Price is required
                                        </div>
                                </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Reseller Price</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Reseller Price" formControlName="resellerPrice">
                                    </mat-form-field>
                                    
                                    <div class="s_error" *ngIf="
                                        !createForm.controls['resellerPrice'].valid &&
                                        (createForm.controls['resellerPrice'].dirty ||
                                        createForm.controls['resellerPrice'].touched ||
                                        createSubmit)
                                        ">
                  
                                        <div class="s_validation" *ngIf="createForm.controls['resellerPrice'].hasError('required')">
                                            Reseller Price is required
                                        </div>
                                </div>
                                </div>
                                <div class="formFieldMainAll custom-fileinput">
                                    <span>Part Image</span>
                                    <input 
                                      [hidden]="true" 
                                      type="file" 
                                      (change)="onFileChange($event)"
                                      class="form-control" 
                                      placeholder="Please upload a photo here"  
                                      #fileSelect
                                      multiple>
                                    
                                    <input 
                                      readonly 
                                      style="cursor: pointer" 
                                      placeholder="Please upload a photo here"
                                      formControlName="partLabel" 
                                      (click)="fileSelect.click()" 
                                      class="form-control" />
                                    <div class="inputFileIcon" (click)="fileSelect.click();">
                                      <img src="/assets/images/clip.svg" alt="icons">
                                    </div>
                                    
                                    <div class="s_error" *ngIf="!createForm.controls['partLabel'].valid && 
                                      (createForm.controls['partLabel'].dirty || 
                                      createForm.controls['partLabel'].touched || 
                                      createSubmit)">
                                      <div class="s_validation" *ngIf="createForm.controls['partLabel'].hasError('required')">
                                        Image is required
                                      </div>
                                    </div>
                                  
                                    <!-- Loader Display -->
                                    <div *ngIf="isUploading" class="loader-container">
                                      <p>Uploading..</p>
                                    </div>
                                  
                                    <!-- Display Selected Images -->
                                    <div class="file-name-preview-container">
                                      <div *ngFor="let image of imagePreviews" class="file-name-item">
                                        <!-- <img [src]="image.url" alt="Selected Image" class="image-preview" /> -->
                                        <span>{{ image.fileName }}</span>
                                        <button (click)="removeImage(image)" class="remove-button">X</button>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  

                                <div class="formFieldMainAll">
                                    <span>Vehicle</span>
                                    <ng-select [items]="vehiclesDropDown" bindLabel="combinedLabel" bindValue="id"
                                        [virtualScroll]="true" formControlName="vehicleId"
                                        [placeholder]="'Select Vehicle'" [clearable]="true">

                                    </ng-select>
                                    
                                    
                                    <div class="s_error" *ngIf="
                                        !createForm.controls['vehicleId'].valid &&
                                        (createForm.controls['vehicleId'].dirty ||
                                        createForm.controls['vehicleId'].touched ||
                                        createSubmit)
                                        ">
                  
                                        <div class="s_validation" *ngIf="createForm.controls['vehicleId'].hasError('required')">
                                            Vehicle is required
                                        </div>
                                    </div>
                                </div>

                                <div class="formFieldMainAll">
                                    <span>Category</span>
                                    <div class="custom-ng-select">
                                        <ng-select [items]="categoriesDropDown" bindLabel="categoryName"
                                            bindValue="categoryId" [virtualScroll]="true" formControlName="categoryId"
                                            (change)="listSubCatOfCategory($event)" [placeholder]="'Select category'"
                                            [clearable]="true">
                                        </ng-select>
                                    
                                        <div class="s_error" *ngIf="
                                            !createForm.controls['categoryId'].valid &&
                                            (createForm.controls['categoryId'].dirty ||
                                            createForm.controls['categoryId'].touched ||
                                            createSubmit)
                                            ">
                      
                                            <div class="s_validation" *ngIf="createForm.controls['categoryId'].hasError('required')">
                                                Category is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="formFieldMainAll">
                                    <span>SubCategory</span>
                                    <ng-select [items]="subcategoriesDropDown" bindLabel="subCatName" bindValue="id"
                                        [virtualScroll]="true" formControlName="subCatId"
                                        [placeholder]="'Select SubCategory'" [clearable]="true">
                                    </ng-select>
                                    
                                    <div class="s_error" *ngIf="
                                        !createForm.controls['subCatId'].valid &&
                                        (createForm.controls['subCatId'].dirty ||
                                        createForm.controls['subCatId'].touched ||
                                        createSubmit)
                                        ">
                  
                                        <div class="s_validation" *ngIf="createForm.controls['subCatId'].hasError('required')">
                                            Sub-Category is required
                                        </div>
                                    </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Stock Status</span>
                                    <mat-form-field class="example-full-width">
                                        <mat-select placeholder="Select Stock Status" formControlName="stockStatus">
                                            <mat-option value="1">In Stock</mat-option>
                                            <mat-option value="0">Out of Stock</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                    <div class="s_error" *ngIf="
                                        !createForm.controls['stockStatus'].valid &&
                                        (createForm.controls['stockStatus'].dirty ||
                                        createForm.controls['stockStatus'].touched ||
                                        createSubmit)
                                        ">
                  
                                        <div class="s_validation" *ngIf="createForm.controls['stockStatus'].hasError('required')">
                                            Stock status is required
                                        </div>
                                    </div>
                                </div>

                                <div class="formFieldMainAll">
                                    <span>Status</span>
                                    <mat-form-field class="example-full-width">
                                        <mat-select placeholder="Select Status" formControlName="status">
                                            <mat-option value="1">Active</mat-option>
                                            <mat-option value="0">Inactive</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                    <div class="s_error" *ngIf="
                                        !createForm.controls['status'].valid &&
                                        (createForm.controls['status'].dirty ||
                                        createForm.controls['status'].touched ||
                                        createSubmit)
                                        ">
                  
                                        <div class="s_validation" *ngIf="createForm.controls['status'].hasError('required')">
                                           Status is required
                                        </div>
                                    </div>
                                </div>

                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend"
                                        [disabled]="createForm.invalid">SUBMIT</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- filter popup -->

<div class="usersPopupdiv">
    <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
                        #closeModalFilter>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="filterForm" (ngSubmit)="filterApply()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>FILTER</h2>
                                <div class="formFieldMainAll">
                                    <span>Category</span>
                                    <ng-select class="custom-dropdown" [items]="categoriesDropDown" bindLabel="categoryName"
                                        bindValue="categoryId" [virtualScroll]="true" formControlName="category"
                                        (change)="listSubCatOfCategory($event)" [placeholder]="'Select category'"
                                        [clearable]="true">
                                    </ng-select>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Subcategory</span>
                                    <ng-select [items]="subcategoriesDropDown" bindLabel="subCatName" bindValue="id"
                                        [virtualScroll]="true" formControlName="subCategory"
                                        [placeholder]="'Select SubCategory'" [clearable]="true">
                                    </ng-select>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Manufacturer</span>
                                    <ng-select [items]="manufacturersDropDown" bindLabel="mfgName" bindValue="mfgName"
                                        [virtualScroll]="true" formControlName="manufacturer"
                                        [placeholder]="'Select Manufacture'" [clearable]="true"></ng-select>
                                </div>

                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend">APPLY</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Image popup -->

<div class="usersPopupdiv">
    <div class="modal fade" id="imgModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
                        #closeModalFilter>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <div class="col-sm-12 imge">
                            <div [ngStyle]="{height:height+'px',width:width+'px'}" *ngIf="isLoading">
                                <img [src]=loader />
                            </div>
                            <img [src]="imagePopupData" alt="img"
                                [ngStyle]="{visibility:isLoading?'hidden':'',height:height+'px',width:width+'px'}"
                                (load)="hideLoader()" (error)="hideLoader()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="usersPopupdiv">
    <div class="modal fade" id="importModal" tabindex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
                        #closeModalBulk>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="bulkUploadForm" (ngSubmit)="InventorybulkUpload()" method="POST" enctype="multipart/form-data">
                            <div class="col-sm-12 spaceMainall">
                                <h2>IMPORT</h2>
                                <!-- <div class="formFieldMainAll custom-fileinput">
                                    <span>Upload CSV</span>
                                    <input [hidden]="true" type="file" formControlName="uploadCSV"
                                        (change)="onFileCSVChange($event)" class="form-control"
                                        placeholder="Please upload a photo here" #fileSelect>
                                    <input readonly style="cursor: pointer" placeholder="Please upload a photo here"
                                        formControlName="uploadCSVLabel" (click)="fileSelect.click()"
                                        class="form-control" />
                                    <div class="inputFileIcon" (click)="fileSelect.click();">
                                        <img src="/assets/images/clip.svg" alt="icons">
                                    </div>
                                </div> -->
                                <div class="formFieldMainAll">
                                    <span>Upload File</span>
                                    <input type="file" id="fileInput" formControlName="uploadCSV"
                                        (change)="onFileCSVChange($event)" />
                                </div>

                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend">SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Add download link here -->
                <div class="formFieldMainAll anchorSample">
                    <a  href="../../assets/sample.csv" download="template.csv" class="download-link">Download Sample CSV </a>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>