import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import {
  CATEGORY_LIST,
  GET_ONE_PART_REQUEST,
  LIST_SUBCAT_OF_CAT,
  SEARCH_FOR_PARTS,
  UPDATE_PARTS,
  VEHICLES_LIST,
} from '../helper/queries';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FireImgUploadService } from '../service/imageupload/fire-img-upload.service';
import { currencyType } from '../helper/constant';
import {
  ADD_PARTS,
  MANUFACTURERS,
  LIST_VEHICLE,
  LIST_CATEGORIES,
  LIST_SUB_CATEGORIES,
} from '../helper/queries';
import { MESSAGES } from '../helper/messages';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-enguiry-view',
  templateUrl: './product-enguiry-view.component.html',
  styleUrls: [
    './product-enguiry-view.component.scss',
    '../users/users.component.scss',
  ],
})
export class ProductEnguiryViewComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model
  productEnguiryData: any;
  productImg: any;
  searchResult: any[] = [];
  selectedPart: any; // To keep track of the selected part
  isVarient: boolean = false;
  isEditMode: boolean = false;
  createForm!: FormGroup; // user create
  vehiclesDropDown: any;
  categoriesDropDown: any;
  subcategoriesDropDown: any;
  manufacturersDropDown: any;
  partImage: any = null;
  orderID!: any;
  searchForm!: FormGroup;
  isSearch: boolean = false;
  isUploading: boolean = false;
  search = new FormControl('');
  imagePreviews: { url: string, fileName: string }[] = [];
  partImageUrls: string[] = [];


  constructor(
    private route: ActivatedRoute,
    private readonly apollo: Apollo,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private imageUploadService: FireImgUploadService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({
      id: [''],
      productSku: ['', [Validators.required]],
      partNumber: ['', [Validators.required]],
      partName: ['', [Validators.required]],
      partDesc: ['', [Validators.required]],
      mfgName: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      sellerPrice: ['', [Validators.required]],
      resellerPrice: ['', [Validators.required]],
      vehicleId: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      subCatId: ['', [Validators.required]],
      stockStatus: ['', [Validators.required]],
      status: ['', [Validators.required]],
      partLabel: [''],
      varient: ['', [Validators.required]],
    });
    this.createForm.reset();
    this.listManufacturers();
    this.listCategories();
    this.listVehicle();

    this.route.params.subscribe((params) => {
      this.orderID = params['id'];
      this.productenguiryview(parseInt(this.orderID));
    });

    this.searchForm = new FormGroup({
      searchInput: new FormControl('', Validators.required),
    });
  }

  searchForParts() {
    let searchData = this.searchForm.get('searchInput')?.value;
    this.apollo
      .watchQuery({
        query: SEARCH_FOR_PARTS,
        variables: {
          make: this.productEnguiryData?.make,
          year: this.productEnguiryData?.year,
          model: this.productEnguiryData?.model,
          engine: this.productEnguiryData?.engine
            ? this.productEnguiryData?.engine
            : '',
          categoryId: null,
          subCatId: null,
          search: searchData,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.searchResult = data?.searchForPart;
        console.log(this.searchResult);
        
        this.isSearch = true;
        searchData = null;
        if (this.searchResult.length > 0) {
          this.isEditMode = true;
        }
        if (this.searchResult.length === 1) {
          this.selectedPart = this.searchResult[0];
        }
      });
  }

  onPartSelected(part: any) {
    this.selectedPart = part;
    this.populateFormWithSelectedPart();
  }

  populateFormWithSelectedPart() {
    if (this.selectedPart) {
      console.log(this.selectedPart);
      
      this.createForm.patchValue({
        productSku: this.selectedPart.productSku,
        partNumber: this.selectedPart.partNumber,
        partName: this.selectedPart.partName,
        partDesc: this.selectedPart.partDesc,
        mfgName: this.selectedPart.mfgName,
        quantity: this.selectedPart.qtyAvail,
        sellerPrice: this.selectedPart.sellerPrice,
        resellerPrice: this.selectedPart.resellerPrice,
        vehicleId: this.selectedPart.vehicleId,
        categoryId: this.selectedPart.categoryId,
        subCatId: this.selectedPart.subCatId,
        stockStatus: this.selectedPart?.stockStatus.toString() ,
        status: this.selectedPart?.status.toString(),
        partLabel: this.selectedPart?.imageUrl        ,
        varient: this.selectedPart?.variants
          ? this.selectedPart?.variants
          : 'null',
      });
      this.setCategoryManufacturerSubCategory(this.selectedPart);
    }
  }

  setCategoryManufacturerSubCategory(part: any) {
    this.createForm.get('mfgName')?.setValue(part.mfgName);
    this.createForm.get('categoryId')?.setValue(part.categoryId);
    this.createForm.get('vehicleId')?.setValue(part.vehicleId);
    console.log(part.vehicleId);
    
    if (part.categoryId) {
      this.apollo
        .watchQuery({
          query: LIST_SUBCAT_OF_CAT,
          variables: {
            categoryId: part.categoryId,
          // make: 'porsche',
          // year: '1999',
          // model: '911',
          // engine: '34l-3400cc-h6-gas-fi-vin-296hp-dohc-naturally-aspirated',
          make: null,
          year: null,
          model: null,
          engine: null,
          },
          fetchPolicy: 'no-cache',
        })
        .valueChanges.subscribe(({ data }: any) => {
          this.subcategoriesDropDown = data?.listSubCatByCat;
          this.createForm.get('subCatId')?.setValue(part.subCatId);
        });
    }
  }
  back(): void {
    this.location.back();
  }

  resetFormState(): void {
    this.searchForm.reset(); // Resets the entire search form
    this.searchResult= [];
    this.selectedPart = null; // Clears the selected part (radio button selection)
    this.isEditMode = false; // Resets the edit mode state
    this.closeModel(); // Close the modal if open

    // Optionally clear other related data
    this.searchResult = [];
    this.isSearch = false;
  }

  productenguiryview(id: number) {
    this.apollo
      .watchQuery({
        query: GET_ONE_PART_REQUEST,
        variables: {
          partRequestId: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.productEnguiryData = data?.getOnePartRequest;
        this.productImg = data?.getOnePartRequest?.imageUrl?.split(';')[0];
      });
  }
  // add-inventory popup
  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  createInventory(): void {
    if (this.createForm.valid) {
      const value = this.selectedPart;
      const Formvalue = this.createForm.value;

      const Formdata = {
        productSku: Formvalue?.productSku,
        partNumber: Formvalue?.partNumber,
        partName: Formvalue?.partName,
        partDesc: Formvalue?.partDesc,
        mfgName: Formvalue?.mfgName,
        qtyAvail: parseInt(Formvalue?.quantity),
        sellerPrice: Formvalue?.sellerPrice,
        resellerPrice: Formvalue?.resellerPrice,
        imageUrl: this.partImage,
        vehicleId: Formvalue?.vehicleId,
        variants: Formvalue?.variants,
        categoryId: Formvalue?.categoryId?.toString(),
        subCatId: Formvalue?.subCatId,
        stockStatus: parseInt(Formvalue?.stockStatus),
        status: parseInt(Formvalue?.status),
        // partLabel: partImg,
      };
      // const partImg = this.imageUploadService.getFileName(value?.imageUrl);
      const data = {
        productSku: value?.productSku,
        partNumber: value?.partNumber,
        partName: value?.partName,
        partDesc: value?.partDesc,
        mfgName: value?.mfgName,
        qtyAvail: parseInt(value?.quantity),
        sellerPrice: value?.sellerPrice,
        resellerPrice: value?.resellerPrice,
        imageUrl: this.partImage,
        vehicleId: value?.vehicleId,
        variants: value?.variants,
        categoryId: value?.categoryId?.toString(),
        subCatId: value?.subCatId,
        stockStatus: parseInt(value?.stockStatus),
        status: parseInt(value?.status),
        // partLabel: partImg,
      };
      if (value?.id) {
        this.apollo
          .mutate({
            mutation: UPDATE_PARTS,
            variables: {
              partsId: value?.id,
              updateParts: Formdata,
              fromEnquiry: 'yes',
              enquiryId: parseInt(this.orderID),
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(
            () => {
              this.toastr.success(MESSAGES.PART_UPDATED);
              this.resetFormState();
              this.closeModel();
            },
            (error) => {
              this.toastr.error(MESSAGES.ERROR_UPDATING_PART);
              console.error(error);
            }
          );
      } else {
        this.apollo
          .mutate({
            mutation: ADD_PARTS,
            variables: {
              createParts: Formdata,
              fromEnquiry: 'yes',
              enquiryId: parseInt(this.orderID),
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(
            () => {
              this.toastr.success(MESSAGES.NEW_PART_ADDED);
              this.resetFormState();
              this.closeModel();
            },
            (error) => {
              this.toastr.error(MESSAGES.ERROR_ADDING_NEWPART);
              console.error(error);
            }
          );
      }
    }
  }

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }
  listManufacturers() {
    this.apollo
      .watchQuery({
        query: MANUFACTURERS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.manufacturersDropDown = data?.listManufacturers;
      });
  }

  listVehicle() {
    this.apollo
      .watchQuery({
        query: VEHICLES_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.vehiclesDropDown = data?.vehiclesList.map((item: any) => ({
          ...item,
          combinedLabel: `${item.make} ${item.model} ${item.year}`,
        }));
      });
  }

  listCategories() {
    this.apollo
      .watchQuery({
        query: CATEGORY_LIST,

        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.categoriesDropDown = data?.listCategories;
      });
  }

  listSubCatOfCategory(event: any) {
    this.apollo
      .watchQuery({
        query: LIST_SUBCAT_OF_CAT,
        variables: {
          categoryId: event.categoryId,
          make: null,
          year: null,
          model: null,
          engine: null,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.subcategoriesDropDown = data?.listSubCatByCat;
      });
  }

  // onFileChange(event: any) {
  //   if (!event.target.files || !event.target.files[0]) {
  //     return;
  //   }
  //   this.onFileSelected(event);
  //   const fileName = event.target.files[0].name;
  //   this.createForm?.get('partLabel')?.setValue(fileName);
  // }
  // onFileSelected(event: any) {
  //   const fileObj = event.target.files[0];
  //   this.imageUploadService
  //     .uploadImage(fileObj)
  //     .then((downloadURL) => {
  //       this.partImage = downloadURL;
  //     })
  //     .catch((error) => {
  //       console.error('Error uploading image:', error);
  //     });
  // }

  
onFileChange(event: any) {
  const files: FileList | null = event.target.files;
  if (!files || files.length === 0) {
    return;
  }

  this.isUploading = true; // Show loader

  // Convert FileList to an array and process files one by one
  Array.from(files).forEach((file: File) => {
    this.uploadFile(file);
  });
}

uploadFile(file: File) {
  this.imageUploadService.uploadImage(file).then((downloadURL) => {
    this.partImageUrls.push(downloadURL);
    this.imagePreviews.push({ url: downloadURL, fileName: file.name });
    this.partImage = this.partImageUrls.join(';');
    this.createForm.get('partLabel')?.setValue(this.partImage);
    this.isUploading = false; // Hide loader after upload
  }).catch((error) => {
    console.error('Error uploading image:', error);
    this.isUploading = false; // Hide loader after upload
  });
}

removeImage(image: { url: string, fileName: string }) {
  this.imagePreviews = this.imagePreviews.filter(img => img !== image);
  this.partImageUrls = this.partImageUrls.filter(url => url !== image.url);
  this.createForm.get('partLabel')?.setValue(this.partImageUrls.join(';'));
}
}
