<section class="addDatSheetCommonSec">

  <div class="serachMainAllWrapper">
    <div class="searchDiv">
      <form class="example-form">
        <input type="text"
               placeholder="Data Sheet"
               [formControl]="control"
               [matAutocomplete]="auto"
               class="example-input">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
            {{street}}
          </mat-option>
        </mat-autocomplete>
      </form>
    </div>
    <button>ADD NEW DATASHEET</button>
  </div>
  <div class="dataSheetTable">
    <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
       class="mat-elevation-z8">

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
      User ID
    </th>
    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
      Date
    </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
      Product code
    </th>
    <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
      Vendor
    </th>
    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
      Action
    </th>
    <td mat-cell *matCellDef="let element"> <div class="editDropDown">
      <select name="cars" id="cars">
        <option value="volvo">Edit</option>
        <option value="saab">Saab</option>
        <option value="opel">Opel</option>
        <option value="audi">Audi</option>
      </select>
    </div></td>
  </ng-container>
  <ng-container matColumnDef="datasheet">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
      Datasheet
    </th>
    <td mat-cell *matCellDef="let element"> <img alt="" src="../../assets/images/pdf-icon.png"> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>



  </div>
</section> 

