export const timeDelay = {
  search: 1000,
};

export const STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const STOCK = {
  INSTOCK: 'In Stock',
  OUTOFSTOCK: 'Out Of Stock',
};
export const redirectToURL = '/dashboard';

export const currencyType = 'AED';

export const STATUS_BUTTONS = {
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
};

export const CUSTOMER_TYPE_LIST = [
  {
    id: 4,
    roleName: 'Customer',
  },
  {
    id: 3,
    roleName: 'Garage Owner',
  },
  {
    id: 2,
    roleName: 'Agent',
  },
];

export const USER_TYPE_LIST = [
  {
    id: 2,
    roleName: 'admin',
  },
];
export const DISCOUNT_TYPE = {
  FIXED: 'fixed',
  PERCENTAGE: 'percentage',
};

export const ORDER_STATUS_TYPE= {
  ORDER_PLACED: 'placed',
  ORDER_CONFIRMED: 'confirmed',
  ORDER_SHIPPED: 'shipped',
  ORDER_COMPLETED: 'completed',
  ORDER_CANCELLED: 'cancelled',
};