<div class="payLayOutSecMain">
      <app-header></app-header>
      <div class="payLayOutSecMainPos">
            <app-sidebar></app-sidebar>
      </div>

      <div class="sideBarMenuMobile">
            <button class="btn btn-primary menuMobile" type="button" data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <img alt="" src="../../assets/images/mobile-menu.png">
            </button>

            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel">
                  <div class="offcanvas-header">

                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                              aria-label="Close">

                        </button>
                  </div>
                  <div class="offcanvas-body">
                        <div class="sideBarMain">
                              <div class="logo-wrapper">
                                    <img class="logoImg" alt="" src="../../assets/images/logo.png">
                              </div>
                              <ul>
                                    <li *ngIf="checkUserPermission(moduleName.viewDashbard)"
                                          (click)="updateHeaderTitle('Dashboard')"> <a routerLink="/dashboard"
                                                routerLinkActive=" activeMain"><span>
                                                      <img src="../../assets/images/icon-16.svg"></span>Dashboard</a>
                                    </li>
                                    <li *ngIf="checkUserPermission(moduleName.viewOrder)"
                                          (click)="updateHeaderTitle('Orders')"> <a routerLink="/order-management"
                                                routerLinkActive=" activeMain"><span>
                                                      <img src="../../assets/images/icon-5.svg"></span>Orders</a>
                                    </li>
                                    <li *ngIf="checkUserPermission(moduleName.viewProductEnquiries)"
                                          (click)="updateHeaderTitle('Product Enquiries')"> <a
                                                routerLink="/product-enquiry" routerLinkActive="activeMain"><span>
                                                      <img src="../../assets/images/icon-12.svg"></span>Product
                                                Enquiries</a>
                                    </li>

                                    <li *ngIf="checkUserPermission(moduleName.viewInventory)"
                                          (click)="updateHeaderTitle('Inventories')"> <a routerLink="/inventory"
                                                routerLinkActive="activeMain"><span>
                                                      <img src="../../assets/images/icon-11.svg"></span>Inventories</a>
                                    </li>
                                    <li *ngIf="checkUserPermission(moduleName.viewCategory)"
                                          (click)="updateHeaderTitle('Categories')"> <a routerLink="/category"
                                                routerLinkActive="activeMain"><span>
                                                      <img src="../../assets/images/icon-9.svg"></span>Categories</a>
                                    </li>
                                    <li *ngIf="checkUserPermission(moduleName.viewAddSubCategory)"
                                          (click)="updateHeaderTitle('Sub Categories')"> <a routerLink="/subcategory"
                                                routerLinkActive="activeMain"><span>
                                                      <img src="../../assets/images/icon-10.svg"></span>Sub
                                                Categories</a>
                                    </li>
                                    <li>
                                          <mat-accordion>
                                                <mat-expansion-panel>
                                                      <mat-expansion-panel-header>
                                                            <mat-panel-title><img
                                                                        src="../../assets/images/icon-3.svg">User
                                                                  Management</mat-panel-title>
                                                      </mat-expansion-panel-header>
                                                      <ul>
                                                            <li *ngIf="checkUserPermission(moduleName.viewCustomer)"
                                                                  (click)="updateHeaderTitle('Customers')"> <a
                                                                        routerLink="/customers"
                                                                        routerLinkActive=" activeMain"><span>
                                                                              <img
                                                                                    src="../../assets/images/icon-2.svg"></span>Customers</a>
                                                            <li *ngIf="checkUserPermission(moduleName.viewUser)"
                                                                  (click)="updateHeaderTitle('Users')"> <a
                                                                        routerLink="/users"
                                                                        routerLinkActive=" activeMain"><span>
                                                                              <img
                                                                                    src="../../assets/images/icon-14.svg"></span>Users</a>
                                                            </li>
                                                            <li *ngIf="checkUserPermission(moduleName.viewRoles)"
                                                                  (click)="updateHeaderTitle('Roles')"> <a
                                                                        routerLink="/users-roles"
                                                                        routerLinkActive=" activeMain"><span>
                                                                              <img
                                                                                    src="../../assets/images/icon-15.svg"></span>Roles</a>
                                                            </li>
                                                            <li *ngIf="checkUserPermission(moduleName.viewPermission)"
                                                                  (click)="updateHeaderTitle('Permissions')">
                                                                  <a routerLink="/permissions"
                                                                        routerLinkActive="activeMain"><span>
                                                                              <img
                                                                                    src="../../assets/images/icon-13.svg"></span>Permissions</a>
                                                            </li>
                                                      </ul>
                                                </mat-expansion-panel>
                                          </mat-accordion>
                                    </li>

                                    <li *ngIf="checkUserPermission(moduleName.viewTransaction)"
                                          (click)="updateHeaderTitle('Transactions')"> <a routerLink="/transaction"
                                                routerLinkActive=" activeMain"><span>
                                                      <img src="../../assets/images/icon-6.svg"></span>Transactions</a>
                                    </li>
                                    <li *ngIf="checkUserPermission(moduleName.viewSubscription)"
                                          (click)="updateHeaderTitle('Subscription Plans')">
                                          <a routerLink="/subscription" routerLinkActive=" activeMain"><span>
                                                      <img src="../../assets/images/icon-4.svg"></span>Subscription
                                                Plans</a>
                                    </li>
                              </ul>
                        </div>

                  </div>
            </div>
      </div>

      <router-outlet></router-outlet>

</div>