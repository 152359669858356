import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import {
  ADD_USER_TYPE,
  LIST_USERS_TYPE,
  UPDATE_USER_TYPE,
  USER_TYPE_STATUS,
} from '../helper/queries';
import { STATUS, timeDelay } from '../helper/constant';
import { debounceTime } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MESSAGES } from '../helper/messages';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class UserTypeComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model

  search = new FormControl(''); //  table serch
  displayedColumns: string[] = ['role', 'editable', 'status', 'action']; // tables colums

  dataSource = new MatTableDataSource(); // table source

  readonly YES = 'Yes';
  readonly NO = 'No';
  readonly ACTIVE = STATUS.ACTIVE;
  readonly INACTIVE = STATUS.INACTIVE;

  createForm!: FormGroup; // user create
  createSubmit: boolean | undefined;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  isEditMode: boolean = false;

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: [''],
      roleName: ['', [Validators.required]],
      isEditable: ['', [Validators.required]],
    });

    this.fetchUserType();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.fetchUserType(searchTerm);
      });
  }

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  fetchUserType(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_USERS_TYPE,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listUserTypesWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            role: resp?.roleName,
            editable: resp?.isEditable,
            status: parseInt(resp?.status),
          })
        );
        this.totalItems =
          data?.listUserTypesWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listUserTypesWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listUserTypesWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.fetchUserType(this.search.value);
  }

  createUserType(): void {
    if (this.createForm.valid) {
      const value = this.createForm.value;
      if (value?.id) {
        const createUsertype = {
          roleName: value?.roleName,
          isEditable: JSON.parse(value?.isEditable),
        };
        this.apollo
          .mutate({
            mutation: UPDATE_USER_TYPE,
            variables: {
              usertypeId: value?.id,
              updateUsertype: createUsertype,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: () => {
              this.fetchUserType();
              this.toastr.success(MESSAGES.PART_UPDATED);
              this.closeModel();
            },
            error: (errors) => {
              this.toastr.error(errors.message)
            }
          });
      } else {
        const createUsertype = {
          roleName: value?.roleName,
          isEditable: JSON.parse(value?.isEditable),
        };
        this.apollo
          .mutate({
            mutation: ADD_USER_TYPE,
            variables: {
              createUsertype: createUsertype,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: () => {
              this.fetchUserType();
              this.toastr.success(MESSAGES.NEW_PART_ADDED)
              this.closeModel();
            },
            error: (errors) => {
              this.toastr.error(errors.message)
            }
          });
      }
    }
  }

  updateUserType(data: any): void {
    const newData = {
      id: data?.id,
      roleName: data?.role,
      isEditable: data?.editable?.toString(),
    };
    this.openModel();
    this.isEditMode = true;
    this.createForm.patchValue(newData);
  }

  openDeleteDialog(id: number, type: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.userStatus(id, type);
      }
    });
  }

  userStatus(id: number, type: number): void {
    this.apollo
      .mutate({
        mutation: USER_TYPE_STATUS,
        variables: {
          usertypeId: id,
          status: type,
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next: () => {
          this.fetchUserType();
          if(type === 2 ){
            this.toastr.success(MESSAGES.DATA_DELETED)
          }
          else {
            this.toastr.success(MESSAGES.SATUS_CHANGE_SUCCESS)
          }
        }
      });
  }
}
