import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { GET_ORDER, UPDATE_ORDER } from '../helper/queries';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES, VALIDATION_MESSAGES } from '../helper/messages';
import { ORDER_STATUS_TYPE, currencyType } from '../helper/constant';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class OrderDetailsComponent {
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = ['partNumber', 'partName', 'quantity', 'price']; // tables colums

  dataSource = new MatTableDataSource(); // table source

  paymentDate: string = '';
  paymentAmount: number = 0;
  paymentStatus: string = '';
  paymentMethod: string = '';
  paymentInvoice: string = '';

  invoiceValid!: boolean;
  orderNo: string = '';
  purchasedBy: string = '';
  shippingAddress: any = '';
  orderStatus: string = '';
  orderStatusOptions: string[] = Object.values(ORDER_STATUS_TYPE);
  ORDERID!: number;

  constructor(
    private readonly apollo: Apollo,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const orderID = params['id'];
      this.ORDERID=parseInt(orderID)
      this.getOrder(parseInt(orderID));
    });
  }
  onOrderStatusChange(event: Event){
    const newStatus = (event.target as HTMLSelectElement).value;
    this.orderStatus = newStatus;
    console.log(newStatus);

    this.apollo
    .mutate({
      mutation: UPDATE_ORDER,
      variables:{
        orderId:this.ORDERID,
        updateOrders: {
          orderStatus:newStatus
        }
      },
      fetchPolicy: 'no-cache',
    }).subscribe({
      next: ()=>{
        this.toastr.success(MESSAGES.PART_UPDATED)
      }
    })
    
  }
  openInvoice(): void {
    if (this.paymentInvoice) {
      if(this.paymentInvoice.startsWith('http') || this.paymentInvoice.startsWith('http')){
        window.open(this.paymentInvoice, '_blank');
      }else{
        this.toastr.error(MESSAGES.INVALID_URL);
      }
     
    } else {
      this.toastr.error(VALIDATION_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }

  getOrder(id: number) {
    this.apollo
      .watchQuery({
        query: GET_ORDER,
        variables: {
          orderId: id,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.dataSource = data?.getOneOrder?.orderItems?.map((resp: any) => ({
          id: resp?.id,
          partNumber: resp?.partsData?.partNumber,
          partName: resp?.partsData?.partName,
          quantity: resp?.quantity,
          price: currencyType + ' ' + resp?.price ?? 0,
        }));
        
        this.orderNo = data?.getOneOrder?.orderNo;
        this.purchasedBy = data?.getOneOrder?.userData?.username;
        this.shippingAddress = data?.getOneOrder?.addressData;
        this.orderStatus = data?.getOneOrder?.orderStatus;

        this.paymentDate = data?.getOneOrder?.paymentData?.paymentDate;
        this.paymentAmount = data?.getOneOrder?.paymentData?.paymentAmount;
        this.paymentStatus = data?.getOneOrder?.paymentData?.paymentStatus;
        
        this.paymentMethod = data?.getOneOrder?.paymentData?.paymentMethod;
        this.paymentInvoice = data?.getOneOrder?.paymentData?.invoice;
        
      });
  }
  navigateBack() {
    this.router.navigate(['/order-management'])
  }

  isPaymentInvoiceValid(): boolean {
    if (this.paymentInvoice && this.paymentInvoice.startsWith('http')) {
      return true;
    } else {
      return false;
    }
  }
  
}
