<!-- <div class="main_wrapper">
    <div class="quote-container">
        <div (click)="back()"><button type="submit"> Back </button></div>
        <div class="main-wrapper">
            <h1>Request <span class="highlight">Quote</span></h1>
            <div class="product-info">
                <div class="product-details">
                    <div class="details-set">
                        <h2>Product Name:</h2>
                        <p>{{ productEnguiryData?.partData?.partName }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Product Number:</h2>
                        <p>{{ productEnguiryData?.partData?.partNumber }}</p>
                    </div>
                    <div class="details-set">
                        <h2>Description:</h2>
                        <p> {{ productEnguiryData?.partData?.partDesc }}</p>
                    </div>
                    <div class="details-set">
                        <h2>Product Code:</h2>
                        <p> {{ productEnguiryData?.partData?.productCode }}</p>
                    </div>
                    <div class="details-set">
                        <h2>Variants:</h2>
                        <p>{{ productEnguiryData?.partData?.variants ? productEnguiryData?.partData?.variants : 'Nill'  }} </p>
                    </div>
                </div>
                <div class="product-image">
                    <img [src]="productImg" class="img-cls" alt="Engine Image">
                </div>
            </div>
            <div class="product-additional-wrapper">
                <div class="make-details">
                    <div class="details-set">
                        <h2>Make</h2>
                        <h3> {{ productEnguiryData?.make }} </h3>
                    </div>
                    <div class="details-set">
                        <h2>Model</h2>
                        <h3>{{ productEnguiryData?.model }}</h3>
                    </div>
                    <div class="details-set">
                        <h2>Year</h2>
                        <h3> {{ productEnguiryData?.year}}</h3>
                    </div>
                    <div class="details-set">
                        <h2>Engine</h2>
                        <h3>{{ productEnguiryData?.engine}}</h3>
                    </div>
                </div>
                <hr>
                <h4>Additional Info:</h4>
                <p>{{ productEnguiryData?.otherInformation}}</p>
            </div>
            <hr>
            <div class="product-options">
                <h4>Search Product Varients</h4>
                <form [formGroup]="searchForm">
                    <div class="form-group">    
                        <div class="cross">
                            <input type="text" placeholder="Search Item" class="search-form"  formControlName="searchInput">
                            <div (click)="resetFormState()" class="cross-btn" *ngIf="searchForm.valid">X</div>
                        </div>        
                        <button (click)="searchForParts()" type="button" class="submit-btn" [disabled]="searchForm.invalid" >
                            <img src="../../assets/images/search-light.svg">
                        </button>
                    </div>
                      <div *ngFor="let part of searchResult">
                        <mat-radio-button (change)="onPartSelected(part)">
                          {{ part.partName }} ( Varient: {{ part.variants }} )
                        </mat-radio-button>
                      </div>
                      
                </form >
                <div *ngIf="isSearch && searchForm.get('searchInput')?.value" >
                    <button 
                    type="button" 
                    class="add-part" 
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal" 
                    (click)="populateFormWithSelectedPart()" 
                    #openModal 
                    [disabled]="searchForm.get('searchInput')?.invalid"
                     >
                        {{ isEditMode ? 'EDIT PARTS AND SEND MAIL' : 'ADD PARTS AND SEND MAIL' }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="createForm" (ngSubmit)="createInventory()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>{{ isEditMode ? 'EDIT PARTS' : 'ADD PARTS' }}</h2>
                                <div class="formFieldMainAll">
                                    <span>Product Sku</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Product Sku" formControlName="productSku">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Part Number</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Part Number" formControlName="partNumber">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Part Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Part Name" formControlName="partName">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Part Description</span>
                                    <mat-form-field class="example-full-width">
                                        <textarea matInput placeholder="Part Description"
                                            formControlName="partDesc"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Manufacture</span>
                                    <ng-select [items]="manufacturersDropDown"
                                    bindLabel="mfgName"
                                    bindValue="mfgName"
                                    [virtualScroll]="true"
                                    formControlName="mfgName"
                                    [placeholder]="'Select Manufacture'"
                                    [clearable]="true"
                                    ></ng-select>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Quantity</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Quantity" formControlName="quantity" >
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Variant</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Varient" formControlName="varient">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Seller Price</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Seller Price" formControlName="sellerPrice">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Reseller Price</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Reseller Price" formControlName="resellerPrice">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll custom-fileinput">
                                    <span>Part Image</span>
                                    <input 
                                      [hidden]="true" 
                                      type="file" 
                                      (change)="onFileChange($event)"
                                      class="form-control" 
                                      placeholder="Please upload a photo here"  
                                      #fileSelect
                                      multiple>
                                    
                                    <input 
                                      readonly 
                                      style="cursor: pointer" 
                                      placeholder="Please upload a photo here"
                                      formControlName="partLabel" 
                                      (click)="fileSelect.click()" 
                                      class="form-control" />
                                    <div class="inputFileIcon" (click)="fileSelect.click();">
                                      <img src="/assets/images/clip.svg" alt="icons">
                                    </div>
                                    
                                   
                                    <div *ngIf="isUploading" class="loader-container">
                                      <p>Uploading..</p>
                                    </div>
                                  
                                    <div class="file-name-preview-container">
                                      <div *ngFor="let image of imagePreviews" class="file-name-item">
                                        <span>{{ image.fileName }}</span>
                                        <button (click)="removeImage(image)" class="remove-button">X</button>
                                      </div>
                                    </div>
                                  </div>
                                <div class="formFieldMainAll">
                                    <span>Vehicle</span>
                                    <ng-select [items]="vehiclesDropDown"
                                    bindLabel="combinedLabel"
                                    bindValue="id"
                                    [virtualScroll]="true"
                                    formControlName="vehicleId"
                                    [placeholder]="'Select Vehicle'"
                                    [clearable]="true"
                                    >

                                    </ng-select>
                                </div>

                                <div class="formFieldMainAll">
                                    <span>Category</span>
                                    <ng-select [items]="categoriesDropDown" 
                                             bindLabel="categoryName" 
                                            bindValue="categoryId"
                                            [virtualScroll]="true" 
                                            formControlName="categoryId"
                                            (change)="listSubCatOfCategory($event)" 
                                            [placeholder]="'Select category'"
                                             [clearable]="true">
                                    </ng-select>
                                </div>

                                <div class="formFieldMainAll">
                                    <span>SubCategory</span>
                                    <ng-select 
                                    [items]="subcategoriesDropDown" 
                                    bindLabel="subCatName" 
                                    bindValue="id"
                                    [virtualScroll]="true" 
                                    formControlName="subCatId"
                                    [placeholder]="'Select SubCategory'"
                                    [clearable]="true">
                                </ng-select>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Stock Status</span>
                                    <mat-form-field class="example-full-width">
                                        <mat-select placeholder="Select Stock Status" formControlName="stockStatus">
                                            <mat-option value="1">In Stock</mat-option>
                                            <mat-option value="0">Out of Stock</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Status</span>
                                    <mat-form-field class="example-full-width">
                                        <mat-select placeholder="Select Stock Status" formControlName="status">
                                            <mat-option value="1">Active</mat-option>
                                            <mat-option value="0">Inactive</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend"
                                        [disabled]="createForm.invalid">SUBMIT AND SEND MAIL</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

 <section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <button class="backBtn" type="button" (click)="back()"> < Back</button>
    </div>
    <div class="order-details-wrapper">
        <div class="top-box">

            <div class="right-set">
                <div class="summary-wrapper">
                    <div class="head">
                        <h2>Enquiry Details</h2>
                    </div>
                    <div class="content">
                        <div class="value-box">
                            <h3>Part Name :</h3>
                            <h4>{{ productEnguiryData?.partData?.partName  || "___" }} </h4>
                        </div>
                        <div class="value-box">
                            <h3>Part Number :</h3>
                            <h4>{{ productEnguiryData?.partData?.partNumber || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Part Description :</h3>
                            <h4>{{ productEnguiryData?.partData?.partDesc || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Product Code :</h3>
                            <h4>{{ productEnguiryData?.partData?.productCode  || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Variants :</h3>
                            <h4>{{ productEnguiryData?.partData?.variants ? productEnguiryData?.partData?.variants : 'Nill' }}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Make :</h3>
                            <h4>{{ productEnguiryData?.make   || "___" }} </h4>
                        </div>
                        <div class="value-box">
                            <h3>Model :</h3>
                            <h4>{{ productEnguiryData?.model || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Year :</h3>
                            <h4>{{ productEnguiryData?.year || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Engine :</h3>
                            <h4>{{ productEnguiryData?.engine  || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Other Information :</h3>
                            <h4>{{ productEnguiryData?.otherInformation || "___" }}</h4>
                        </div>
                        <div class="value-box" >
                            <h3>Product Images :</h3>
                            <img [src]="productImg" class="img-cls" alt="Engine Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-box">
            <div class="right-set">
                <div class="summary-wrapper">
                    <div class="head">
                        <h2>Search Product Varients </h2>
                    </div>
                    <div class="content">
                        <form [formGroup]="searchForm">
                            <div class="form-group">    
                                <div class="cross">
                                    <input type="text" placeholder="Search Item" class="search-form"  formControlName="searchInput">
                                    <div (click)="resetFormState()" class="cross-btn" *ngIf="searchForm.valid">X</div>
                                </div>        
                                <button (click)="searchForParts()" type="button" class="submit-btn" [disabled]="searchForm.invalid" >
                                    <img src="../../assets/images/search-light.svg">
                                </button>
                            </div>
                              <div *ngFor="let part of searchResult">
                                <mat-radio-button (change)="onPartSelected(part)">
                                  {{ part.partName }} ( Varient: {{ part.variants }} )
                                </mat-radio-button>
                              </div>
                              
                        </form >
                        <div *ngIf="isSearch && searchForm.get('searchInput')?.value" >
                            <button 
                            type="button" 
                            class="add-part" 
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal" 
                            (click)="populateFormWithSelectedPart()" 
                            #openModal 
                            [disabled]="searchForm.get('searchInput')?.invalid"
                             >
                                {{ isEditMode ? 'EDIT PARTS AND SEND MAIL' : 'ADD PARTS AND SEND MAIL' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

