import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { debounceTime } from 'rxjs';
import { timeDelay } from '../helper/constant';
import { PageEvent } from '@angular/material/paginator';
import { LIST_CONTACT, LIST_ENGINE } from '../helper/queries';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss', '../users/users.component.scss']
})
export class ContactUsComponent {
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'phoneNumber', 'message']; // tables colums

  dataSource = new MatTableDataSource(); // table source

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  constructor(private readonly apollo: Apollo) {}

  ngOnInit() {
    this.listContacts();

    // Subscribe to changes in the search FormControl
    // this.search.valueChanges
    //   .pipe(debounceTime(timeDelay.search))
    //   .subscribe((searchTerm) => {
    //     this.listEngine(searchTerm);
    //   });
  }

  listContacts(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_CONTACT,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listContactWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            firstName: resp?.firstName,
            lastName: resp?.lastName,
            email: resp?.emailId,
            phoneNumber: resp?.phoneNumber,
            message: resp?.message
          })
        );
        console.log(this.dataSource);
        
        this.totalItems = data?.listContactWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listContactWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listContactWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listContacts(this.search.value);
  }
}
