<section class="addDatSheetCommonSec">

    <div class="dashboard">
        <div class="card" *ngFor="let card of cards">
            <div class="card-label">{{ card.label }}</div>
            <div class="card-content">
                <div class="card-value">{{ card.value }}</div>
            </div>
        </div>
    </div>

    <div class="dataSheetTable">
        <h1 class="table-header">Low Stock Items</h1>
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- Name Column -->
                <ng-container matColumnDef="partNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        Part Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.partNumber || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="partName">
                    <th mat-header-cell *matHeaderCellDef>
                        Part Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.partName || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="qtyAvail">
                    <th mat-header-cell *matHeaderCellDef>
                        Quantity Available
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.qtyAvail || "0" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="mfgName">
                    <th mat-header-cell *matHeaderCellDef>
                        Manufacturer
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.mfgName || "___"}}
                    </td>
                </ng-container>


                <!-- Symbol Column -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItems">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
    </mat-paginator>

    <div class="dataSheetTable">
        <h1 class="table-header">Orders Placed Today</h1>
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSourceTwo" class="mat-elevation-z8">
                <!-- Name Column -->
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.username || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="orderNo">
                    <th mat-header-cell *matHeaderCellDef>
                        Order No
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.orderNo || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="orderStatus">
                    <th mat-header-cell *matHeaderCellDef>
                        Order Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.orderStatus || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="paymentStatus">
                    <th mat-header-cell *matHeaderCellDef>
                        Payment Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.paymentStatus || "___"}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef>
                        Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.totalAmount || "___"}}
                    </td>
                </ng-container>

                <!-- Symbol Column -->
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItemsTwo">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItemsTwo" [length]="totalItemsTwo" [pageSize]="itemsPerPageTwo"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChangeTwo($event)">
    </mat-paginator>

</section>