export const VALIDATION_MESSAGES = {
  SOMETHING_WENT_WRONG: 'SOMETHING WENT WRONG. Please try again later.',
  EMAIL_REQUIRED: 'Email is required.',
  EMAIL_INVALID: 'Invalid email format.',
  PASSWORD_REQUIRED: 'Password is required.',
  PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters long.',
  CONFIRM_PASSWORD_REQUIRED: 'Password is required.',
  CONFIRM_PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters long.',
};

export const MESSAGES = {
  SOMETHING_WENT_WRONG: 'SOMETHING WENT WRONG. Please try again later.',
  TOKEN_EXPIRED: 'Your session has timed out. Please log in again to continue.',
  LOGIN_SUCCESS: 'You have logged in successfully',
  PASSWORD_MISMATCH: 'Passwords must match...',
  PASSWORD_UPDATE_SUCCESS: 'Your password has been successfully updated!',
  PASSWORD_RESET_LINK_SENT: 'Password reset link sent to your email.',
  PASSWORD_RESET_LINK_FAILED:
    'Failed to send password reset link. Please try again.',
  PROFILE_UPDATE: 'Your profile has been successfully updated!',
  PASSWORD_CHANGE_SUCCESSFUL: 'Password changed successfully.',
  PASSWORD_CHANGE_FAILED: 'Failed to change password. Please try again.',
  PERMISSION_FETCH_ERROR: 'There was an error fetching your permissions. Please try again later.',
  NEW_PART_ADDED: 'Added successfully',
  ERROR_ADDING_NEWPART: 'Error while adding new parts',
  PART_UPDATED: 'Updated successfully',
  ERROR_UPDATING_PART: 'Error while updating parts',
  SATUS_CHANGE_SUCCESS: 'Status changed',
  SATUS_CHANGE_FAILED: 'Failed to change status',
  DATA_DELETED: 'Deleted successfully',
  BULK_UPLOAD_SUCCESS_MESSAGE: 'Bulk upload completed successfully',
  INVALID_URL:'Invoice not generated'
};
