import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { CHANGE_PASSWORD, PROFILE, UPDATE_USER } from '../helper/queries';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '../helper/messages';
import { FireImgUploadService } from '../service/imageupload/fire-img-upload.service';
import { selectMyProfile } from '../reducers/permissions.selectors';
import { Store, select } from '@ngrx/store';
import { Observable, max } from 'rxjs';
import { setMyProfile } from '../reducers/permissions.actions';



@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent {
  selected = '';

  @Input() loader:string='https://media.tenor.com/images/f864cbf3ea7916572605edd3b3fe637f/tenor.gif';
  @Input() height:number=150;
  @Input() width:number=150;
  @Input() picUrl!:string;
  isLoading: boolean = true;

  profileForm!: FormGroup;

  passwordForm!: FormGroup;
  passwordSubmit: boolean | undefined;

  // picUrl: any;

  myProfile$: Observable<any>;
  constructor(
    private formBuilder: FormBuilder,
    private readonly apollo: Apollo,
    private toastr: ToastrService,
    private imageUploadService: FireImgUploadService,
    private store: Store,
  ) {
    this.myProfile$ = this.store.pipe(select(selectMyProfile));
    // this.isLoading=true;
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.profileForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      roleId: [''],
      profileUpload: [''],
      profileLabel: [''],
    });

    this.fetchProfile();
  }

  hideLoader(){
    this.isLoading=false;
    console.log('loading');
    
  }

  ChangePassword() {
    if (this.passwordForm.valid) {
      const value = this.passwordForm.value;
      console.log(value);
      if (value?.newPassword !== value?.confirmPassword) {
        this.toastr.error(MESSAGES.PASSWORD_MISMATCH);
      } else {
        this.apollo
          .mutate({
            mutation: CHANGE_PASSWORD,
            variables: {
              currentPassword: value?.currentPassword,
              newPassword: value?.newPassword,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe(() => {
            this.passwordForm.reset();
            this.toastr.success(MESSAGES.PASSWORD_UPDATE_SUCCESS);
          });
      }
    }
  }

  fetchProfile() {
    this.myProfile$?.subscribe((profile) => {
      const respData = profile;
      const fileName = this.imageUploadService.getFileName(
        respData?.profilePicUrl
      );
      this.picUrl = respData?.profilePicUrl;
      const newData = {
        id: respData?.id,
        username: respData?.username,
        email: respData?.email,
        phone: respData?.phone,
        roleId: respData?.roleId,
        profileLabel: fileName,
      };
      this.profileForm.patchValue(newData);
    });
  }

  refetchProfile() {
    this.apollo
      .watchQuery({
        query: PROFILE,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.store.dispatch(setMyProfile({ myProfile: data?.myProfile }));
      });
  }

  profileUpdate() {
    if (this.profileForm.valid) {
      const value = this.profileForm.value;
      const createUserData = {
        firstName: value?.username,
        // lastName: value?.lastName,
        phone: value?.phone,
        roleId: value?.roleId,
        profilePicUrl: this.picUrl,
      };
      this.apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: value?.id,
            updateUsers: createUserData,
          },
          fetchPolicy: 'no-cache',
        })
        .subscribe(() => {
          this.refetchProfile()
          this.toastr.success(MESSAGES.PROFILE_UPDATE);
        });
    }
  }


  onFileSelected(event: any) {
    const fileObj = event.target.files[0];
    this.isLoading = true;
    this.picUrl = '';

    this.imageUploadService
    .uploadImage(fileObj)
    .then((downloadURL) => {
      const img = new Image();
      img.src = downloadURL;
  
      img.onload = () => {
          this.picUrl = downloadURL;
          this.hideLoader();
      };
    }).catch((error) => {
      console.error('Error uploading image:', error);
      this.hideLoader();
    });
  }

  onFileChange(event: any) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    this.onFileSelected(event);
    const fileName = event.target.files[0].name;
    this.profileForm?.get('profileLabel')?.setValue(fileName);
  }
}
