<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for subcategory..." [formControl]="search" class="example-input">
            </form>
        </div>
        <button type="button" class="primaryColotBtn" data-bs-toggle="modal"
        data-bs-target="#filterModal">FILTER</button>
        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()" #openModal>ADD
            Sub Category</button>

    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                <!-- Position Column -->


                <ng-container matColumnDef="subCatId">
                    <th mat-header-cell *matHeaderCellDef>
                        Sub Category Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.subCatId || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef>
                       Image
                    </th>
                    <td mat-cell *matCellDef="let element"
                    data-bs-toggle="modal"
                    data-bs-target="#imgModal"
                    (click)="popupDataImg(element?.imagePopupData)"
                    > 
                    <img *ngIf="element.subCatImage" [src]="imagePath" alt="">
                    <span *ngIf="!element.subCatImage">___</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="categoryName">
                    <th mat-header-cell *matHeaderCellDef>

                        Category Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.categoryName || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="subCatName">
                    <th mat-header-cell *matHeaderCellDef>
                        Sub Category Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.subCatName || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dropMenuMain">
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="setFormData(element)">Edit</button>
                                <button mat-menu-item (click)="deleteSubcategory(element)" >Delete</button>
                            </mat-menu>

                            <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                                    fill="black" />
                                <path
                                    d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                                    fill="black" />
                                <path
                                    d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                                    fill="black" />
                            </svg>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItems">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
    </mat-paginator>
</section>


<!-- create pop Up -->
<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="createForm" (ngSubmit)="createSubCategory()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>ADD SUBCATEGORY</h2>
                                <div class="formFieldMainAll">
                                    <span>SubCategory Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="SubCategory Name" formControlName="subCatName">
                                    </mat-form-field>
                                </div>

                                <div class="formFieldMainAll custom-fileinput">
                                    <span>SubCategory Image</span>
                                    <input [hidden]="true" type="file" (change)="onFileChange($event)"
                                        class="form-control" placeholder="Please upload a photo here" #fileSelect>
                                    <input readonly style="cursor: pointer" placeholder="Please upload a photo here"
                                        formControlName="subCategoryLabel" (click)="fileSelect.click()"
                                        class="form-control" />
                                    <div class="inputFileIcon" (click)="fileSelect.click();">
                                        <img src="/assets/images/clip.svg" alt="icons">
                                    </div>
                                       <!-- Loader Display -->
                                       <div *ngIf="isUploading" class="loader-container">
                                        <p>Uploading..</p>
                                    </div>
                                </div>

                                <div class="formFieldMainAll">
                                    <span>Category</span>
                                    <ng-select [items]="categoriesDropDown" 
                                             bindLabel="categoryName" 
                                            bindValue="categoryId"
                                            [virtualScroll]="true" 
                                            formControlName="categoryId" 
                                            [placeholder]="'Select category'"
                                             [clearable]="true">
                                    </ng-select>
                                </div>


                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend"
                                        [disabled]="createForm.invalid">SUBMIT</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- img pop up -->

<div class="usersPopupdiv">
    <div class="modal fade" id="imgModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
                        #closeModalFilter>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <div class="col-sm-12 imge">
                            <div
                              class="loadImageMain"
                              *ngIf="isLoading"
                            >
                              <img class="loadImage" [src]="loader" />
                            </div>
                            
                            <div 
                            >
                            <img
                            [src]="imagePopupData"
                            alt="img"
                            [ngStyle]="{height: height + 'px', width: width + 'px'}"
                            (load)="hideLoader()"
                          />
                            </div>

                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- filter popup -->

<div class="usersPopupdiv">
    <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close"
                        #closeModalButtonFilter>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="filterForm" (ngSubmit)="onApplyClick()" >
                            <div class="col-sm-12 spaceMainall">
                                <h2>FILTER</h2>
                                <div class="formFieldMainAll">
                                    <span>Category</span>
                                    <ng-select [items]="categoriesDropDown" 
                                             bindLabel="categoryName" 
                                            bindValue="categoryId"
                                            [virtualScroll]="true" 
                                            formControlName="category"
                                            [placeholder]="'Select category'"
                                             [clearable]="true">
                                    </ng-select>
                                </div>
                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend" >APPLY</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>