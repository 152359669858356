<section class="permission-main-wrapper">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let userType of usertypes; let i = index" [expanded]="i === 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="modeleTitle">
                        {{userType?.roleName}}
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="modeleinnerTitle">Permissions</p>
            <div *ngFor="let permission of permissionsList">
                <div class="head">
                    <h2>{{permission?.moduleName}}</h2>
                    <label class="switch">
                        <input type="checkbox" [checked]="isCheckedHead(userType?.id , permission?.subFunctions)"
                            (change)="toggleHeadChecked($event,userType?.id, permission?.moduleId)">
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="permission-box">
                    <div class="inner-box" *ngFor="let subFunction of permission?.subFunctions">
                        <div class="inner-head">
                            <h2>{{subFunction?.functionName}}</h2>
                            <label class="switch">
                                <input type="checkbox"
                                    [checked]="isChecked(userType?.id, permission?.moduleId, subFunction?.id)"
                                    (change)="toggleChecked($event, userType?.id, permission?.moduleId, subFunction?.id)">
                                >
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>