import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import {
  ADD_SUBCATEGORIES,
  CATEGORY_LIST,
  LIST_CATEGORIES,
  LIST_SUB_CATEGORIES,
  UPDATE_SUBCATEGORIES,
  UPDATE_SUBCATEGORY_STATUS,
} from '../helper/queries';
import { debounceTime } from 'rxjs';
import { timeDelay } from '../helper/constant';
import { PageEvent } from '@angular/material/paginator';
import { FireImgUploadService } from '../service/imageupload/fire-img-upload.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '../helper/messages';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class SubCategoryComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model
  @ViewChild('closeModalFilter') closeModalFilter: any;
  @ViewChild('closeModalButtonFilter') closeModalButtonFilter: any;

  search = new FormControl('');
  displayedColumns: string[] = [
    'categoryName',
    'subCatName',
    'image',
    'action',
  ]; // tables colums

  dataSource = new MatTableDataSource(); // table source

  createForm!: FormGroup; // user create
  filterForm!: FormGroup;
  categoriesDropDown: any;
  selectedCategoryId: any;
  subcategoryImageEdit: any;

  createSubmit: boolean | undefined;

  @Input() loader: string =
    'https://media.tenor.com/images/f864cbf3ea7916572605edd3b3fe637f/tenor.gif';
  @Input() height: number = 200;
  @Input() width: number = 200;
  @Input() imagePopupData: any;
  isLoading: boolean = true;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  imagePath!: string;
  DELETE_STATUS: number = 2;

  categoryDropDown: any;
  subcategoryImage: any = null;
  isEditMode: boolean = false;
  isUploading: boolean = false;

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }

  closeModel(): void {
    this.subcategoryImage=null;
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private imageUploadService: FireImgUploadService,
    private toastr: ToastrService
  ) {
    // this.isLoading=true;
  }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: [''],
      // subCatId: ['', [Validators.required]],
      subCatName: ['', [Validators.required]],
      subCategoryLabel: [''],
      categoryId: ['', [Validators.required]],
    });

    this.listSubCategories();
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listSubCategories(searchTerm, this.selectedCategoryId);
      });

    this.filterForm = this.formBuilder.group({
      category: [null],
    });
    //listCategories for dropdown
    this.categoriesList();
    // this.listCategories();
    this.imagePath = '../../assets/images/image-icon-black.svg';
  }
  hideLoader() {
    this.isLoading = false;
  }

  listSubCategories(
    searchTerm: string | null = '',
    categoryId: string | null = ''
  ) {
    this.apollo
      .watchQuery({
        query: LIST_SUB_CATEGORIES,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
          filter: {
            categoryId: categoryId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listSubCategoriesWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            categoryId: resp?.categoryId,
            subCatName: resp?.subCatName,
            subCatImage: resp?.subCatImage,
            categoryName: resp?.categoryData?.categoryName,
            imagePopupData: resp?.subCatImage,
          })
        );

        this.totalItems =
          data?.listSubCategoriesWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listSubCategoriesWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listSubCategoriesWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listSubCategories(this.search.value, this.selectedCategoryId);
  }

  listCategories(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_CATEGORIES,
        variables: {
          page: 1,
          limit: 10,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.categoryDropDown = data?.listCategoriesWithPagination?.items;
      });
  }

  deleteSubcategory(data: any): void {
    this.apollo
      .watchQuery({
        query: UPDATE_SUBCATEGORY_STATUS,
        variables: {
          subCategoryId: data.id,
          status: this.DELETE_STATUS,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe({
        next: () => {
          this.listSubCategories();
          this.toastr.success(MESSAGES.DATA_DELETED);
        },
        error: (errors)=>{
          this.toastr.error(errors.message)
        }
      });
  }
  categoriesList() {
    this.apollo
      .watchQuery({
        query: CATEGORY_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.categoriesDropDown = data?.listCategories;
      });
  }

  onFileChange(event: any) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    this.isUploading = true; // Show loader
    this.onFileSelected(event);
    const fileName = event.target.files[0].name;
    this.createForm?.get('subCategoryLabel')?.setValue(fileName);
  }

  onFileSelected(event: any) {
    const fileObj = event.target.files[0];
    this.imageUploadService
      .uploadImage(fileObj)
      .then((downloadURL) => {
        this.subcategoryImage = downloadURL;
        this.subcategoryImageEdit= downloadURL;
        this.isUploading = false; // Hide loader after upload
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        this.isUploading = false; // Hide loader after upload
      });
  }

  createSubCategory(): void {
    if (this.createForm.valid) {
      const value = this.createForm.value;
      console.log(value);

      const data = {
        // subCatId: value?.subCatId,
        subCatName: value?.subCatName,
        subCatImage: this.subcategoryImage,
        categoryId: value?.categoryId?.toString(),
      };

      const editData = {
        subCatName: value?.subCatName,
        subCatImage: this.subcategoryImageEdit,
        categoryId: value?.categoryId?.toString(),
      }
      if (value?.id) {
        this.apollo
          .mutate({
            mutation: UPDATE_SUBCATEGORIES,
            variables: {
              id: value?.id,
              updateSubCategoryInput: editData,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: () => {
              this.listSubCategories();
              this.toastr.success(MESSAGES.PART_UPDATED);
              this.listCategories(this.search.value);
              this.closeModel();
            },
            error: (errors)=>{
              this.toastr.error(errors.message, "Error")
            }
          });
      } else {
        this.apollo
          .mutate({
            mutation: ADD_SUBCATEGORIES,
            variables: {
              createSubCategoryInput: data,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: () => {
              this.listSubCategories();
              this.toastr.success(MESSAGES.NEW_PART_ADDED);
              this.listCategories(this.search.value);
              this.closeModel();
            },
            error: (errors: any) => {
              this.toastr.error(errors.message);
            }
          });
      }
    }
  }
  

  setFormData(data: any): void {
    const subcategoryImage = this.imageUploadService.getFileName(
      data?.subCatImage
    );
    this.subcategoryImageEdit=data?.subCatImage;
    
    const newData = {
      id: data?.id,
      // subCatId: data?.subCatId,
      subCatName: data?.subCatName,
      subCatImage: data?.subCatImage,
      categoryId: data?.categoryId,
      subCategoryLabel: subcategoryImage,
    };
    this.openModel();
    this.isEditMode = true;
    this.createForm.patchValue(newData);
  }
  popupDataImg(img: string): void {
    this.imagePopupData = img;
  }

  onSelectionOfCategoryId(catId: string) {
    this.listSubCategories(this.search.value, catId);
  }

  onApplyClick() {
    this.selectedCategoryId = this.filterForm.get('category')?.value;
    this.onSelectionOfCategoryId(this.selectedCategoryId);
    this.closeModalButtonFilter.nativeElement.click();
  }
}
