<section class="addDatSheetCommonSec">

    <!-- <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for order details..." [formControl]="search"
                    class="example-input">
            </form>
        </div>
        <button type="button" >
            INVOICE
        </button>
    </div> -->
    <div class="serachMainAllWrapper">
        <button class="backBtn" type="button" (click)="back()"> < Back</button>
    </div>
    <div class="order-details-wrapper">
        <div class="top-box">

            <div class="right-set">
                <div class="summary-wrapper">
                    <div class="head">
                        <h2>User Details</h2>
                    </div>
                    <div class="content">
                        <div class="value-box">
                            <h3>Name :</h3>
                            <h4>{{userData?.name || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Email :</h3>
                            <h4>{{userData?.email || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Address :</h3>
                            <h4>{{userData?.address || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Phone Number :</h3>
                            <h4>{{userData?.phoneNumber || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Status :</h3>
                            <h4>{{userData?.status === 1 ? ACTIVE : INACTIVE }}</h4>
                        </div>
                        <div class="value-box" *ngIf="userType !== CUSTOMER">
                            <h3>Registration Number :</h3>
                            <h4>{{userData?.number || "___"}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-set" *ngIf="userType !== CUSTOMER">
                <div class="summary-wrapper">
                    <div class="head">
                        <h2>Subscription Plans</h2>
                    </div>
                    <div class="content">
                        <div class="value-box">
                            <h3>Plan :</h3>
                            <h4>{{userData?.planData?.name || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Start Date :</h3>
                            <h4>{{userData?.planData?.startDate || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Expiry date :</h3>
                            <h4>{{userData?.planData?.endDate || "___"}}</h4>
                        </div>
                        <div class="value-box">
                            <h3>Status :</h3>
                            <h4
                                [ngClass]="{'active-status': userData?.planData?.status === 'active', 'default-status': userData?.planData?.status !== 'active'}">
                                {{ userData?.planData?.status || '___' }}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-set">
            <div class="dataSheetTable">
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                        <!-- Position Column -->
                        <ng-container matColumnDef="orderNo">
                            <th mat-header-cell *matHeaderCellDef>
                                Order No
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.orderNo || "___"}} </td>
                        </ng-container>
                        <ng-container matColumnDef="orderDate">
                            <th mat-header-cell *matHeaderCellDef>
                                Order Date
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.orderDate || "___"}} </td>
                        </ng-container>
                        <ng-container matColumnDef="orderStatus">
                            <th mat-header-cell *matHeaderCellDef>
                                Order Status
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.orderStatus || "___"}} </td>
                        </ng-container>
                        <ng-container matColumnDef="paymentStatus">
                            <th mat-header-cell *matHeaderCellDef>
                                Payment Status
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.paymentStatus || "___"}} </td>
                        </ng-container>
                        <ng-container matColumnDef="totalAmount">
                            <th mat-header-cell *matHeaderCellDef>
                                Total Amount
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element?.totalAmount || "___"}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

</section>