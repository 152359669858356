import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { LIST_TRANSACTIONS } from '../helper/queries';
import { timeDelay } from '../helper/constant';
import { debounceTime } from 'rxjs';
import * as XLSX from 'xlsx';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-payment-transaction',
  templateUrl: './payment-transaction.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class PaymentTransactionComponent {
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = [
    'paymentId',
    'name',
    'email',
    'transactionType',
    'transactionDate',
    'transactionAmount',
    'transactionStatus',
  ]; // tables colums


  dataSource = new MatTableDataSource(); // table source

  createForm!: FormGroup; // user create
  createSubmit: boolean | undefined;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: [''],
      roleName: ['', [Validators.required]],
      isEditable: ['', [Validators.required]],
    });

    this.listTransactions();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listTransactions(searchTerm);
      });
  }

  listTransactions(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_TRANSACTIONS,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listTransactionsWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            name: resp?.paymentData?.orderData?.userData?.username,
            email: resp?.paymentData?.orderData?.userData?.email,
            paymentId: resp?.paymentId,
            transactionType: resp.transactionType,
            transactionDate: resp?.transactionDate,
            transactionAmount: resp?.transactionAmount,
            transactionStatus: resp?.transactionStatus,
          })
        );

        this.totalItems =
          data?.listTransactionsWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listTransactionsWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listTransactionsWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listTransactions(this.search.value);
  }

  exportexcel(): void {
    const fileName = 'transactionsSheet.xlsx';
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }
}
