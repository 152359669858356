<div class="main_wrapper">
    <div class="quote-container">
        <div (click)="back()"><button class="backButton" type="submit"> Back </button></div>
        <div class="main-wrapper">
            <h1>Part <span class="highlight">Details</span></h1>
            <div class="product-info">
                <div class="product-details">
                    
                    <div class="details-set">
                        <h2>Product SKU:</h2>
                        <p>{{ inventoryEnguiryData?.productSku }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Product Number:</h2>
                        <p>{{ inventoryEnguiryData?.partNumber }}</p>
                    </div>
                    <div class="details-set">
                        <h2>Product Name:</h2>
                        <p>{{ inventoryEnguiryData?.partName }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Part Description:</h2>
                        <p>{{ inventoryEnguiryData?.partDesc }} </p>
                    </div>
                    
                    <div class="details-set">
                        <h2>Quantity:</h2>
                        <p>{{ inventoryEnguiryData?.qtyAvail }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Seller Price:</h2>
                        <p>{{ inventoryEnguiryData?.sellerPrice }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Reseller Price:</h2>
                        <p>{{ inventoryEnguiryData?.resellerPrice }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Vehicle</h2>
                        <p>{{ inventoryEnguiryData?.vehicleDetails?.make }} {{ inventoryEnguiryData?.vehicleDetails?.model }}{{ inventoryEnguiryData?.requests?.engine }}</p>
                    </div>
                    <div class="details-set">
                        <h2>Category</h2>
                        <p>{{ inventoryEnguiryData?.categoryId }} </p>
                    </div>
                    <div class="details-set">
                        <h2>SubCategory</h2>
                        <p>{{ inventoryEnguiryData?.subCatDetails?.subCatName }} </p>
                    </div>
                    <div class="details-set">
                        <h2>Stock Status</h2>
                        <p>{{ inventoryEnguiryData?.stockStatus > 0 ?  stock.INSTOCK :  stock.OUTOFSTOCK}} </p>
                    </div>
                    <div class="details-set">
                        <h2>Status</h2>
                        <p>{{ inventoryEnguiryData?.status > 0 ? status.ACTIVE : status.INACTIVE   }} </p>
                    </div>
                </div>
                <!-- <div class="product-image">
                    <img [src]="productImg" class="img-cls" alt="Engine Image">
                </div> -->
                <div class="slider-container">
                    <div class="slider" [style.transform]="getSliderTransform()">
                      <div class="slider-item" *ngFor="let img of productImg">
                        <img [src]="img" alt="Product Image" class="slider-image" />
                      </div>
                    </div>
                  
                    <!-- Navigation buttons -->
                    <button 
                    class="slider-button prev" 
                    (click)="prevImage()" 
                    [ngClass]="{'hidden': productImg.length <= 1}">
                    &#10094;
                  </button>
                  
                  <button 
                    class="slider-button next" 
                    (click)="nextImage()" 
                    [ngClass]="{'hidden': productImg.length <= 1}">
                    &#10095;
                  </button>
                  
                  
            </div>
            <!-- <div class="product-additional-wrapper">
                <div class="make-details">
                    <div class="details-set">
                        <h2>Make</h2>
                        <h3> {{ inventoryEnguiryData?.vehicleDetails?.make }} </h3>
                    </div>
                    <div class="details-set">
                        <h2>Model</h2>
                        <h3>{{ inventoryEnguiryData?.vehicleDetails?.model }}</h3>
                    </div>
                    <div class="details-set">
                        <h2>Year</h2>
                        <h3> {{ inventoryEnguiryData?.vehicleDetails?.year }}</h3>
                    </div>
                    <div class="details-set">
                        <h2>Engine</h2>
                        <h3>{{ inventoryEnguiryData?.requests?.engine }}</h3>
                    </div>
                </div>
            </div> -->
            
        </div>
    </div>
</div>