import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { MESSAGES } from 'src/app/helper/messages';
import { selectPermissions } from 'src/app/reducers/permissions.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  permissions$: Observable<any>;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private store: Store
  ) {
    this.permissions$ = this.store.pipe(select(selectPermissions));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const helper = new JwtHelperService();
    const requiredPermission = route.data['permission'];
    // console.log(requiredPermission);

    const token = localStorage.getItem('token');

    if (token) {
      const isTokenExpired = helper?.isTokenExpired(token);
      if (isTokenExpired) {
        localStorage.clear();
        this.toastr.warning(MESSAGES.TOKEN_EXPIRED);
        this.router.navigate(['/login']);
        return false;
      } else {
        this.permissions$
          .pipe(
            tap((permissions: any) => {
              if (permissions.length === 0) {
                return;
              } else {
                const isPermission = permissions.find(
                  (permission: any) =>
                    permission?.permissionData?.permissionName ===
                    requiredPermission
                );
                if (isPermission) {
                  return true;
                } else {
                  this.router.navigate(['/no-access']);
                  return false;
                }
              }
            })
          )
          .subscribe();
        return true;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
