<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for transactions..." [formControl]="search"
                    class="example-input">
            </form>
        </div>
        <button type="button" (click)="exportexcel()">
            EXPORT
        </button>
    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                <!-- Position Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.name || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>
                        Email
                    </th>
                    <td mat-cell class="lowercase" *matCellDef="let element"> {{element?.email || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="paymentId">
                    <th mat-header-cell *matHeaderCellDef>
                        Transaction No
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.paymentId || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="transactionType">
                    <th mat-header-cell *matHeaderCellDef>
                         Type
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.transactionType || "___"}} </td>
                </ng-container>
                
                <ng-container matColumnDef="transactionDate">
                    <th mat-header-cell *matHeaderCellDef>
                         Date
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.transactionDate || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="transactionAmount">
                    <th mat-header-cell *matHeaderCellDef>
                         Amount
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.transactionAmount || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="transactionStatus">
                    <th mat-header-cell *matHeaderCellDef>
                         Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.transactionStatus || "___"}} </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <div class="no-data-found" *ngIf="!totalItems">
                <img src="../../assets/images/no-data-found.png" alt="noData">
            </div>
        </div>

        <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
        </mat-paginator>



    </div>
</section>