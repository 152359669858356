import { Component, Input, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { debounceTime } from 'rxjs';
import { timeDelay } from '../helper/constant';
import {
  ADD_CATEGORIES,
  LIST_CATEGORIES,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORY_STATUS
} from '../helper/queries';
import { PageEvent } from '@angular/material/paginator';
import { FireImgUploadService } from '../service/imageupload/fire-img-upload.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '../helper/messages';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class CategoryComponent {
  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('openModal') openModal: any; //close Model

  search = new FormControl(''); //  table serch
  displayedColumns: string[] = [
    'categoryId',
    'categoryName',
    'categoryImage',
    'action',
  ]; // tables colums

  dataSource = new MatTableDataSource(); // table source

  createForm!: FormGroup; // user create
  createSubmit: boolean | undefined;
 
  imagePath = '../../assets/images/image-icon-black.svg';
  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  vehiclesDropDown: any;

  categoryImage: any = null;
  categoryImageEdit: any;
  DELETE_STATUS: number = 2;

  isEditMode: boolean = false;

  @Input() loader: string =
    'https://media.tenor.com/images/f864cbf3ea7916572605edd3b3fe637f/tenor.gif';
  @Input() height: number = 200;
  @Input() width: number = 200;
  @Input() imagePopupData: any;
  isLoading: boolean= true;
  isUploading: boolean = false;
  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private imageUploadService: FireImgUploadService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: [''],
      categoryName: ['', [Validators.required]],
      categoryLabel: [''],
    });
    
    this.listCategories();
    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listCategories(searchTerm);
      });
  }
  hideLoader() {
    this.isLoading = false;
  }

  listCategories(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_CATEGORIES,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listCategoriesWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            categoryId: resp?.categoryId,
            categoryImage: resp?.categoryImage,
            catImagePopup: resp?.categoryImage,
            categoryName: resp?.categoryName,
          })
        );

        this.totalItems =
          data?.listCategoriesWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listCategoriesWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listCategoriesWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listCategories(this.search.value);
  }

  openModel(): void {
    this.createForm.reset();
    this.openModal.nativeElement.click();
  }

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  clearForm(): void {
    this.isEditMode = false;
    this.createForm.reset();
  }

  
  onFileChange(event: any) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    this.isUploading = true;
    this.onFileSelected(event);
    const fileName = event.target.files[0].name;
    this.createForm?.get('categoryLabel')?.setValue(fileName);
  }

  onFileSelected(event: any) {
    const fileObj = event.target.files[0];
    this.imageUploadService
      .uploadImage(fileObj)
      .then((downloadURL) => {
        this.categoryImage = downloadURL;
        this.categoryImageEdit= downloadURL;
        this.isUploading = false;
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        this.isUploading = false; // Hide loader after upload
      });
  }

  createCategory(): void {
    if (this.createForm.valid) {
      const value = this.createForm.value;
      if (value?.categoryName) {
        value.categoryName = value.categoryName.trim();
      }
      const data = {
        categoryName: value?.categoryName,
        categoryImage: this.categoryImage,
      };
      const edit = {
        categoryName: value?.categoryName,
        categoryImage: this.categoryImageEdit,
      }
      if (value?.id) {
        this.apollo
          .mutate({
            mutation: UPDATE_CATEGORIES,
            variables: {
              id: value?.id,
              updateCategoryInput: edit,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: () => {
              this.listCategories(this.search.value);
              this.toastr.success(MESSAGES.PART_UPDATED)
              this.categoryImageEdit=null;
              this.resetForm();
              this.closeModel();
            },
            error: (errors: any) => {
              this.toastr.error(errors.message)
            }
          });
      } else {
        this.apollo
          .mutate({
            mutation: ADD_CATEGORIES,
            variables: {
              createCategoryInput: data,
            },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: () => {
              this.listCategories(this.search.value);
              this.toastr.success(MESSAGES.NEW_PART_ADDED);
              this.resetForm();
              this.closeModel();
              // this.resetForm();
            },
            error: (errors: any) => {
              this.toastr.error(errors.message)
            }
          });
      }
    }
  }
  resetForm(): void {
    this.createForm.reset(); // Reset the form
    this.categoryImage = null; // Clear the selected image
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
    fileInput.value = ''; // Clear the file input
  }
    
  }
  deleteCategory(data: any) {
    this.apollo
      .watchQuery({
        query: UPDATE_CATEGORY_STATUS,
        variables: {
          categoryId: data.id,
          status: this.DELETE_STATUS
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe({
        next: () => {
          this.listCategories();
          this.toastr.success(MESSAGES.DATA_DELETED);
        },
        error: (errors: any) => {
          const msg = " It contain subcategories or parts ";
          this.toastr.error(errors.message + msg)
        }
      });
  }

  setFormData(data: any): void {
    const categoryImage = this.imageUploadService.getFileName(
      data?.categoryImage
    );
    this.categoryImageEdit=data?.categoryImage;
    const newData = {
      id: data?.id,
      // categoryId: data?.categoryId,
      vehicleId: data?.vehicleId,
      categoryName: data?.categoryName,
      categoryLabel: categoryImage,
    };
    this.openModel();
    this.isEditMode = true;
    this.createForm.patchValue(newData);
  }
  popupDataImg(img: string): void {
    this.imagePopupData = img;
  }
}
