<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for roles..." [formControl]="search" class="example-input">
            </form>
        </div>
        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()" #openModal>ADD
            ROLE</button>
    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- Position Column -->
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>
                        Role
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.role || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="editable">
                    <th mat-header-cell *matHeaderCellDef>
                        Editable
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.editable ? YES : NO || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.status === 1 ? ACTIVE : INACTIVE || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dropMenuMain" *ngIf="element?.editable">
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="updateUserType(element)">Edit</button>
                                <button mat-menu-item (click)="openDeleteDialog(element?.id , 2)">Delete</button>
                                <button mat-menu-item (click)="userStatus(element?.id , 1)"
                                    *ngIf="element?.status === 0">Active</button>
                                <button mat-menu-item (click)="userStatus(element?.id , 0)"
                                    *ngIf="element?.status === 1">Inactive</button>

                            </mat-menu>

                            <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                                    fill="black" />
                                <path
                                    d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                                    fill="black" />
                                <path
                                    d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                                    fill="black" />
                            </svg>


                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <div class="no-data-found" *ngIf="!totalItems">
                <img src="../../assets/images/no-data-found.png" alt="noData">
            </div>
        </div>

        <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
        </mat-paginator>



    </div>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="createForm" (ngSubmit)="createUserType()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>{{ isEditMode ? 'EDIT ROLE' : 'ADD ROLE' }}</h2>
                                <div class="formFieldMainAll">
                                    <span>Role Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Role Name" formControlName="roleName">
                                    </mat-form-field>
                                    <div class="s_error" *ngIf="
            !createForm.controls['roleName'].valid &&
            (createForm.controls['roleName'].dirty ||
              createForm.controls['roleName'].touched ||
              createSubmit)
          ">
                                        <div class="s_validation"
                                            *ngIf="createForm.controls['roleName'].hasError('required')">
                                            Role Name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>Editable</span>
                                    <mat-form-field class="example-full-width">
                                        <!-- <input matInput placeholder="First Name" formControlName="isEditable"> -->
                                        <mat-select placeholder="Select" formControlName="isEditable">
                                            <mat-option value="true">Yes</mat-option>
                                            <mat-option value="false">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend"
                                        [disabled]="createForm.invalid">SUBMIT</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>