import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { USER_TYPE_PERMISSIONS, PROFILE } from '../../helper/queries';
import {
  setMyProfile,
  setPermissions,
} from '../../reducers/permissions.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthResolver implements Resolve<any> {
  constructor(private apollo: Apollo, private store: Store) {}
  userId: number | undefined;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable((observer) => {
      this.fetchProfile().subscribe((res) => {
        this.fetchAndSetPermissions().subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      });
    });
  }

  private fetchAndSetPermissions(): Observable<void> {
    return new Observable((observer) => {
      this.apollo
        .watchQuery({
          query: USER_TYPE_PERMISSIONS,
          fetchPolicy: 'no-cache',
          variables: {
            usertype: this.userId,
          },
        })
        .valueChanges.subscribe(({ data }: any) => {
          this.store.dispatch(
            setPermissions({ permissions: data?.usertypePermissions })
          );
          observer.next();
          observer.complete();
        });
    });
  }

  private fetchProfile(): Observable<void> {
    return new Observable((observer) => {
      this.apollo
        .watchQuery({
          query: PROFILE,
          fetchPolicy: 'no-cache',
        })
        .valueChanges.subscribe(({ data }: any) => {
          this.userId = data?.myProfile?.id;
          this.store.dispatch(setMyProfile({ myProfile: data?.myProfile }));
          observer.next();
          observer.complete();
        });
    });
  }
}
