<section class="addDatSheetCommonSec">

    <div class="divBgWhiteSet">
        <form [formGroup]="profileForm" (ngSubmit)="profileUpdate()">
            <div class="row rowSpaceMain">
                <div class="col-sm-12 spaceMainall">
                    <div class="headerFleXmain">
                        <label>Edit Profile</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Username</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Username" formControlName="username">
                                    </mat-form-field>

                                </div>
                            </div>
                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll lightColor">
                                    <span>Email</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Email" formControlName="email" readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Phone Number</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Phone" formControlName="phone">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-sm-6 spaceMainall">
                                <!-- <div class="formFieldMainAll">
                                    <span>Profile Upload</span>
                                    <input type="file" id="fileInput" formControlName="profileUpload"
                                        (change)="onFileSelected($event)" />
                                </div> -->
                                <div class="formFieldMainAll custom-fileinput">
                                    <span>Profile Upload</span>
                                    <input [hidden]="true" type="file" formControlName="profileUpload"
                                        (change)="onFileChange($event)" class="form-control"
                                        placeholder="Please upload a photo here" #fileSelect>
                                    <input readonly style="cursor: pointer" placeholder="Please upload a photo here"
                                        formControlName="profileLabel" (click)="fileSelect.click()"
                                        class="form-control" />
                                    <div class="inputFileIcon" (click)="fileSelect.click();">
                                        <img src="/assets/images/clip.svg" alt="icons">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 photoWrapper" *ngIf="true">
                        <div class="formFieldMainAll">
                            <!-- <span> Profile Photo </span> -->
                            <div [ngStyle]="{height:height+'px',width:width+'px'}" *ngIf="isLoading">
                                <img [src]=loader />
                            </div>
                            <div class="img-wrapper">
                                <img  class="img-profile" alt="" [src]=" this.picUrl" [ngStyle]="{visibility:isLoading?'hidden':'',height:height+'px',width:width+'px'}"
                                (load)="hideLoader()" >
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-6 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>Role</span>
                        <mat-form-field>
                            <mat-select [(value)]="selected">
                                <mat-option value="">Select</mat-option>
                                <mat-option value="one">Role</mat-option>
                                <mat-option value="two">Second option</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->









                <div class="col-sm-12 spaceMainall">
                    <div class="bottomBtnmain">
                        <button class="saveBtn" type="submit" [disabled]="profileForm.invalid">Save</button>
                        <!-- <button class="cancelbtn" type="button">Cancel</button> -->

                    </div>
                </div>
            </div>

        </form>
    </div>


    <div class="divBgWhiteSet">
        <form [formGroup]="passwordForm" (ngSubmit)="ChangePassword()">
            <div class="row rowSpaceMain">
                <div class="col-sm-12 spaceMainall">
                    <div class="headerFleXmain">
                        <label>Change Password</label>
                    </div>
                </div>
                <div class="col-sm-4 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>Current Password</span>
                        <mat-form-field class="example-full-width">
                            <input type="password" matInput placeholder="Current Password"
                                formControlName="currentPassword" autocomplete="new-password">
                        </mat-form-field>
                        <div class="s_error" *ngIf="
            !passwordForm.controls['currentPassword'].valid &&
            (passwordForm.controls['currentPassword'].dirty ||
              passwordForm.controls['currentPassword'].touched ||
              passwordSubmit)
          ">
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['currentPassword'].hasError('required')">
                                Current Password is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>New Password</span>
                        <mat-form-field class="example-full-width">
                            <input type="password" matInput placeholder="New Password" formControlName="newPassword">
                        </mat-form-field>
                        <div class="s_error" *ngIf="
            !passwordForm.controls['newPassword'].valid &&
            (passwordForm.controls['newPassword'].dirty ||
              passwordForm.controls['newPassword'].touched ||
              passwordSubmit)
          ">
                            <div class="s_validation" *ngIf="passwordForm.controls['newPassword'].hasError('required')">
                                New Password is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>Confirm Password</span>
                        <mat-form-field class="example-full-width">
                            <input type="password" matInput placeholder="Confirm Password"
                                formControlName="confirmPassword">
                        </mat-form-field>
                        <div class="s_error" *ngIf="
            !passwordForm.controls['confirmPassword'].valid &&
            (passwordForm.controls['confirmPassword'].dirty ||
              passwordForm.controls['confirmPassword'].touched ||
              passwordSubmit)
          ">
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['confirmPassword'].hasError('required')">
                                Confirm Password is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 spaceMainall">
                    <div class="bottomBtnmain">
                        <button type="submit" class="saveBtn" [disabled]="passwordForm.invalid">Save</button>
                        <!-- <button type="reset" class="cancelbtn">Cancel</button> -->
                    </div>
                </div>
            </div>
        </form>
    </div>


</section>