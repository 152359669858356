import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { debounceTime } from 'rxjs';
import { timeDelay } from '../helper/constant';
import { LIST_VEHICLE } from '../helper/queries';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class VehicleComponent {
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = ['id', 'year', 'make', 'model']; // tables colums

  dataSource = new MatTableDataSource(); // table source

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  constructor(private readonly apollo: Apollo) {}

  ngOnInit() {
    this.listVehicle();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listVehicle(searchTerm);
      });
  }

  listVehicle(searchTerm: string | null = '') {
    this.apollo
      .watchQuery({
        query: LIST_VEHICLE,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listVehicleWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            year: resp?.year,
            make: resp?.make,
            model: resp?.model,
          })
        );

        this.totalItems = data?.listVehicleWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listVehicleWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listVehicleWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listVehicle(this.search.value);
  }

  
}
