import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { DatasheetComponent } from './datasheet/datasheet.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './users/users.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SettingComponent } from './setting/setting.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { AuthGuardService } from './service/authGuard/auth-guard.service';
import { OrderManagementComponent } from './order-management/order-management.component';
import { PaymentTransactionComponent } from './payment-transaction/payment-transaction.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { redirectToURL } from './helper/constant';
import { VehicleComponent } from './vehicle/vehicle.component';
import { EngineComponent } from './engine/engine.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ProductEnquiryComponent } from './product-enquiry/product-enquiry.component';
import { ModulesAndPermissionsComponent } from './modules-and-permissions/modules-and-permissions.component';
import { ProductEnguiryViewComponent } from './product-enguiry-view/product-enguiry-view.component';
import { CustomersComponent } from './customers/customers.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { moduleName } from './helper/permissionData';
import { InventoryEnquiryViewComponent } from './inventory-enguiry-view/inventory-enguiry-view.component';
import { AuthResolver } from './service/authGuard/auth-resolver.service';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { ContactUsComponent } from './contact-us/contact-us.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: '',
    component: PagelayoutComponent,
    resolve: {
      permissions: AuthResolver,
    },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        component: DashboardComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'order-management',
        canActivate: [AuthGuardService],
        component: OrderManagementComponent,
        data: { permission: moduleName.viewOrder },
      },
      {
        path: 'order-management/:id',
        canActivate: [AuthGuardService],
        component: OrderDetailsComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'product-enquiry',
        canActivate: [AuthGuardService],
        component: ProductEnquiryComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'product-enquiry/:id',
        canActivate: [AuthGuardService],
        component: ProductEnguiryViewComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'subscription',
        canActivate: [AuthGuardService],
        component: SubscriptionPlansComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'transaction',
        canActivate: [AuthGuardService],
        component: PaymentTransactionComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'inventory',
        canActivate: [AuthGuardService],
        component: InventoryComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'inventory-enquiry/:id',
        canActivate: [AuthGuardService],
        component: InventoryEnquiryViewComponent,
        data: { permission: moduleName.viewCustomer },
      },
      {
        path: 'category',
        canActivate: [AuthGuardService],
        component: CategoryComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'subcategory',
        canActivate: [AuthGuardService],
        component: SubCategoryComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'vehicle',
        canActivate: [AuthGuardService],
        component: VehicleComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'engine',
        canActivate: [AuthGuardService],
        component: EngineComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'settings',
        canActivate: [AuthGuardService],
        component: SettingComponent,
        data: { permission: moduleName.viewDashbard },
      },
      {
        path: 'customers',
        canActivate: [AuthGuardService],
        component: CustomersComponent,
        data: { permission: moduleName.viewCustomer },
      },
      {
        path: 'customers/:id',
        canActivate: [AuthGuardService],
        component: UserDetailsComponent,
        data: { permission: moduleName.viewCustomer },
      },
      {
        path: 'users',
        canActivate: [AuthGuardService],
        component: UsersComponent,
        data: { permission: moduleName.viewUser },
      },
      {
        path: 'users/:id',
        canActivate: [AuthGuardService],
        component: UserDetailsComponent,
        data: { permission: moduleName.viewUser },
      },
      {
        path: 'users-roles',
        canActivate: [AuthGuardService],
        component: UserTypeComponent,
        data: { permission: moduleName.viewRoles },
      },
      {
        path: 'permissions',
        canActivate: [AuthGuardService],
        component: ModulesAndPermissionsComponent,
        data: { permission: moduleName.viewPermission },
      },
      {
        path: 'admin-settings',
        canActivate: [AuthGuardService],
        component: AdminSettingsComponent,
        data: { permission: moduleName.viewPermission },
      },
      
      {
        path: 'contact-us',
        canActivate: [AuthGuardService],
        component: ContactUsComponent,
        data: { permission: moduleName.viewPermission },
      },
      { path: '**', component: PagenotfoundComponent, pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
