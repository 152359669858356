import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { moduleName } from '../helper/permissionData';
import { Observable, tap } from 'rxjs';
import { selectPermissions } from '../reducers/permissions.selectors';
import { setHeaderTitle } from '../reducers/permissions.actions';

@Component({
  selector: 'app-pagelayout',
  templateUrl: './pagelayout.component.html',
  styleUrls: ['./pagelayout.component.scss'],
})
export class PagelayoutComponent {
  moduleName = moduleName;
  permissions$: Observable<any>;

  userPermission: any;

  constructor(private store: Store) {
    this.permissions$ = this.store.pipe(select(selectPermissions));
  }

  ngOnInit() {
    this.permissions$
      .pipe(
        tap((permissions: any) => {
          this.userPermission = permissions;
        })
      )
      .subscribe();
  }

  checkUserPermission(menu: string): boolean {
    const isPermission = this.userPermission?.find(
      (permission: any) => permission?.permissionData?.permissionName === menu
    );
    if (isPermission) {
      return true;
    } else {
      return false;
    }
  }

  updateHeaderTitle(newTitle: string) {
    this.store.dispatch(setHeaderTitle({ headerTitle: newTitle }));
  }
}
