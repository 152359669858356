import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { currencyType, timeDelay } from '../helper/constant';
import { Apollo } from 'apollo-angular';
import { LIST_ORDER, UPDATE_ORDER } from '../helper/queries';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { VALIDATION_MESSAGES } from '../helper/messages';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

enum DateRange {
  NONE = 'None',
  LAST_DAY = 'Last Day',
  LAST_7_DAYS = 'Last 7 Days',
  THIS_MONTH = 'This Month',
  CUSTOM = 'Custom Range',
}

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['../users/users.component.scss'],
  providers: [DatePipe],
})
export class OrderManagementComponent {
  search = new FormControl(''); //  table serch
  displayedColumns: string[] = [
    'orderNo',
    'username',
    'orderDate',
    'orderStatus',
    'totalAmount',
    'paymentStatus',
    'action',
  ]; // tables colums
  dataSource = new MatTableDataSource(); // table source
  dateRangeForm!: FormGroup;

  readonly CUSTOM_TYPE = 'Custom Range';

  createForm!: FormGroup; // user create
  createSubmit: boolean | undefined;

  totalItems: number | undefined;
  itemsPerPage: number = 10;
  currentPage: number = 1;

  @ViewChild('closeModal') closeModal: any; //close Model
  @ViewChild('picker') picker!: MatDateRangePicker<Date>;

  // filter vaues
  dateRanges = DateRange;
  selectedRange: DateRange | null = null;
  customStartDate: string | null = null;
  customEndDate: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;

  dateRangeOrder: DateRange[] = [
    DateRange.NONE,
    DateRange.LAST_DAY,
    DateRange.LAST_7_DAYS,
    DateRange.THIS_MONTH,
    DateRange.CUSTOM,
  ];

  orderStatusConstants = {
    PLACED: 'Placed',
    CONFIRMED: 'Confirmed',
    SHIPPED: 'Shipped',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  };

  constructor(
    private readonly apollo: Apollo,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe
  ) {}

  closeModel(): void {
    this.createForm.reset();
    this.closeModal.nativeElement.click();
  }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      orderStatus: ['', [Validators.required]],
      totalAmount: ['', [Validators.required]],
      paymentStatus: ['', [Validators.required]],
    });

    this.listOrder();

    // Subscribe to changes in the search FormControl
    this.search.valueChanges
      .pipe(debounceTime(timeDelay.search))
      .subscribe((searchTerm) => {
        this.listOrder(searchTerm, this.startDate, this.endDate);
      });

    this.dateRangeForm = this.formBuilder.group({
      dateRange: this.formBuilder.group({
        start: [null],
        end: [null],
      }),
    });

    this.dateRangeForm.valueChanges.subscribe((value) => {
      const { start, end } = value.dateRange;
      if (start && end) {
        const startDate = start.toISOString().split('T')[0];
        const endDate = end.toISOString().split('T')[0];
        this.listOrder(this.search.value, startDate, endDate);
        // this.dateRangeForm.reset();
        this.picker?.close(); 
      }
    });
  }

  navigateToOrderDetails(orderId: string): void {
    if (orderId) {
      this.router.navigate(['/order-management', orderId]);
    } else {
      this.toastr.error(VALIDATION_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  onRangeChange(): void {
    if (this.selectedRange && this.selectedRange !== DateRange.NONE) {
      const range = this.getDateRange(this.selectedRange);
      this.startDate = range.startDate;
      this.endDate = range.endDate;
      this.dateRangeForm.reset();
      this.listOrder(this.search.value, this.startDate, this.endDate);
    } else {
      this.selectedRange= null;
      this.startDate = null;
      this.endDate = null;
      this.customStartDate = null;
      this.customEndDate = null;
      this.dateRangeForm.reset();
      this.listOrder(this.search.value, this.startDate, this.endDate);
    }
  }

  getDateRange(range: DateRange): { startDate: string; endDate: string } {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (range) {
      case DateRange.LAST_DAY:
        startDate.setDate(today.getDate() - 1);
        break;
      case DateRange.LAST_7_DAYS:
        startDate.setDate(today.getDate() - 7);
        break;
      // case DateRange.LAST_30_DAYS:
      //   startDate.setDate(today.getDate() - 30);
      //   break;
      case DateRange.THIS_MONTH:
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      // case DateRange.LAST_MONTH:
      //   startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      //   endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      //   break;
      // case DateRange.CUSTOM:
      //   startDate = new Date(this.customStartDate!);
      //   endDate = new Date(this.customEndDate!);
      //   return {
      //     startDate: startDate.toISOString().split('T')[0],
      //     endDate: endDate.toISOString().split('T')[0],
      //   };
      case DateRange.NONE:
      default:
        return { startDate: '', endDate: '' };
    }

    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
    };
  }

  listOrder(
    searchTerm: string | null = '',
    startDate: string | null = '',
    endDate: string | null = ''
  ) {
    this.apollo
      .watchQuery({
        query: LIST_ORDER,
        variables: {
          page: this.currentPage,
          limit: this.itemsPerPage,
          search: searchTerm,
          filter:
            startDate && endDate
              ? { dateFrom: startDate, dateTo: endDate }
              : null,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(({ data, loading }: any) => {
        this.dataSource = data?.listOrdersWithPagination?.items?.map(
          (resp: any) => ({
            id: resp?.id,
            orderNo: resp?.orderNo,
            username: resp?.userData?.username,
            orderDate: this.datePipe.transform(
              resp?.createdAt,
              'yyyy-MM-dd hh:mm a'
            ), // Convert timestamp to date
            orderStatus: resp?.orderStatus,
            paymentStatus:
              resp?.paymentStatus?.toLowerCase() === 'success'
                ? 'Paid'
                : 'Pending',
            // totalAmount: currencyType+" "+resp?.totalAmount,
            totalAmount: resp?.totalAmount,
          })
        );
        this.totalItems = data?.listOrdersWithPagination?.pageInfo?.totalItems;
        this.itemsPerPage =
          data?.listOrdersWithPagination?.pageInfo?.itemsPerPage;
        this.currentPage =
          data?.listOrdersWithPagination?.pageInfo?.currentPage;
      });
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.listOrder(this.search.value);
  }

  exportexcel(): void {
    const fileName = 'orderSheet.xlsx';
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  orderUpdate(data: any): void {
    const newData = {
      id: data?.id,
      orderStatus: data?.orderStatus,
      totalAmount: data?.totalAmount,
      paymentStatus: data?.paymentStatus,
    };
    this.createForm.patchValue(newData);
  }

  updateOrder(): void {
    if (this.createForm.valid) {
      const value = this.createForm.value;
      const data = {
        orderStatus: value?.orderStatus,
        totalAmount: value?.totalAmount,
        paymentStatus: value?.paymentStatus,
      };
      this.apollo
        .mutate({
          mutation: UPDATE_ORDER,
          variables: {
            orderId: value?.id,
            updateOrders: data,
          },
          fetchPolicy: 'no-cache',
        })
        .subscribe(() => {
          this.listOrder();
          this.closeModel();
        });
    }
  }
}
