<!-- 
<section class="addDatSheetCommonSec">
  <div class="container">
    <div class="order-details-wrapper">
      <div class="top-box">
        <div class="right-set">
          <div class="summary-wrapper">
            <div class="head header-flex">
              <h2>Admin Details</h2>
              <button (click)="toggleEdit()">
                <img *ngIf="!isEditMode" src="../../assets/images/edit.svg" alt="">
              </button>
              <button  (click)="edit()" [ngClass]="{'hidden': !isEditMode}"> 
                <img *ngIf="isEditMode" src="../../assets/images/save.svg" alt="">
              </button>
            </div>
            <div class="content row">
              <div class="col-md-4 value-box">
                <h3>Email :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.adminEmail }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.adminEmail" type="text" />
              </div>
              <div class="col-md-4 value-box">
                <h3>Phone Number :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.adminPhone }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.adminPhone" type="text" />
              </div>
              <div class="col-md-4 value-box">
                <h3>Tax :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.tax }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.tax" type="text" />
              </div>
              <div class="col-md-4 value-box">
                <h3>Discount :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.discount }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.discount" type="number" />
              </div>
              <div class="col-md-4 value-box">
                <h3>Shipping :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.shipping }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.shipping" type="text" />
              </div>
              <div class="col-md-4 value-box">
                <h3>Discount Type :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.discountType }}</h4>
                <select *ngIf="isEditMode" [(ngModel)]="adminData.discountType">
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>
              <div class="col-md-4 value-box">
                <h3>Address :</h3>
                <h4 *ngIf="!isEditMode">{{ adminData.address }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.address" type="text" />
              </div>
              <div class="col-md-4 value-box links">
                <h3>FaceBook:</h3>
                <h4 *ngIf="!isEditMode" [title]="adminData.facebook" (click)="openLink('facebook', adminData.facebook)">{{ adminData.facebook | truncate }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.facebook" type="text" />
              </div>
              <div class="col-md-4 value-box links">
                <h3>WhatsApp :</h3>
                <h4 *ngIf="!isEditMode"  [title]="adminData.whatsApp" (click)="openLink('whatsapp', adminData.whatsApp)">{{ adminData.whatsApp | truncate }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.whatsApp" type="text" />
              </div>
              <div class="col-md-4 value-box links">
                <h3>Twitter :</h3>
                <h4 *ngIf="!isEditMode" [title]="adminData.twitter" (click)="openLink('twitter', adminData.twitter)">{{ adminData.twitter | truncate }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.twitter" type="text" />
              </div>
              <div class="col-md-4 value-box links">
                <h3>LinkedIn :</h3>
                <h4 *ngIf="!isEditMode" [title]="adminData.linkedIn" (click)="openLink('linkedIn', adminData.linkedIn)">{{ adminData.linkedIn | truncate }}</h4>
                <input *ngIf="isEditMode" [(ngModel)]="adminData.linkedIn" type="text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> 
 -->



<section class="addDatSheetCommonSec">
  <div class="container">
    <div class="order-details-wrapper">
      <div class="top-box">
        <div class="right-set">
          <div class="summary-wrapper">
            <div class="head header-flex">
              <h2>Admin Details</h2>
              <button (click)="toggleEditMode()" >
                <img *ngIf="!isEditMode" src="../../assets/images/edit.svg" alt="">
              </button>
              <button  [ngClass]="{'hidden': !isEditMode}" (click)="edit()"> 
                <img *ngIf="isEditMode" src="../../assets/images/save.svg" alt="">
              </button>

              <button class="cancelbtn"  *ngIf="isEditMode" (click)="cancel()">X</button>
            </div>
            <div class="content row">
              <form [formGroup]="createForm">
                <div class="formFieldMainAll">
                  
                    <p>CONTACT DETAILS</p>
                    <section class="sections">
                    <div class="sub">
                      <span>Email</span>
                      <span *ngIf="!isEditMode">{{ adminData.adminEmail }}</span>
                      <mat-form-field class="example-full-width" *ngIf="isEditMode">
                          <input  matInput placeholder="Admin Email" formControlName="adminEmail">
                      </mat-form-field>
                    </div>
                    <div class="sub">
                      <span>Phone Number</span>
                      <span *ngIf="!isEditMode">{{ adminData.adminPhone }}</span>
                      <mat-form-field class="example-full-width" *ngIf="isEditMode">
                          <input  matInput placeholder="Phone Number" formControlName="adminPhone">
                      </mat-form-field>
                    </div>
                    <div class="sub">
                      <span>Address</span>
                      <span *ngIf="!isEditMode">{{ adminData.address }}</span>
                      <mat-form-field class="example-full-width" *ngIf="isEditMode">
                          <input  matInput placeholder="Address" formControlName="address">
                      </mat-form-field>
                    </div>
                  </section>
                  <hr>
                                    
                  <p>TAX DETAILS</p>
                  <section class="sections">
                  <div class="sub">
                    <span>Tax</span>
                    <span *ngIf="!isEditMode">{{ adminData.tax }}</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode">
                        <input  matInput placeholder="Tax" formControlName="tax">
                    </mat-form-field>
                  </div>
                  <div class="sub">
                    <span>Shipping</span>
                    <span *ngIf="!isEditMode">{{ adminData.shipping }}</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode">
                        <input  matInput placeholder="Shipping" formControlName="shipping">
                    </mat-form-field>
                  </div>
                  <div class="sub">
                    <span>Discount Type</span>
                    <span *ngIf="!isEditMode">{{ adminData.discountType }}</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode">
                      <mat-select  formControlName="discountType">
                        <mat-option value="fixed">Fixed</mat-option>
                        <mat-option value="percentage">Percentage</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  
                </section>

                <hr>
                                    
                  <p>SOCIAL MEDIA DETAILS</p>
                  <section class="sections">
                  <div class="sub">
                    <span>WhatsApp</span>
                    <span *ngIf="!isEditMode" (click)="openLink('whatsapp', adminData.whatsApp)">{{ adminData.whatsApp | truncate }}</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode" >
                        <input matInput placeholder="Email" formControlName="whatsApp">
                    </mat-form-field>
                  </div>
                  <div class="sub">
                    <span>Twitter</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode">
                      <input matInput placeholder="Twitter" formControlName="twitter">
                    </mat-form-field>
                    <span *ngIf="!isEditMode" (click)="openLink('twitter', adminData.twitter)">{{ adminData.twitter | truncate }}</span>
                  </div>
                                   
                  <div class="sub">
                    <span>Facebook</span>
                    <span *ngIf="!isEditMode" (click)="openLink('facebook', adminData.facebook)" >{{ adminData.facebook | truncate }}</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode" >
                        <input  matInput placeholder="Email" formControlName="facebook">
                    </mat-form-field>
                  </div>

                  <div class="sub">
                    <span>LinkedIn</span>
                    <span *ngIf="!isEditMode" (click)="openLink('linkedIn', adminData.linkedIn)">{{ adminData.linkedIn | truncate }}</span>
                    <mat-form-field class="example-full-width" *ngIf="isEditMode">
                        <input  matInput placeholder="Email" formControlName="linkedIn">
                    </mat-form-field>
                  </div>
                </section>
              </div>
              </form>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</section> 