<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Search for category..." [formControl]="search" class="example-input">
            </form>
        </div>
        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()" #openModal>ADD
            Category</button>

    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                <!-- Position Column -->
                <ng-container matColumnDef="categoryId">
                    <th mat-header-cell *matHeaderCellDef>
                        Category Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.categoryId || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="categoryName">
                    <th mat-header-cell *matHeaderCellDef>
                        Category Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.categoryName || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="categoryImage">
                    <th mat-header-cell *matHeaderCellDef>
                        Image
                    </th>
                    <td mat-cell *matCellDef="let element"
                    data-bs-toggle="modal"
                    data-bs-target="#imgModal"
                    (click)="popupDataImg(element?.catImagePopup)"
                    > 
                    <img *ngIf="element.categoryImage" [src]="imagePath">
                    <span *ngIf="!element.categoryImage">___</span>
                    </td>

                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dropMenuMain">
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="setFormData(element)">Edit</button>
                                <button mat-menu-item (click)="deleteCategory(element)">Delete</button>
                            </mat-menu>

                            <svg mat-button [matMenuTriggerFor]="menu" width="32" height="32" viewBox="0 0 32 32"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 10.5C18 11.3284 17.3284 12 16.5 12C15.6716 12 15 11.3284 15 10.5C15 9.67157 15.6716 9 16.5 9C17.3284 9 18 9.67157 18 10.5Z"
                                    fill="black" />
                                <path
                                    d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z"
                                    fill="black" />
                                <path
                                    d="M18 22.5C18 23.3284 17.3284 24 16.5 24C15.6716 24 15 23.3284 15 22.5C15 21.6716 15.6716 21 16.5 21C17.3284 21 18 21.6716 18 22.5Z"
                                    fill="black" />
                            </svg>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItems">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
    </mat-paginator>
</section>


<!-- add category pop Up -->
<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="createForm" (ngSubmit)="createCategory()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>{{ isEditMode ? 'EDIT CATEGORY' : 'ADD CATEGORY' }}</h2>
                                <div class="formFieldMainAll">
                                    <span>Category Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Category Name" formControlName="categoryName">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll custom-fileinput">
                                    <span>Category Image</span>
                                    <input [hidden]="true" type="file" (change)="onFileChange($event)"
                                        class="form-control" placeholder="Please upload a photo here" #fileSelect>
                                    <input readonly style="cursor: pointer" placeholder="Please upload a photo here"
                                        formControlName="categoryLabel" (click)="fileSelect.click()"
                                        class="form-control" />
                                    <div class="inputFileIcon" (click)="fileSelect.click();">
                                        <img src="/assets/images/clip.svg" alt="icons">
                                    </div>
                                       <!-- Loader Display -->
                                    <div *ngIf="isUploading" class="loader-container">
                                        <p>Uploading..</p>
                                    </div>
                                </div>
                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend"
                                        [disabled]="createForm.invalid">SUBMIT</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="usersPopupdiv">
  <div
    class="modal fade"
    id="imgModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-closeMain"
            data-bs-dismiss="modal"
            aria-label="Close"
            #closeModalFilter
          >
            <img alt="" src="../../assets/images/close-icon1.png" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row rowSpaceMain">
            <div class="col-sm-12 imge">
                <div
                  class="loadImageMain"
                  *ngIf="isLoading"
                >
                  <img class="loadImage" [src]="loader" />
                </div>
                
                <div 
                >
                <img
                [src]="imagePopupData"
                alt="img"
                [ngStyle]="{height: height + 'px', width: width + 'px'}"
                (load)="hideLoader()"
              />
                </div>

              </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
