<section class="addDatSheetCommonSec">

    <div class="serachMainAllWrapper">
        <div class="searchDiv searchDivFull">
            <form class="example-form">
                <input type="text" placeholder="Search for engine..." [formControl]="search" class="example-input">
            </form>
        </div>

    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table">
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>
                        Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.id || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="engineId">
                    <th mat-header-cell *matHeaderCellDef>
                        Engine Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.engineId || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="vehicleId">
                    <th mat-header-cell *matHeaderCellDef>
                        Vehicle Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.vehicleId || "___"}} </td>
                </ng-container>

                <ng-container matColumnDef="engineName">
                    <th mat-header-cell *matHeaderCellDef>
                        Engine Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.engineName || "___"}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <div class="no-data-found" *ngIf="!totalItems">
            <img src="../../assets/images/no-data-found.png" alt="noData">
        </div>
    </div>
    <mat-paginator *ngIf="totalItems" [length]="totalItems" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="onPageChange($event)">
    </mat-paginator>
</section>