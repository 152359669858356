<header class="headerBgsecMain">
  <div class="headerTxtMainAll">
    <h3 *ngIf="!headerValue">Welcome To <label>Smarttrack</label> </h3>
    <h3 *ngIf="headerValue"> {{ headerValue }} </h3>

  </div>
  <div class="profileMainAll">
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span> <img alt="" [src]=" this.picUrl"> </span>{{headerIteam?.username || "___"}}
        <label class="capitalize">{{headerIteam?.userType || "___"}}</label>
      </button>
      <ul class="dropdown-menu">
        <li (click)="updateHeaderTitle('Settings')"><a class="dropdown-item" routerLink="/settings">Settings</a></li>

        <li><a class="dropdown-item" (click)="logoutUser()">Logout</a></li>
      </ul>
    </div>
  </div>


</header>